import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EnumPrestationState } from '../../../../../common/src/bdd/interfaces/IPrestation';
import { MessageNotifications } from '../../../../../common/src/bdd/messaging/MessageNotifications';
import { Prestation } from '../../../../../common/src/bdd/prestation/Prestation';
import { TraducteurPrestations } from '../../../../../common/src/bdd/traducteur/TraducteurPrestations';
import { HieroBDD } from '../../../services/hierobdd.service';

interface IStateFilter {
  state: EnumPrestationState;
  active: boolean;
}

@Component({
  templateUrl: './prestations.component.html',
  styleUrls: ['./prestations.component.scss']
})
export class PrestationListComponent implements OnDestroy {

  private traducteurPrestationSub: Subscription;
  private listSub: Subscription;
  private notifySub: Subscription;

  prestations: TraducteurPrestations;
  prestationList: Prestation[] = [];

  filterUI: IStateFilter[];

  desc = true;

  page = 1;
  pageSize = 10;

  notifiers: Map<string, MessageNotifications>;

  busy = true;

  constructor(
    private hiero: HieroBDD,
    private router: Router,
    private route: ActivatedRoute
  ) {

    // Setup default filters
    this.filterUI = [
      { state: EnumPrestationState.WaitingForTranslator, active: true },
      { state: EnumPrestationState.RefusedByTranslator, active: false },
      { state: EnumPrestationState.WaitingForPayment, active: false },
      { state: EnumPrestationState.Translating, active: true },
      { state: EnumPrestationState.CancelledByClient, active: false },
      { state: EnumPrestationState.Validated, active: false },
      { state: EnumPrestationState.WaitingForValidationFromClient, active: true },
    ];

    // Subscribe to the list of prestations for this translator
    this.traducteurPrestationSub = this.hiero.WatchTraducteurPrestations({
      next: (prestations: TraducteurPrestations) => {
        if (prestations) {
          // Get original list
          this.prestations = prestations;
          this.prestationList = this.prestations.All;

          // Listen for any subsequent updates to the list
          this.listSub = this.prestations.WatchList({
            next: (list: Prestation[]) => {
              this.prestationList = list;
            }
          });

          // Listen for notifications for the emails
          this.notifySub = this.hiero.WatchPrestationMessageNotifiers({
            next: (notifiers) => {
              this.notifiers = notifiers;
            }
          });

          // Process the list for filters, order and paging
          this.processQuery();
        }
      }
    });
  }

  ngOnDestroy()  {

    if (this.traducteurPrestationSub) {
      this.traducteurPrestationSub.unsubscribe();
    }

    if (this.listSub) {
      this.listSub.unsubscribe();
    }

    if (this.prestations) {
      // DO NOT CLEAN UP HERE: Prestation List is global
      this.prestations = null;
    }

    if (this.notifySub) {
      this.notifySub.unsubscribe();
    }

  }

  toggleState(filter: IStateFilter) {
    filter.active = !filter.active;
    this.processQuery();
  }

  toggleOrder() {
    this.desc = !this.desc;
    this.processQuery();
  }

  private processQuery() {
    const states: EnumPrestationState[] = [];
    this.filterUI.forEach(
      (ui) => {
        if (ui.active) {
          states.push(ui.state);
        }
      }
    );

    this.prestations.updateQuery(states, [ { column: 'lastModifiedAt', desc: this.desc  }], (this.page - 1), this.pageSize);
    this.busy = false;
  }

  open(prestation: Prestation) {
    this.router.navigate(['..', 'prestation', prestation.Id, 'index'], { relativeTo: this.route });
  }

  getUnreadMessages(prestation: Prestation): number {
    const notifier: MessageNotifications = this.notifiers.get(prestation.Id);
    if (notifier) {
      return notifier.Unseen;
    } else {
      return 0;
    }
  }

  onPageChange(pageNumber: number) {
    this.page = pageNumber;
    this.processQuery();
  }

}
