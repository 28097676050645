import { EventEmitter, OnChanges, OnInit, SimpleChange } from '@angular/core';
var AppFormDropdownComponent = /** @class */ (function () {
    function AppFormDropdownComponent() {
        this.onSelect = new EventEmitter();
        this.onClick = new EventEmitter();
        this.index = 0;
    }
    Object.defineProperty(AppFormDropdownComponent.prototype, "selected", {
        get: function () {
            if (this.index < this.parameters.choices.length) {
                return this.parameters.choices[this.index].name;
            }
            else {
                return '';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppFormDropdownComponent.prototype, "hasData", {
        get: function () {
            if (this.parameters) {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    AppFormDropdownComponent.prototype.ngOnInit = function () {
        if (this.parameters) {
            this.index = this.parameters.selectedIndex;
        }
    };
    AppFormDropdownComponent.prototype.ngOnChanges = function (changeMap) {
        if ('parameters' in changeMap) {
            var change = changeMap.parameters;
            if (change.currentValue) {
                this.index = this.parameters.selectedIndex;
            }
        }
    };
    AppFormDropdownComponent.prototype.onSelected = function (nameValue) {
        this.index = this.parameters.choices.indexOf(nameValue);
        this.onSelect.emit(nameValue);
    };
    AppFormDropdownComponent.prototype.onClicked = function () {
        this.onClick.emit(this.parameters.choices[this.index]);
    };
    return AppFormDropdownComponent;
}());
export { AppFormDropdownComponent };
