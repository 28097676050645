import { LoggingLevel } from '../interfaces/config/IConfig';
var Logger = /** @class */ (function () {
    function Logger() {
    }
    Object.defineProperty(Logger, "LogLevel", {
        get: function () {
            return Logger._level;
        },
        set: function (level) {
            Logger._level = level;
        },
        enumerable: true,
        configurable: true
    });
    Logger.Debug = function (message) {
        var params = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            params[_i - 1] = arguments[_i];
        }
        if (Logger._level >= LoggingLevel.Debug) {
            console.log(message, params);
        }
    };
    Logger.Info = function (message) {
        var params = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            params[_i - 1] = arguments[_i];
        }
        if (Logger._level >= LoggingLevel.Info) {
            console.log(message, params);
        }
    };
    Logger.Warn = function (message) {
        var params = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            params[_i - 1] = arguments[_i];
        }
        if (Logger._level >= LoggingLevel.Warning) {
            console.warn(message, params);
        }
    };
    Logger.Error = function (message) {
        var params = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            params[_i - 1] = arguments[_i];
        }
        if (Logger._level >= LoggingLevel.Error) {
            console.error(message, params);
        }
    };
    Logger._level = LoggingLevel.Warning;
    return Logger;
}());
export { Logger };
