import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { DocumentType } from '../../../../../../../common/src/bdd/documents/DocumentType';
import { IDocument } from '../../../../../../../common/src/bdd/interfaces/IDocument';
import { IFile } from '../../../../../../../common/src/bdd/interfaces/IFile';
import { IMessage } from '../../../../../../../common/src/bdd/interfaces/IMessaging';
import { EnumPrestationState, IPrestation } from '../../../../../../../common/src/bdd/interfaces/IPrestation';
import { MessageBox } from '../../../../../../../common/src/bdd/messaging/MessageBox';
import { File } from '../../../../../../../common/src/bdd/prestation/File';
import { Prestation } from '../../../../../../../common/src/bdd/prestation/Prestation';
import { Traducteur } from '../../../../../../../common/src/bdd/traducteur/Traducteur';
import { User } from '../../../../../../../common/src/bdd/user/User';
import { HieroBDD } from '../../../../../services/hierobdd.service';
import { AppModalConfirmComponent } from '../../../../../../../common/src/utility/modal/confirm-modal.component';
import { LocalisationService } from '../../../../../../../common/src/modules/localisation/localisation.service';


@Component({
  templateUrl: './prestation.component.html',
  styleUrls: ['./prestation.component.scss']
})
export class PrestationComponent implements OnDestroy, OnInit {

  private prestationSub: Subscription;
  private traducteurSub: Subscription;

  prestation: Prestation;

  prestationId: string;
  documentTypeMap: DocumentType;

  user: User;
  traducteur: Traducteur;

  messageBox: MessageBox;
  messages: IMessage[] = [];

  busy = true;

  constructor(
    private hiero: HieroBDD,
    private router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private modalService: NgbModal,
    private localisation: LocalisationService
  ) {

  }

  ngOnInit() {
    // Get prestation id from params
    const prestationId = this.route.snapshot.parent.paramMap.get('prestationId');

    // Watch for translator
    this.traducteurSub = this.hiero.WatchTraducteur({
      next: (traducteur: Traducteur) => {
        if (traducteur) {
          this.traducteur = traducteur;
          this.user = traducteur.User;

          // Get the specific prestation
          Prestation.Load(traducteur.User, true, prestationId, true)
          .then(
            (prestation) => {

              // Clean up old one
              if (this.prestation) {
                this.prestation.cleanup();
              }
              if (this.prestationSub) {
                this.prestationSub.unsubscribe();
              }
              // Replace with new one
              this.prestation = prestation;

              // Subscribe for modifications
              this.prestationSub = this.prestation.Watch({
                next: (updated) => {
                  this.prestation = updated;
                  this.checkBusy();
                }
              });

              this.cdRef.detectChanges();
            }
          );

          // Listen for document types
          DocumentType.Init(traducteur.User)
          .then(
            (types: DocumentType) => {
              this.documentTypeMap = types;
              this.checkBusy();
            }
          );
        }
      }
    });
  }

  checkBusy() {
    if (this.prestation && this.documentTypeMap) {
      this.busy = false;
    }
  }

  get haveChatWindow(): boolean {
    if (!this.prestation) {
      return false;
    }

    return (this.prestation.Data.state === EnumPrestationState.Translating ||
      this.prestation.Data.state === EnumPrestationState.WaitingForValidationFromClient ||
      this.prestation.Data.state === EnumPrestationState.Validated);
  }

  getDocumentName(code: string) {
    if (this.documentTypeMap) {
      return this.documentTypeMap.GetDocumentName(this.localisation.CurrentLanguageISO639, code);
    } else {
      return '';
    }
  }

  async openFile(prestation: IPrestation, document: IDocument, file: IFile) {
    const fileObj: File = new File(this.hiero.Storage, this.prestation.Data.uid, prestation, document.deviceStorageId, file);
    try {
      const link = await fileObj.GetDownloadLink();
      window.open(link, '_blank');
    } catch (err) {
      console.error(err);
    }
  }

  ngOnDestroy() {
    if (this.traducteurSub) {
      this.traducteurSub.unsubscribe();
    }
    if (this.prestationSub) {
      this.prestationSub.unsubscribe();
    }

    if (this.prestation) {
      this.prestation.cleanup();
    }
  }


  async validate() {

    const modalRef = this.modalService.open(AppModalConfirmComponent, {
      centered: true,
    });
    modalRef.componentInstance.header = this.localisation.localise('prestation_order_validate_title');
    modalRef.componentInstance.message = this.localisation.localise('prestation_order_validate_msg');
    modalRef.componentInstance.ok = this.localisation.localise('prestation_order_confirm_ok');
    modalRef.componentInstance.cancel = this.localisation.localise('prestation_order_confirm_cancel');
    let result = false;
    try {
      result = await modalRef.result;
    } catch {}

    if (result) {
      this.busy = true;
      try {
        await this.prestation.Validate();
      } catch (err) {
        console.error(err.message);
      } finally {
        this.busy = false;
      }
    }
  }

  public translate(document: IDocument) {
    this.router.navigate(['/app', 'prestation', this.prestation.Id, 'document', document.deviceStorageId ]);
  }

  public get canValidate(): boolean {
    let valid = true;
    this.prestation.Data.documents.forEach(
      (doc) => {
        if (!doc.translated || doc.translated.length === 0) {
          valid = false;
        }
      }
    );
    return valid;
  }

  async removeFile(document: IDocument, file: IFile) {
    this.busy = true;
    try {
      await this.prestation.DeleteTranslatedFile(this.hiero.Storage, document, file);
    } catch (err) {
      console.error(err);
    }
    finally {
      this.busy = false;
    }
  }

  async accept() {
    const modalRef = this.modalService.open(AppModalConfirmComponent, {
      centered: true,
    });
    modalRef.componentInstance.header = this.localisation.localise('prestation_order_accept_title');
    modalRef.componentInstance.message = this.localisation.localise('prestation_order_accept_msg');
    modalRef.componentInstance.ok = this.localisation.localise('prestation_order_confirm_ok');
    modalRef.componentInstance.cancel = this.localisation.localise('prestation_order_confirm_cancel');
    let result = false;
    try {
      result = await modalRef.result;
    } catch {}

    if (result) {
      this.busy = true;
      try {
        await this.prestation.AcceptPrestation();
      } catch (err) {
        console.error(err.message);
      } finally {
        this.busy = false;
      }
    }
  }

  async refuse() {
    const modalRef = this.modalService.open(AppModalConfirmComponent, {
      centered: true,
    });
    modalRef.componentInstance.header = this.localisation.localise('prestation_order_refuse_title');
    modalRef.componentInstance.message = this.localisation.localise('prestation_order_refuse_msg');
    modalRef.componentInstance.ok = this.localisation.localise('prestation_order_confirm_ok');
    modalRef.componentInstance.cancel = this.localisation.localise('prestation_order_confirm_cancel');
    let result = false;
    try {
      result = await modalRef.result;
    } catch {}

    if (result) {
      this.busy = true;
      try {
        await this.prestation.RefusePrestation();
      } catch (err) {
        console.error(err.message);
      } finally {
        this.busy = false;
      }
    }
  }

}
