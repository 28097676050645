var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { EmptyTraducteurProfile } from '../../../../../common/src/bdd/interfaces/ITraducteur';
import { Config } from '../../../../../common/src/services/config.service';
import { GoogleGeo } from '../../../services/google.services';
import { HieroBDD } from '../../../services/hierobdd.service';
import { SubmitForm } from '../../../../../common/src/utility/forms/submitform.class';
import { haveValideAddressValidator } from '../../verify/profile/profilecheck.component';
import { LocalisationService } from '../../../../../common/src/modules/localisation/localisation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppModalManualAddressComponent } from '../../../utility/components/modal-manual-address/modal-manual-address.component';
var TraducteurComponent = /** @class */ (function () {
    function TraducteurComponent(fb, hiero, config, router, geo, localisation, modalService) {
        var _this = this;
        this.fb = fb;
        this.hiero = hiero;
        this.config = config;
        this.router = router;
        this.geo = geo;
        this.localisation = localisation;
        this.modalService = modalService;
        this.busy = true;
        this.formatter = function (loc) { return (loc && loc.address) ? loc.address.formatted : ''; };
        this.search = function (text$) {
            return text$.pipe(debounceTime(100), distinctUntilChanged(), switchMap(function (term) {
                return term.length < 3
                    ? []
                    : _this.geo.geocode(term);
            }));
        };
        this.createForm(this.profile || EmptyTraducteurProfile);
    }
    TraducteurComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.profSubs = this.hiero.WatchTraducteurProfile({
            next: function (profile) {
                if (profile) {
                    _this.profile = profile;
                    _this.createForm(_this.profile);
                }
                _this.busy = false;
            }
        });
    };
    TraducteurComponent.prototype.ngOnDestroy = function () {
        if (this.profSubs) {
            this.profSubs.unsubscribe();
        }
    };
    TraducteurComponent.prototype.createForm = function (profile) {
        var _this = this;
        var compliment = '';
        if (profile && profile.address && profile.address.extra) {
            compliment = profile.address.extra;
        }
        var fullAddr = {
            address: profile.address,
            coords: profile.coords
        };
        this.profileForm = new SubmitForm(this.fb, [
            {
                name: 'businessName',
                value: profile.businessName,
                validators: [Validators.required],
                type: 'text',
                title: this.localisation.localise('agency_setup_companyname'),
                autocomplete: '',
                placeholder: this.localisation.localise('agency_setup_companyname_placeholder'),
                help: this.localisation.localise('agency_setup_companyname_help'),
                errors: [
                    {
                        code: 'required',
                        message: this.localisation.localise('agency_setup_error_required_field'),
                    },
                ]
            },
            {
                name: 'fulladdr',
                value: fullAddr,
                validators: [haveValideAddressValidator('fulladdr')],
                type: 'text',
                title: this.localisation.localise('agency_setup_address'),
                autocomplete: '',
                placeholder: this.localisation.localise('agency_setup_address_placeholder'),
                help: this.localisation.localise('agency_setup_address_help'),
                errors: [
                    {
                        code: 'required',
                        message: this.localisation.localise('agency_setup_error_required_field'),
                    },
                    {
                        code: 'validAddress',
                        message: this.localisation.localise('agency_setup_error_valid_address'),
                    },
                ]
            },
            {
                name: 'extra',
                value: compliment,
                validators: [],
                type: 'text',
                title: this.localisation.localise('agency_setup_addresscompl'),
                autocomplete: '',
                placeholder: this.localisation.localise('agency_setup_addresscompl_placeholder'),
                help: this.localisation.localise('agency_setup_addresscompl_help'),
                errors: []
            },
            {
                name: 'telephone',
                value: profile.telephone,
                validators: [Validators.required],
                type: 'text',
                title: this.localisation.localise('agency_setup_tel'),
                autocomplete: '',
                placeholder: this.localisation.localise('agency_setup_tel_placeholder'),
                help: this.localisation.localise('agency_setup_tel_help'),
                errors: [
                    {
                        code: 'required',
                        message: this.localisation.localise('agency_setup_error_required_field'),
                    },
                ]
            },
            {
                name: 'email',
                value: profile.email,
                validators: [Validators.required],
                type: 'email',
                title: this.localisation.localise('agency_setup_email'),
                autocomplete: '',
                placeholder: this.localisation.localise('agency_setup_email_placeholder'),
                help: this.localisation.localise('agency_setup_email_help'),
                errors: [
                    {
                        code: 'required',
                        message: this.localisation.localise('agency_setup_error_required_field'),
                    },
                    {
                        code: 'email',
                        message: this.localisation.localise('agency_setup_error_invalid_email'),
                    },
                ]
            },
            {
                name: 'coords',
                value: profile.coords,
                validators: [Validators.required],
                type: 'text',
                title: '',
                autocomplete: '',
                placeholder: '',
                help: '',
                errors: []
            },
            {
                name: 'address',
                value: profile.address,
                validators: [Validators.required],
                type: 'text',
                title: '',
                autocomplete: '',
                placeholder: '',
                help: '',
                errors: []
            }
        ], 
        // Submit callback
        function (changes) {
            _this.busy = true;
            var trad = _this.updateData();
            return _this.hiero.Traducteur.UpdateProfile(trad);
        }, 
        // Success callback
        function () {
            _this.busy = false;
        }, 
        // Fail callback
        function (err) {
            // What to do with login failuer
            console.error(err);
            _this.busy = false;
        }, 
        // Changes callback
        null);
    };
    TraducteurComponent.prototype.onAddressSelected = function (address) {
        var oldAddress = this.profileForm.GetValue('address');
        if (oldAddress && oldAddress.extra) {
            address.address.extra = oldAddress.extra;
        }
        this.profileForm.SetValue('coords', address.coords);
        this.profileForm.SetValue('address', address.address);
    };
    TraducteurComponent.prototype.updateData = function () {
        var traducteur = {
            uid: this.profile.uid,
            businessName: this.profileForm.GetValue('businessName'),
            email: this.profileForm.GetValue('email'),
            telephone: this.profileForm.GetValue('telephone'),
            address: this.profileForm.GetValue('address'),
            coords: this.profileForm.GetValue('coords'),
            registration: this.profile.registration,
        };
        traducteur.address.extra = this.profileForm.GetValue('extra');
        return traducteur;
    };
    TraducteurComponent.prototype.manualAddress = function () {
        return __awaiter(this, void 0, void 0, function () {
            var modalRef, result, _a, trad, extra;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        modalRef = this.modalService.open(AppModalManualAddressComponent, {
                            centered: true,
                            backdrop: 'static',
                            keyboard: false
                        });
                        modalRef.componentInstance.address = this.profileForm.GetValue('address');
                        modalRef.componentInstance.coords = this.profileForm.GetValue('coords');
                        result = false;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, modalRef.result];
                    case 2:
                        result = _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        _a = _b.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        if (result) {
                            this.busy = true;
                            try {
                                trad = this.updateData();
                                extra = trad.address.extra;
                                trad.address = result.address;
                                trad.address.extra = extra;
                                trad.coords = result.coords;
                                this.createForm(trad);
                                this.profileForm.CheckValidity();
                            }
                            catch (err) {
                                console.error(err.message);
                            }
                            finally {
                                this.busy = false;
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return TraducteurComponent;
}());
export { TraducteurComponent };
