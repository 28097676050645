<div class="row">
  <div class="col-md-6">
    <p>{{'account_expl' | localise}}</p>
    <form [formGroup]="profileForm.Group" novalidate>
      <app-form-input [parameters]="profileForm.GetInputElement('familyName')"></app-form-input>
      <app-form-input [parameters]="profileForm.GetInputElement('givenName')"></app-form-input>
      <app-form-input [parameters]="profileForm.GetInputElement('telephone')"></app-form-input>

      <app-form-general-error [submitForm]="profileForm"></app-form-general-error>

      <button class="btn btn-info" (click)='profileForm.Submit()' [disabled]="!profileForm.Valid">{{'account_save' | localise}}</button>
    </form>

    <app-wait [show]='busy'></app-wait>
  </div>
  
</div>