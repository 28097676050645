import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { EmptyTraducteurAddress, EmptyTraducteurCoords, EmptyTraducteurProfile, IAddress, IGeoCoords, ITraducteur } from '../../../../../common/src/bdd/interfaces/ITraducteur';
import { Traducteur } from '../../../../../common/src/bdd/traducteur/Traducteur';
import { Config } from '../../../../../common/src/services/config.service';
import { GeoCodeResult, GoogleGeo } from '../../../services/google.services';
import { HieroBDD } from '../../../services/hierobdd.service';
import { ISubmitFormInputErrors, SubmitForm } from '../../../../../common/src/utility/forms/submitform.class';
import { LocalisationService } from '../../../../../common/src/modules/localisation/localisation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppModalManualAddressComponent } from '../../../utility/components/modal-manual-address/modal-manual-address.component';


export function haveValideAddressValidator(id: string): ValidationErrors|null {
  return (control: AbstractControl): {[key: string]: any} => {
    let noddress = false;
    if (control.parent) {
      const par = control.parent;
      const coords: IGeoCoords = par.get('coords').value;
      const address: IAddress = par.get('address').value;

      noddress = (
        (!coords || !coords.latitude || !coords.longitude) ||
        (!address || !address.formatted )
      );
    }

    return (noddress) ? {'validAddress': true} : null;
  };
}

export function IsValidProfile(profile: ITraducteur) {
  return !(
    !profile.businessName ||
    !profile.coords ||
    !profile.coords.latitude ||
    !profile.coords.longitude ||
    !profile.address ||
    !profile.address.formatted
  );
}


@Component({
  templateUrl: './profilecheck.component.html',
  styleUrls: ['./profilecheck.component.scss']
})
export class ProfileCheckComponent implements OnInit, OnDestroy {

  busy = true;
  traducteur: Traducteur;
  profile: ITraducteur;
  profileForm: SubmitForm;

  tradSubs: Subscription;
  profSubs: Subscription;

  constructor(
    private fb: FormBuilder,
    private hiero: HieroBDD,
    private config: Config,
    private router: Router,
    private geo: GoogleGeo,
    private modalService: NgbModal,
    private localisation: LocalisationService
  ) {
    this.createForm(this.profile || EmptyTraducteurProfile);
  }

  ngOnInit() {
    this.tradSubs = this.hiero.WatchTraducteur({
      next: (traducteur: Traducteur) => {
        this.profile = null;
        // We have a user, save a reference
        this.traducteur = traducteur;
        if (this.traducteur) {

          // Set up a listener for the translator profile
          this.profSubs = this.traducteur.WatchProfile({
            next: (profile: ITraducteur) => {
              this.profile = profile;

              if (!IsValidProfile(this.profile)) {

                console.log(this.profile);

                if (!this.profile.email) {
                  this.profile.email = this.traducteur.User.Email;
                }

                if (!this.profile.address) {
                  this.profile.address = EmptyTraducteurAddress;
                }

                if (!this.profile.coords) {
                  this.profile.coords = EmptyTraducteurCoords;
                }

                if (!this.profile.registration) {
                  this.profile.registration = '';
                }

                this.createForm(this.profile);

                this.busy = false;
              } else {
                // Profile OK! Continut
                this.router.navigate(['app']);
              }
            }
          });
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.tradSubs) {
      this.tradSubs.unsubscribe();
    }
    if (this.profSubs) {
      this.profSubs.unsubscribe();
    }
  }

  createForm(profile: ITraducteur) {

    let compliment = '';
    if (profile && profile.address && profile.address.extra) {
      compliment = profile.address.extra;
    }

    const fullAddr: GeoCodeResult  = {
      address: profile.address,
      coords: profile.coords
    };

    this.profileForm = new SubmitForm(
      this.fb,
      [
        {
          name: 'businessName',
          value: profile.businessName,
          validators: [Validators.required],
          type: 'text',
          title: this.localisation.localise('agency_setup_companyname'),
          autocomplete: '',
          placeholder: this.localisation.localise('agency_setup_companyname_placeholder'),
          help: this.localisation.localise('agency_setup_companyname_help'),
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: this.localisation.localise('agency_setup_error_required_field'),
            },
          ]
        },
        {
          name: 'fulladdr',
          value: fullAddr,
          validators: [ haveValideAddressValidator('fulladdr') ],
          type: 'text',
          title: this.localisation.localise('agency_setup_address'),
          autocomplete: '',
          placeholder: this.localisation.localise('agency_setup_address_placeholder'),
          help: this.localisation.localise('agency_setup_address_help'),
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: this.localisation.localise('agency_setup_error_required_field'),
            },
            <ISubmitFormInputErrors>{
              code: 'validAddress',
              message: this.localisation.localise('agency_setup_error_valid_address'),
            },
          ]
        },
        {
          name: 'extra',
          value: compliment,
          validators: [],
          type: 'text',
          title: this.localisation.localise('agency_setup_addresscompl'),
          autocomplete: '',
          placeholder: this.localisation.localise('agency_setup_addresscompl_placeholder'),
          help: this.localisation.localise('agency_setup_addresscompl_help'),
          errors: []
        },
        {
          name: 'telephone',
          value: profile.telephone,
          validators: [Validators.required],
          type: 'text',
          title: this.localisation.localise('agency_setup_tel'),
          autocomplete: '',
          placeholder: this.localisation.localise('agency_setup_tel_placeholder'),
          help: this.localisation.localise('agency_setup_tel_help'),
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: this.localisation.localise('agency_setup_error_required_field'),
            },
          ]
        },
        {
          name: 'email',
          value: profile.email,
          validators: [Validators.required, Validators.email],
          type: 'email',
          title: this.localisation.localise('agency_setup_email'),
          autocomplete: '',
          placeholder:  this.localisation.localise('agency_setup_email_placeholder'),
          help: this.localisation.localise('agency_setup_email_help'),
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: this.localisation.localise('agency_setup_error_required_field'),
            },
            <ISubmitFormInputErrors>{
              code: 'email',
              message: this.localisation.localise('agency_setup_error_invalid_email'),
            },
          ]
        },

        {
          name: 'coords',
          value: profile.coords,
          validators: [Validators.required],
          type: 'text',
          title: '',
          autocomplete: '',
          placeholder: '',
          help: '',
          errors: [
          ]
        },
        {
          name: 'address',
          value: profile.address,
          validators: [Validators.required],
          type: 'text',
          title: '',
          autocomplete: '',
          placeholder: '',
          help: '',
          errors: [
          ]
        }
      ],
      // Submit callback
      (changes) => {
        this.busy = true;

        const trad: ITraducteur = this.updateData();

        return this.traducteur.UpdateProfile(trad);

      },

      // Success callback
      () => {
        this.busy = false;
      },

      // Fail callback
      err => {
        // What to do with login failuer
        console.error(err);
        this.busy = false;
      },

      // Changes callback
      null
    );

  }

  formatter = (loc: GeoCodeResult) => (loc && loc.address) ? loc.address.formatted : '';

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(
        term =>
          term.length < 3
            ? []
            : this.geo.geocode(term)
      )
    )

  onAddressSelected(address: GeoCodeResult) {

    const oldAddress = this.profileForm.GetValue('address');
    if (oldAddress && oldAddress.extra) {
      address.address.extra = oldAddress.extra;
    }

    this.profileForm.SetValue('coords', address.coords);
    this.profileForm.SetValue('address', address.address);
  }

  async disconnect() {
    this.busy = true;
    await this.hiero.Auth.logout();
    this.router.navigate(['compte', 'connexion']);
  }

  updateData(): ITraducteur {

    const traducteur: ITraducteur = {
      uid: this.profile.uid,
      businessName: this.profileForm.GetValue('businessName'),
      email: this.profileForm.GetValue('email'),
      telephone: this.profileForm.GetValue('telephone'),
      address: this.profileForm.GetValue('address'),
      coords: this.profileForm.GetValue('coords'),
      registration: this.profile.registration,
    };

    traducteur.address.extra = this.profileForm.GetValue('extra');

    return traducteur;
  }

  async manualAddress() {

    const modalRef = this.modalService.open(AppModalManualAddressComponent, {
      centered: true,
      backdrop : 'static',
      keyboard : false
    });
    modalRef.componentInstance.address = this.profileForm.GetValue('address');
    modalRef.componentInstance.coords = this.profileForm.GetValue('coords');
    let result: any = false;
    try {
      result = await modalRef.result;
    } catch {}

    if (result) {
      this.busy = true;
      try {      
        const trad = this.updateData();
        const extra = trad.address.extra;
        trad.address = result.address;
        trad.address.extra = extra;

        trad.coords = result.coords;

        this.createForm(trad);

        this.profileForm.CheckValidity();

      } catch (err) {
        console.error(err.message);
      } finally {
        this.busy = false;
      }
    }
  }



}
