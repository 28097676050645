import * as jsPDF from 'jspdf';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { EPDFRotation } from '../../bdd/interfaces/IPDF';
import { HELVETICA_BOLD } from './helvetica-bold';
import { HELVETICA_BOLD_ITALIC } from './helvetica-bold-italic';
import { HELVETICA_ITALIC } from './helvetica-italic';
import { HELVETICA_REGULAR } from './helvetica-regular';
var PDFRender = /** @class */ (function () {
    function PDFRender(pdf) {
        this.pageWidth = 0;
        this.pageHeight = 0;
        this.pdf = pdf;
        this.stringSubject = new BehaviorSubject(this.renderToUriString());
    }
    Object.defineProperty(PDFRender.prototype, "Height", {
        get: function () {
            return this.pageHeight;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PDFRender.prototype, "Width", {
        get: function () {
            return this.pageWidth;
        },
        enumerable: true,
        configurable: true
    });
    PDFRender.prototype.Watch = function (observer) {
        return this.stringSubject.subscribe(observer);
    };
    PDFRender.prototype.renderToUriString = function (cleanVersion) {
        if (cleanVersion === void 0) { cleanVersion = false; }
        if (this.pdf.pages.length === 0) {
            console.log("Oops, pdf empty");
            return null;
        }
        var doc = this.renderDoc(cleanVersion);
        var data = doc.output('datauristring');
        if (this.stringSubject) {
            this.stringSubject.next(data);
        }
        return data;
    };
    PDFRender.prototype.renderToBlob = function (cleanVersion) {
        if (cleanVersion === void 0) { cleanVersion = true; }
        if (this.pdf.pages.length === 0) {
            return null;
        }
        var doc = this.renderDoc(cleanVersion);
        var data = doc.output('blob');
        return data;
    };
    Object.defineProperty(PDFRender.prototype, "Data", {
        get: function () {
            return this.pdf;
        },
        enumerable: true,
        configurable: true
    });
    PDFRender.prototype.clearHighlights = function () {
        this.pdf.pages.forEach(function (page) {
            page.inputs.forEach(function (input) {
                input.fill = null;
            });
        });
    };
    PDFRender.prototype.renderDoc = function (cleanVersion) {
        var firstPage = this.pdf.pages[0];
        this.pageWidth = firstPage.width;
        this.pageHeight = firstPage.height;
        var por = 'portrait';
        switch (firstPage.orientation) {
            case EPDFRotation.Portrait:
                por = 'portrait';
                break;
            case EPDFRotation.LandscapeLeft:
                por = 'landscape';
                break;
            case EPDFRotation.LandscapeRight:
                por = 'landscape';
                break;
            case EPDFRotation.PortaitInvert:
                por = 'portrait';
                break;
        }
        var doc = new jsPDF({
            orientation: por,
            unit: 'pt',
            format: [firstPage.width, firstPage.height],
            putOnlyUsedFonts: true
        });
        doc.addFileToVFS('Helvetica-Regular-normal.ttf', HELVETICA_REGULAR);
        doc.addFileToVFS('Helvetica-Bold-normal.ttf', HELVETICA_BOLD);
        doc.addFileToVFS('Helvetica-Italic-normal.ttf', HELVETICA_ITALIC);
        doc.addFileToVFS('Helvetica-Bold-Italic-normal.ttf', HELVETICA_BOLD_ITALIC);
        doc.addFont('Helvetica-Regular-normal.ttf', 'HelveticaCustom', 'normal');
        doc.addFont('Helvetica-Bold-normal.ttf', 'HelveticaCustom', 'bold');
        doc.addFont('Helvetica-Italic-normal.ttf', 'HelveticaCustom', 'italic');
        doc.addFont('Helvetica-Bold-Italic-normal.ttf', 'HelveticaCustom', 'bolditalic');
        this.pdf.pages.forEach(function (page, index) {
            if (index > 0) {
                doc.addPage([page.width, page.height]);
            }
            page.inputs.forEach(function (input) {
                if (!cleanVersion) {
                    if (input.fill) {
                        doc.setDrawColor(input.fill);
                        doc.setFillColor(input.fill);
                        doc.rect(input.rectX, input.rectY, input.width, input.height, 'F');
                    }
                }
                if (!!input.fontSize) {
                    doc.setFontSize(input.fontSize);
                }
                else {
                    doc.setFontSize(9);
                }
                doc.setFont('HelveticaCustom');
                if (input.bold) {
                    if (input.italic) {
                        doc.setFontStyle('bolditalic');
                    }
                    else {
                        doc.setFontStyle('bold');
                    }
                }
                else {
                    if (input.italic) {
                        doc.setFontStyle('italic');
                    }
                    else {
                        doc.setFontStyle('normal');
                    }
                }
                doc.text(input.text, input.x, input.y, {
                    align: input.textAlign,
                    baseline: 'top'
                });
            });
        });
        return doc;
    };
    return PDFRender;
}());
export { PDFRender };
