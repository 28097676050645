var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentType } from '../../../../../../../common/src/bdd/documents/DocumentType';
import { Template } from '../../../../../../../common/src/bdd/documents/Template';
import { File } from '../../../../../../../common/src/bdd/prestation/File';
import { Prestation } from '../../../../../../../common/src/bdd/prestation/Prestation';
import { PDFRender } from '../../../../../../../common/src/components/pdfpreview/pdfrender';
import { HieroBDD } from '../../../../../services/hierobdd.service';
import { LocalisationService } from '../../../../../../../common/src/modules/localisation/localisation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppModalConfirmComponent } from '../../../../../../../common/src/utility/modal/confirm-modal.component';
var PrestationEditorComponent = /** @class */ (function () {
    function PrestationEditorComponent(hiero, route, router, cdRef, localisation, modalService) {
        this.hiero = hiero;
        this.route = route;
        this.router = router;
        this.cdRef = cdRef;
        this.localisation = localisation;
        this.modalService = modalService;
        this.errorLoadingTemplate = false;
        this.busy = true;
    }
    PrestationEditorComponent.prototype.ngOnDestroy = function () {
        this.traducteurSub.unsubscribe();
        this.prestationSub.unsubscribe();
        if (this.prestation) {
            this.prestation.cleanup();
        }
    };
    PrestationEditorComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.documentId = this.route.snapshot.paramMap.get('documentId');
        var prestationId = this.route.snapshot.parent.paramMap.get('prestationId');
        this.traducteurSub = this.hiero.WatchTraducteur({
            next: function (traducteur) {
                if (traducteur) {
                    _this.traducteur = traducteur;
                    Prestation.Load(traducteur.User, true, prestationId, true)
                        .then(function (prestation) {
                        // Clean up old one
                        if (_this.prestation) {
                            _this.prestation.cleanup();
                        }
                        if (_this.prestationSub) {
                            _this.prestationSub.unsubscribe();
                        }
                        // Replace with new one
                        _this.prestation = prestation;
                        _this.setupDocument();
                        // Subscribe
                        _this.prestationSub = _this.prestation.Watch({
                            next: function (updated) {
                                _this.prestation = updated;
                                _this.setupDocument();
                            }
                        });
                        _this.cdRef.detectChanges();
                    });
                    DocumentType.Init(traducteur.User)
                        .then(function (types) {
                        _this.documentTypeMap = types;
                    });
                }
            }
        });
    };
    PrestationEditorComponent.prototype.setupDocument = function () {
        var _this = this;
        this.document = this.prestation.Data.documents.find(function (doc) { return doc.deviceStorageId === _this.documentId; });
        // Get template
        if (this.document && !this.template) {
            if (!this.document.translation) {
                Template.Find(this.traducteur.User, this.prestation.Data.srcLanguageIso639, this.prestation.Data.destLanguageIso639, this.prestation.Data.srcCountryCode, this.document.documentTypeId)
                    .then(function (template) {
                    if (!template) {
                        _this.errorLoadingTemplate = true;
                    }
                    else {
                        _this.template = template;
                        _this.pdfData = _this.template.Data.config;
                        _this.pdfRender = new PDFRender(_this.pdfData);
                    }
                    _this.busy = false;
                })
                    .catch(function () {
                    _this.errorLoadingTemplate = true;
                });
            }
            else {
                this.pdfData = this.document.translation;
                this.pdfRender = new PDFRender(this.pdfData);
                this.busy = false;
            }
        }
    };
    PrestationEditorComponent.prototype.getDocumentName = function (code) {
        if (this.documentTypeMap) {
            return this.documentTypeMap.GetDocumentName(this.localisation.CurrentLanguageISO639, code);
        }
        else {
            return '';
        }
    };
    PrestationEditorComponent.prototype.openFile = function (prestation, document, file) {
        return __awaiter(this, void 0, void 0, function () {
            var fileObj, link, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        fileObj = new File(this.hiero.Storage, prestation.uid, prestation, document.deviceStorageId, file);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, fileObj.GetDownloadLink()];
                    case 2:
                        link = _a.sent();
                        window.open(link, '_blank');
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        console.error(err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PrestationEditorComponent.prototype.onUpdate = function (data) {
        this.prestation.UpdateDocumentTranslation(this.document, data);
    };
    PrestationEditorComponent.prototype.uploadFile = function () {
        return __awaiter(this, void 0, void 0, function () {
            var blob, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.pdfRender) return [3 /*break*/, 4];
                        this.busy = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        blob = this.pdfRender.renderToBlob(true);
                        return [4 /*yield*/, this.prestation.UploadTranslatedFile(this.hiero.Storage, this.document, 'hierø-translated', blob)];
                    case 2:
                        _a.sent();
                        this.router.navigate(['/app', 'prestation', this.prestation.Id]);
                        this.busy = false;
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        console.error(err_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PrestationEditorComponent.prototype.resetTemplate = function () {
        return __awaiter(this, void 0, void 0, function () {
            var modalRef, result, _a, template, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        modalRef = this.modalService.open(AppModalConfirmComponent, {
                            centered: true,
                        });
                        modalRef.componentInstance.header = this.localisation.localise('prestation_document_confirm_title');
                        modalRef.componentInstance.message = this.localisation.localise('prestation_document_confirm_message');
                        modalRef.componentInstance.ok = this.localisation.localise('prestation_document_confirm_oui');
                        modalRef.componentInstance.cancel = this.localisation.localise('prestation_document_confirm_non');
                        result = false;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, modalRef.result];
                    case 2:
                        result = _c.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        _a = _c.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        if (!result) return [3 /*break*/, 9];
                        this.busy = true;
                        _c.label = 5;
                    case 5:
                        _c.trys.push([5, 7, , 8]);
                        return [4 /*yield*/, Template.Find(this.traducteur.User, this.prestation.Data.srcLanguageIso639, this.prestation.Data.destLanguageIso639, this.prestation.Data.srcCountryCode, this.document.documentTypeId)];
                    case 6:
                        template = _c.sent();
                        this.template = template;
                        this.pdfData = this.template.Data.config;
                        this.pdfRender = new PDFRender(this.pdfData);
                        this.onUpdate(this.pdfData);
                        return [3 /*break*/, 8];
                    case 7:
                        _b = _c.sent();
                        this.errorLoadingTemplate = true;
                        return [3 /*break*/, 8];
                    case 8:
                        this.busy = false;
                        _c.label = 9;
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    return PrestationEditorComponent;
}());
export { PrestationEditorComponent };
