var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BehaviorSubject } from 'rxjs';
import { EnumPrestationState } from '../interfaces/IPrestation';
var FIREBASE_TEMPLATE_COLLECTION = 'templates';
var FIREBASE_PRESTATIONS_COLLECTION = 'prestations';
var Template = /** @class */ (function () {
    function Template(user, docRef, id, raw, watch) {
        var _this = this;
        this._user = user;
        this._docRef = docRef;
        this._id = id;
        this._raw = raw;
        this._templateSubject = new BehaviorSubject(this);
        if (watch) {
            this._stopListening = docRef.onSnapshot({
                next: function (snapshot) {
                    if (snapshot.exists) {
                        _this._raw = snapshot.data();
                        _this._templateSubject.next(_this);
                    }
                },
                error: function (err) {
                }
            });
        }
    }
    Template.prototype.cleanup = function () {
        if (this._stopListening) {
            this._stopListening();
            this._stopListening = null;
        }
    };
    Object.defineProperty(Template.prototype, "Id", {
        get: function () {
            return this._id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Template.prototype, "Data", {
        get: function () {
            return this._raw;
        },
        enumerable: true,
        configurable: true
    });
    Template.prototype.Watch = function (observer) {
        return this._templateSubject.subscribe(observer);
    };
    Template.prototype.Update = function (template) {
        return __awaiter(this, void 0, void 0, function () {
            var willCreateDouble;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Template.WillCreateDouble(this._user, template, this.Data)];
                    case 1:
                        willCreateDouble = _a.sent();
                        if (willCreateDouble) {
                            throw Error('Pas autorisé : un template avec la même configuration existe déjà');
                        }
                        return [4 /*yield*/, this._docRef.update(template)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Template.prototype.Remove = function () {
        return __awaiter(this, void 0, void 0, function () {
            var willImpactPrestations;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.WillImpactPrestations()];
                    case 1:
                        willImpactPrestations = _a.sent();
                        if (willImpactPrestations) {
                            throw Error('Pas autorisé : il y a déjà des missions qui utilisent ce template');
                        }
                        return [4 /*yield*/, this._docRef.delete()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Template.prototype.WillImpactPrestations = function () {
        return __awaiter(this, void 0, void 0, function () {
            var query, snapshots, foundUsed;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = this._user.DB.collection(FIREBASE_PRESTATIONS_COLLECTION)
                            .where('srcLanguageIso639', '==', this.Data.srcLanguageIso639)
                            .where('destLanguageIso639', '==', this.Data.destLanguageIso639)
                            .where('srcCountryCode', '==', this.Data.srcCountryCode);
                        return [4 /*yield*/, query.get()];
                    case 1:
                        snapshots = _a.sent();
                        foundUsed = false;
                        snapshots.forEach(function (snapshot) {
                            var prestation = snapshot.data();
                            if (prestation.state !== EnumPrestationState.RefusedByTranslator &&
                                prestation.state !== EnumPrestationState.Validated &&
                                prestation.state !== EnumPrestationState.CancelledByClient) {
                                // Check only prestations that can currently change
                                var found = prestation.documents.find(function (doc) { return doc.documentTypeId === _this.Data.documentTypeId; });
                                if (found) {
                                    foundUsed = true;
                                }
                            }
                        });
                        return [2 /*return*/, foundUsed];
                }
            });
        });
    };
    Template.WillCreateDouble = function (user, template, current) {
        return __awaiter(this, void 0, void 0, function () {
            var query, snapshots;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (current) {
                            if (template.srcLanguageIso639 === current.srcLanguageIso639 &&
                                template.destLanguageIso639 === current.destLanguageIso639 &&
                                template.srcCountryCode === current.srcCountryCode &&
                                template.documentTypeId === current.documentTypeId) {
                                // No change, no need to check
                                return [2 /*return*/, Promise.resolve(false)];
                            }
                        }
                        query = user.DB.collection(FIREBASE_TEMPLATE_COLLECTION)
                            .where('srcLanguageIso639', '==', template.srcLanguageIso639)
                            .where('destLanguageIso639', '==', template.destLanguageIso639)
                            .where('srcCountryCode', '==', template.srcCountryCode)
                            .where('documentTypeId', '==', template.documentTypeId);
                        return [4 /*yield*/, query.get()];
                    case 1:
                        snapshots = _a.sent();
                        return [2 /*return*/, !snapshots.empty];
                }
            });
        });
    };
    Template.Create = function (user, template) {
        return __awaiter(this, void 0, void 0, function () {
            var willCreateDouble, docRef, snapshot, raw;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Template.WillCreateDouble(user, template)];
                    case 1:
                        willCreateDouble = _a.sent();
                        if (willCreateDouble) {
                            throw Error('Pas autorisé : un template avec la même configuration existe déjà');
                        }
                        return [4 /*yield*/, user.DB.collection(FIREBASE_TEMPLATE_COLLECTION).add(template)];
                    case 2:
                        docRef = _a.sent();
                        return [4 /*yield*/, docRef.get()];
                    case 3:
                        snapshot = _a.sent();
                        raw = snapshot.data();
                        return [2 /*return*/, new Template(user, docRef, snapshot.id, raw)];
                }
            });
        });
    };
    Template.Load = function (user, templateId, watch) {
        return __awaiter(this, void 0, void 0, function () {
            var doc, snapshot;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        doc = user.DB.collection(FIREBASE_TEMPLATE_COLLECTION).doc(templateId);
                        return [4 /*yield*/, doc.get()];
                    case 1:
                        snapshot = _a.sent();
                        if (!snapshot.exists) {
                            return [2 /*return*/, Promise.reject('No template found with that id')];
                        }
                        else {
                            return [2 /*return*/, new Template(user, doc, templateId, snapshot.data(), watch)];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    Template.Find = function (user, srcLanguageIso639, destLanguageIso639, srcCountryCode, documentTypeId) {
        return __awaiter(this, void 0, void 0, function () {
            var query, snapshot, snapDoc;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = user.DB.collection(FIREBASE_TEMPLATE_COLLECTION)
                            .where('srcLanguageIso639', '==', srcLanguageIso639)
                            .where('destLanguageIso639', '==', destLanguageIso639)
                            .where('srcCountryCode', '==', srcCountryCode)
                            .where('documentTypeId', '==', documentTypeId);
                        return [4 /*yield*/, query.get()];
                    case 1:
                        snapshot = _a.sent();
                        if (snapshot && !snapshot.empty && snapshot.docs.length > 0) {
                            snapDoc = snapshot.docs[0];
                            return [2 /*return*/, Template.Load(user, snapDoc.id, false)];
                        }
                        else {
                            return [2 /*return*/, Promise.reject('No template found matching src lang, dst lang, country and document type')];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return Template;
}());
export { Template };
