import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { EmptyUserProfile, IUserProfile } from '../../../../../common/src/bdd/interfaces/IUserProfile';
import { User } from '../../../../../common/src/bdd/user/User';
import { HieroBDD } from '../../../services/hierobdd.service';
import { ISubmitFormInputErrors, SubmitForm } from '../../../../../common/src/utility/forms/submitform.class';
import { LocalisationService } from '../../../../../common/src/modules/localisation/localisation.service';


@Component({
  templateUrl: './profile.component.html'
})

export class ProfileComponent implements OnInit, OnDestroy {

  user: User;
  profile: IUserProfile;
  profileForm: SubmitForm;
  
  userSubs: Subscription;
  profSubs: Subscription;

  busy = true;

  constructor(
    private hiero: HieroBDD,
    private fb: FormBuilder,
    private localisation: LocalisationService
  ) {
    this.createForm(this.profile || EmptyUserProfile);
  }

  ngOnInit() {
    // Add listeners for the data we want
    // These listeners will either fire immediately,
    // or they will fire when the data becomes available
    this.userSubs = this.hiero.Auth.WatchUser({
      next: (user: User) => {
        if (user) {
          // We have a user, save a reference
          this.user = user;

          if (this.user) {
            // The user is not null, watch for the user profile
            this.profSubs = this.user.WatchProfile({
              next: (profile: IUserProfile) => {
                if (profile) {
                  this.profile = profile;
                  this.createForm(profile);
                }

                this.busy = false;
              }
            });
          }
        }
      }
    });
  }

  ngOnDestroy() {
    this.profSubs.unsubscribe();
    this.userSubs.unsubscribe();
  }

  createForm(profile: IUserProfile) {
    this.profileForm = new SubmitForm(
      this.fb,
      [
        {
          name: 'givenName',
          value: profile.givenName,
          validators: [Validators.required],
          type: 'text',
          title: this.localisation.localise('account_name'),
          autocomplete: 'given-name',
          placeholder: this.localisation.localise('account_name_placeholder'),
          help: this.localisation.localise('account_name_help'),
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: this.localisation.localise('account_error_required'),
            },
          ]
        },
        {
          name: 'familyName',
          value: profile.familyName,
          validators: [Validators.required],
          type: 'text',
          title: this.localisation.localise('account_surname'),
          autocomplete: 'family-name',
          placeholder: this.localisation.localise('account_surname_placeholder'),
          help: this.localisation.localise('account_surname_placeholder'),
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: this.localisation.localise('account_error_required'),
            },
          ]
        },
        {
          name: 'telephone',
          value: profile.telephone,
          validators: [Validators.required],
          type: 'text',
          title: this.localisation.localise('account_telephone'),
          autocomplete: 'tel',
          placeholder: this.localisation.localise('account_telephone_placeholder'),
          help: this.localisation.localise('account_telephone_help'),
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: this.localisation.localise('account_error_required'),
            },
          ]
        },
      ],
      // Submit callback
      (changes) => {
        this.busy = true;
        const prom = this.user.UpdateProfile({
          familyName: this.profileForm.GetValue('familyName'),
          givenName: this.profileForm.GetValue('givenName'),
          telephone: this.profileForm.GetValue('telephone'),
        });
        return prom;
      },

      // Success callback
      () => {
        // What to do with login success ?
        this.busy = false;
      },

      // Fail callback
      err => {
        // What to do with login failuer
        console.error(err);
        this.busy = false;
      },

      // Changes callback
      null
    );
  }
}
