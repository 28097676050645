var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { UserProfileSchema } from '../interfaces/IUserProfile';
export var ERoleType;
(function (ERoleType) {
    ERoleType["client"] = "client";
    ERoleType["traducteur"] = "traducteur";
    ERoleType["admin"] = "admin";
})(ERoleType || (ERoleType = {}));
var ERoleAccess;
(function (ERoleAccess) {
    ERoleAccess["granted"] = "granted";
    ERoleAccess["denied"] = "denied";
})(ERoleAccess || (ERoleAccess = {}));
var EAsyncResult;
(function (EAsyncResult) {
    EAsyncResult[EAsyncResult["DoNothing"] = 0] = "DoNothing";
    EAsyncResult[EAsyncResult["Negative"] = 1] = "Negative";
    EAsyncResult[EAsyncResult["Positive"] = 2] = "Positive";
})(EAsyncResult || (EAsyncResult = {}));
/**
 * The User class represents a user in the Hiero system, and is generally identified by an email address.
 * A user may be a translator AND a paying customer, in both cases he/she will have the same ID address.
 * For this reason, during sign-up or login, we must specify which ROLE we are interested in.
 *
 * This class will automatically monitor for changes to the user profile, and roles through the Watch* methods.
 */
var User = /** @class */ (function () {
    function User(fbUser, db, uid, role, profile) {
        var _this = this;
        this._fbUser = fbUser;
        this._db = db;
        this._profileSubject = new BehaviorSubject(profile);
        this._role = new BehaviorSubject(role);
        this._authorized = new BehaviorSubject(role.access === ERoleAccess.granted);
        this._docRef = db.collection('users').doc(uid);
        // Subscribe to the snapshot
        this._docRef.onSnapshot(function (snapshot) {
            var snapProfile = snapshot.get('profile');
            _this._profileSubject.next(snapProfile);
            var roles = snapshot.get('roles');
            var foundRole = roles.find(function (oneRole) {
                return (oneRole.type === _this._role.value.type);
            });
            if (foundRole) {
                if (_this._role.value.type !== foundRole.type || _this._role.value.access !== foundRole.access) {
                    _this._role.next(foundRole);
                }
                if (_this._role.value.access !== foundRole.access) {
                    _this._authorized.next(foundRole.access === ERoleAccess.granted);
                }
            }
            else {
                // If role is removed on the server, deny
                _this._authorized.next(false);
            }
        }, function (err) {
            _this._profileSubject.next(null);
            _this._authorized.next(false);
        }, function () {
        });
    }
    Object.defineProperty(User.prototype, "Id", {
        /**
         * The user id as in the Firestore database
         */
        get: function () {
            return this._fbUser.uid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(User.prototype, "Email", {
        /**
         * The user email address
         */
        get: function () {
            return this._fbUser.email;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(User.prototype, "DB", {
        /**
         * Reference to the firestore database.
         */
        get: function () {
            return this._db;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Subscribe to get updates to the user profile.
     * @param observer
     */
    User.prototype.WatchProfile = function (observer) {
        return this._profileSubject.subscribe(observer);
    };
    Object.defineProperty(User.prototype, "Profile", {
        get: function () {
            return this._profileSubject.value;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Subscribe to get updates to the user roles
     * @param observer
     */
    User.prototype.WatchRole = function (observer) {
        return this._role.subscribe(observer);
    };
    /**
     * Updates the user profile.
     * The passed data is first validated, then sent to the server. It will result in an update to the user profile,
     * so all subscriptions should automatically update.
     * @param profile The user profile structure that you wish to update
     * @throws ValidationError
     */
    User.prototype.UpdateProfile = function (profile) {
        return __awaiter(this, void 0, void 0, function () {
            var validatedProfile, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        validatedProfile = null;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, UserProfileSchema.validate(profile, {
                                strict: false,
                                abortEarly: false,
                                stripUnknown: true
                            })];
                    case 2:
                        validatedProfile = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        return [2 /*return*/, Promise.reject(err_1)];
                    case 4: return [4 /*yield*/, this._docRef.update({
                            profile: validatedProfile
                        })];
                    case 5:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    User.prototype.UpdateEmail = function (email) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._fbUser.updateEmail(email)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    User.prototype.UpdateFCMToken = function (token) {
        return __awaiter(this, void 0, void 0, function () {
            var err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        console.log("User doc: " + this._docRef.id);
                        console.log("Updating fcm token to: " + token);
                        return [4 /*yield*/, this._docRef.update({
                                fcm: token
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        err_2 = _a.sent();
                        console.warn(err_2.message);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    User.prototype.UpdateDisplayLanguage = function (langCode) {
        return __awaiter(this, void 0, void 0, function () {
            var err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this._docRef.update({
                                displayLang: langCode
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        err_3 = _a.sent();
                        console.warn(err_3.message);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    User.prototype.GetIDToken = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._fbUser.getIdToken(true)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /// ---------------------------- FACTORY METHODS ---------------------------- ///
    /**
     * Factory method for creating a user. This method will try find the user, create the profile if necessary and verify the user roles,
     * before creating an instance of the user.
     * @param fbUser The firebase user object
     * @param db The firestore database object
     * @param role The role we are logging in for
     * @param profile An optional profile, to use for creating a new profile
     */
    // tslint:disable-next-line: member-ordering
    User.Init = function (fbUser, db, role, iProfile) {
        return __awaiter(this, void 0, void 0, function () {
            var profileDocRef, unsubscribe, profile, success, roleStruct;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        profileDocRef = db.collection('users').doc(fbUser.uid);
                        unsubscribe = null;
                        profile = null;
                        success = false;
                        roleStruct = {
                            access: ERoleAccess.denied,
                            type: role
                        };
                        return [4 /*yield*/, new Promise(function (resolve, reject) {
                                // Subscribe to the snapshot listener
                                unsubscribe = profileDocRef.onSnapshot(function (snapshot) {
                                    // Case 1: No profile exists for this user, create one
                                    if (!snapshot.exists) {
                                        if (iProfile) {
                                            // There is a profile, set it up as default one
                                            // This is async, but will not stop and wait for it, as we are expecting the snapshot to update
                                            _this._setupProfile(profileDocRef, iProfile)
                                                .catch(function (err) {
                                                // If there was a problem, snapshot won't update so should reject
                                                console.log('Error setting up profile');
                                                reject(err);
                                            });
                                        }
                                        else {
                                            // No profile, are we signing in instead of signing-up ?
                                            reject('No profile was found and none was provided!');
                                        }
                                    }
                                    else {
                                        // Snapshot received, get profile
                                        profile = snapshot.get('profile');
                                        // Check the user role for this platform
                                        _this._checkRoles(role, snapshot)
                                            .then(function (result) {
                                            if (result === EAsyncResult.Negative) {
                                                // Negative response, do not have permission to continue with this user
                                                roleStruct.access = ERoleAccess.denied;
                                                resolve(false);
                                            }
                                            else if (result === EAsyncResult.Positive) {
                                                // Have permission !
                                                roleStruct.access = ERoleAccess.granted;
                                                success = true;
                                                resolve(true);
                                            }
                                        })
                                            .catch(function (err) {
                                            console.log(err);
                                            reject('Error creating or validating roles!');
                                        });
                                    }
                                }, function () {
                                    // .log('Error reading user snapshot.. TODO: should this fail the init process?');
                                    // NOT SURE IF I SHOULD PUT A REJECT HERE?
                                });
                                // Start listening
                                profileDocRef.get({ source: 'server' })
                                    .catch(function (err) {
                                    console.log(err);
                                });
                            })
                                .catch(function (err) {
                                console.log(err);
                            })];
                    case 1:
                        _a.sent();
                        // In all cases, unsubscribe
                        if (unsubscribe) {
                            unsubscribe();
                        }
                        if (success) {
                            return [2 /*return*/, new User(fbUser, db, fbUser.uid, roleStruct, profile)];
                        }
                        else {
                            return [2 /*return*/, null];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    // tslint:disable-next-line: member-ordering
    User._setupProfile = function (profileDocRef, profile) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, profileDocRef.set({
                            profile: profile
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, true];
                }
            });
        });
    };
    User._createRole = function (requestedRole, snapshot) {
        return __awaiter(this, void 0, void 0, function () {
            var role;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        role = {
                            type: requestedRole,
                            access: ERoleAccess.granted
                        };
                        // NOTE: MAY THROW
                        return [4 /*yield*/, snapshot.ref.update({
                                roles: [role]
                            })];
                    case 1:
                        // NOTE: MAY THROW
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    User._checkRoles = function (requestedRole, snapshot) {
        return __awaiter(this, void 0, void 0, function () {
            var roles, foundRole;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        roles = snapshot.get('roles');
                        if (!(!roles || roles.length === 0)) return [3 /*break*/, 2];
                        // No roles yet, add this person as a translator
                        // May throw
                        return [4 /*yield*/, this._createRole(requestedRole, snapshot)];
                    case 1:
                        // No roles yet, add this person as a translator
                        // May throw
                        _a.sent();
                        // The above will result in a recall of the callback, so return here
                        return [2 /*return*/, EAsyncResult.DoNothing];
                    case 2:
                        foundRole = roles.find(function (role) {
                            return (role.type === requestedRole);
                        });
                        if (!!foundRole) return [3 /*break*/, 4];
                        // No roles yet, add this person as a translator
                        // May throw
                        return [4 /*yield*/, this._createRole(requestedRole, snapshot)];
                    case 3:
                        // No roles yet, add this person as a translator
                        // May throw
                        _a.sent();
                        // The above will result in a recall of the callback, so return here
                        return [2 /*return*/, EAsyncResult.DoNothing];
                    case 4:
                        // Have the role, is it granted or denied ?
                        if (foundRole.access !== ERoleAccess.granted) {
                            // Refuse, was deliberately denied by adming
                            return [2 /*return*/, EAsyncResult.Negative];
                        }
                        else {
                            return [2 /*return*/, EAsyncResult.Positive];
                        }
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    return User;
}());
export { User };
