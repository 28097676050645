import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { EmptyTraducteurProfile, ITraducteur } from '../../../../../common/src/bdd/interfaces/ITraducteur';
import { Config } from '../../../../../common/src/services/config.service';
import { GeoCodeResult, GoogleGeo } from '../../../services/google.services';
import { HieroBDD } from '../../../services/hierobdd.service';
import { ISubmitFormInputErrors, SubmitForm } from '../../../../../common/src/utility/forms/submitform.class';
import { haveValideAddressValidator } from '../../verify/profile/profilecheck.component';
import { LocalisationService } from '../../../../../common/src/modules/localisation/localisation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppModalManualAddressComponent } from '../../../utility/components/modal-manual-address/modal-manual-address.component';


@Component({
  templateUrl: './traducteur.component.html'
})

export class TraducteurComponent implements OnInit, OnDestroy {

  busy = true;
  profile: ITraducteur;
  profileForm: SubmitForm;

  profSubs: Subscription;

  constructor(
    private fb: FormBuilder,
    private hiero: HieroBDD,
    private config: Config,
    private router: Router,
    private geo: GoogleGeo,
    private localisation: LocalisationService,
    private modalService: NgbModal
  ) {
    this.createForm(this.profile || EmptyTraducteurProfile);
  }

  ngOnInit() {
    this.profSubs = this.hiero.WatchTraducteurProfile({
      next: (profile: ITraducteur) => {
        if (profile) {
          this.profile = profile;
          this.createForm(this.profile);
        }
        this.busy = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.profSubs) {
      this.profSubs.unsubscribe();
    }
  }

  createForm(profile: ITraducteur) {

    let compliment = '';
    if (profile && profile.address && profile.address.extra) {
      compliment = profile.address.extra;
    }

    const fullAddr: GeoCodeResult  = {
      address: profile.address,
      coords: profile.coords
    };

    this.profileForm = new SubmitForm(
      this.fb,
      [
        {
          name: 'businessName',
          value: profile.businessName,
          validators: [Validators.required],
          type: 'text',
          title: this.localisation.localise('agency_setup_companyname'),
          autocomplete: '',
          placeholder: this.localisation.localise('agency_setup_companyname_placeholder'),
          help: this.localisation.localise('agency_setup_companyname_help'),
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: this.localisation.localise('agency_setup_error_required_field'),
            },
          ]
        },
        {
          name: 'fulladdr',
          value: fullAddr,
          validators: [ haveValideAddressValidator('fulladdr') ],
          type: 'text',
          title: this.localisation.localise('agency_setup_address'),
          autocomplete: '',
          placeholder: this.localisation.localise('agency_setup_address_placeholder'),
          help: this.localisation.localise('agency_setup_address_help'),
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: this.localisation.localise('agency_setup_error_required_field'),
            },
            <ISubmitFormInputErrors>{
              code: 'validAddress',
              message: this.localisation.localise('agency_setup_error_valid_address'),
            },
          ]
        },
        {
          name: 'extra',
          value: compliment,
          validators: [],
          type: 'text',
          title: this.localisation.localise('agency_setup_addresscompl'),
          autocomplete: '',
          placeholder: this.localisation.localise('agency_setup_addresscompl_placeholder'),
          help: this.localisation.localise('agency_setup_addresscompl_help'),
          errors: []
        },
        {
          name: 'telephone',
          value: profile.telephone,
          validators: [Validators.required],
          type: 'text',
          title: this.localisation.localise('agency_setup_tel'),
          autocomplete: '',
          placeholder: this.localisation.localise('agency_setup_tel_placeholder'),
          help: this.localisation.localise('agency_setup_tel_help'),
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: this.localisation.localise('agency_setup_error_required_field'),
            },
          ]
        },
        {
          name: 'email',
          value: profile.email,
          validators: [Validators.required],
          type: 'email',
          title: this.localisation.localise('agency_setup_email'),
          autocomplete: '',
          placeholder:  this.localisation.localise('agency_setup_email_placeholder'),
          help: this.localisation.localise('agency_setup_email_help'),
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: this.localisation.localise('agency_setup_error_required_field'),
            },
            <ISubmitFormInputErrors>{
              code: 'email',
              message: this.localisation.localise('agency_setup_error_invalid_email'),
            },
          ]
        },

        {
          name: 'coords',
          value: profile.coords,
          validators: [Validators.required],
          type: 'text',
          title: '',
          autocomplete: '',
          placeholder: '',
          help: '',
          errors: [
          ]
        },
        {
          name: 'address',
          value: profile.address,
          validators: [Validators.required],
          type: 'text',
          title: '',
          autocomplete: '',
          placeholder: '',
          help: '',
          errors: [
          ]
        }
      ],
      // Submit callback
      (changes) => {
        this.busy = true;
        const trad: ITraducteur = this.updateData();
        return this.hiero.Traducteur.UpdateProfile(trad);
      },

      // Success callback
      () => {
        this.busy = false;
      },

      // Fail callback
      err => {
        // What to do with login failuer
        console.error(err);
        this.busy = false;
      },

      // Changes callback
      null
    );

  }

  formatter = (loc: GeoCodeResult) => (loc && loc.address) ? loc.address.formatted : '';

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(
        term =>
          term.length < 3
            ? []
            : this.geo.geocode(term)
      )
    )

  onAddressSelected(address: GeoCodeResult) {

    const oldAddress = this.profileForm.GetValue('address');
    if (oldAddress && oldAddress.extra) {
      address.address.extra = oldAddress.extra;
    }

    this.profileForm.SetValue('coords', address.coords);
    this.profileForm.SetValue('address', address.address);    
  }

  updateData(): ITraducteur {

    const traducteur: ITraducteur = {
      uid: this.profile.uid,
      businessName: this.profileForm.GetValue('businessName'),
      email: this.profileForm.GetValue('email'),
      telephone: this.profileForm.GetValue('telephone'),
      address: this.profileForm.GetValue('address'),
      coords: this.profileForm.GetValue('coords'),
      registration: this.profile.registration,
    };

    traducteur.address.extra = this.profileForm.GetValue('extra');

    return traducteur;
  }

  async manualAddress() {

    const modalRef = this.modalService.open(AppModalManualAddressComponent, {
      centered: true,
      backdrop : 'static',
      keyboard : false
    });
    modalRef.componentInstance.address = this.profileForm.GetValue('address');
    modalRef.componentInstance.coords = this.profileForm.GetValue('coords');
    let result: any = false;
    try {
      result = await modalRef.result;
    } catch {}

    if (result) {
      this.busy = true;
      try {      
        const trad = this.updateData();
        const extra = trad.address.extra;
        trad.address = result.address;
        trad.address.extra = extra;

        trad.coords = result.coords;

        this.createForm(trad);

        this.profileForm.CheckValidity();

      } catch (err) {
        console.error(err.message);
      } finally {
        this.busy = false;
      }
    }
  }
}
