var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Template } from './Template';
var FIREBASE_TEMPLATE_COLLECTION = 'templates';
var TemplateList = /** @class */ (function () {
    function TemplateList(user, query, initSnap, watch) {
        var _this = this;
        this._totalFilteredUnpagedCount = 0;
        this._page = 0;
        this._pageSize = 10;
        this.getData = null;
        this._user = user;
        this.reconstruct(initSnap);
        this._listSubject = new BehaviorSubject(this.All);
        if (watch) {
            this._stopListening = query.onSnapshot({
                next: function (snapshot) {
                    _this.reconstruct(snapshot);
                },
                error: function (err) {
                }
            });
        }
    }
    Object.defineProperty(TemplateList.prototype, "Count", {
        get: function () {
            return this._filteredArr.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TemplateList.prototype, "TotalCount", {
        /**
         * Gets the number of filtered items before paging
         */
        get: function () {
            return this._totalFilteredUnpagedCount;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TemplateList.prototype, "All", {
        /**
         * Get a copy of your query
         */
        get: function () {
            return this._filteredArr.slice();
        },
        enumerable: true,
        configurable: true
    });
    TemplateList.prototype.updateQuery = function (filters, order, page, pageSize) {
        this._filter = filters;
        this._order = order;
        this._page = page;
        this._pageSize = pageSize;
        // Filter and order
        this._filteredArr = this.getWithQuery(this._filter, this._order, page, pageSize);
        if (this._listSubject) {
            this._listSubject.next(this.All);
        }
    };
    TemplateList.prototype.getWithQuery = function (filters, order, page, pageSize) {
        var _this = this;
        if (page === void 0) { page = 0; }
        if (pageSize === void 0) { pageSize = 10; }
        // Filter by selected states
        var result = [];
        if (filters && filters.length > 0) {
            var filterSet_1 = new Set();
            filters.forEach(function (filter) {
                var filterResult = _this._templateArr.filter(function (template) {
                    // Iterate through ANDS, find one where there is no match
                    var noMatch = filter.and.find(function (colVal) {
                        return (template.Data[colVal.column] !== colVal.value);
                    });
                    // If none found, all match, therefore add to filter result
                    return (!noMatch);
                });
                // Add only unique to list
                filterResult.forEach(function (template) {
                    if (!filterSet_1.has(template.Id)) {
                        result.push(template);
                        filterSet_1.add(template.Id);
                    }
                });
            });
        }
        else {
            // No filter means all values
            result = this._templateArr;
        }
        // Order
        if (order && order.length > 0) {
            var _loop_1 = function (i) {
                var orderParam = order[i];
                var sorted = result.sort(function (a, b) {
                    var res = 0;
                    var dataA = a.Data[orderParam.column];
                    var dataB = a.Data[orderParam.column];
                    if (_this.getData) {
                        dataA = _this.getData(orderParam.column, a.Data[orderParam.column]);
                        dataB = _this.getData(orderParam.column, b.Data[orderParam.column]);
                    }
                    if (typeof (dataA) === 'string') {
                        res = dataA.localeCompare(dataB);
                    }
                    else {
                        res = dataA - dataB;
                    }
                    if (orderParam.desc) {
                        res = res * -1;
                    }
                    return res;
                });
                result = sorted;
            };
            for (var i = order.length - 1; i >= 0; --i) {
                _loop_1(i);
            }
        }
        // Save the total filtered count
        this._totalFilteredUnpagedCount = result.length;
        // Paging
        var startPage = page * pageSize;
        result = result.slice(startPage, startPage + pageSize);
        return result;
    };
    TemplateList.prototype.getTemplate = function (id) {
        return this._templateMap.get(id);
    };
    TemplateList.prototype.reconstruct = function (snap) {
        var _this = this;
        this._templateMap = new Map();
        this._templateArr = [];
        snap.forEach(function (doc) {
            var raw = doc.data();
            var template = new Template(_this._user, doc.ref, doc.id, raw);
            _this._templateMap.set(doc.id, template);
            _this._templateArr.push(template);
        });
        // Filter and order
        this._filteredArr = this.getWithQuery(this._filter, this._order);
        if (this._listSubject) {
            this._listSubject.next(this.All);
        }
    };
    /**
     * Subscribe for changes to this list
     * @param observer
     */
    TemplateList.prototype.WatchList = function (observer) {
        return this._listSubject.subscribe(observer);
    };
    /**
     * Make sure you call this function to avoid memory leaks.
     */
    TemplateList.prototype.cleanup = function () {
        if (this._stopListening) {
            this._stopListening();
            this._stopListening = null;
        }
    };
    TemplateList.Init = function (user, watch) {
        return __awaiter(this, void 0, void 0, function () {
            var query, snapshot;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = user.DB.collection(FIREBASE_TEMPLATE_COLLECTION);
                        return [4 /*yield*/, query.get()];
                    case 1:
                        snapshot = _a.sent();
                        return [2 /*return*/, new TemplateList(user, query, snapshot, watch)];
                }
            });
        });
    };
    TemplateList.CountTemplates = function (user, srcLangIso639, destLangIso639) {
        return __awaiter(this, void 0, void 0, function () {
            var query, snapshot;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = user.DB.collection(FIREBASE_TEMPLATE_COLLECTION)
                            .where('srcLanguageIso639', '==', srcLangIso639)
                            .where('destLanguageIso639', '==', destLangIso639);
                        return [4 /*yield*/, query.get()];
                    case 1:
                        snapshot = _a.sent();
                        return [2 /*return*/, snapshot.size];
                }
            });
        });
    };
    return TemplateList;
}());
export { TemplateList };
