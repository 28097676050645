var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { EnumMessageSender } from '../interfaces/IMessaging';
var FIREBASE_MESSAGES_COLLECTION = 'messages';
var MessageBox = /** @class */ (function () {
    function MessageBox(user, query, snapshot, ascending) {
        if (ascending === void 0) { ascending = true; }
        var _this = this;
        this._user = user;
        this._query = query;
        this._ascending = ascending;
        this._messages = [];
        this._messagesSubject = new BehaviorSubject(this._messages);
        this._stopListeningMessages = query.onSnapshot(function (newSnap) {
            _this.processMessages(newSnap);
        });
    }
    MessageBox.prototype.cleanup = function () {
        if (this._stopListeningMessages) {
            this._stopListeningMessages();
            this._stopListeningMessages = null;
        }
    };
    Object.defineProperty(MessageBox.prototype, "Id", {
        get: function () {
            return this._id;
        },
        enumerable: true,
        configurable: true
    });
    MessageBox.prototype.processMessages = function (snapshot) {
        var _this = this;
        this._messages = [];
        snapshot.forEach(function (doc) {
            var msg = doc.data();
            _this._messages.push(msg);
        });
        this._messages = this._messages.sort(function (a, b) {
            if (_this._ascending) {
                return (a.sentAt - b.sentAt);
            }
            else {
                return (b.sentAt - a.sentAt);
            }
        });
        this._messagesSubject.next(this._messages);
    };
    MessageBox.prototype.WatchMessages = function (observer) {
        return this._messagesSubject.subscribe(observer);
    };
    MessageBox.prototype.forPrestation = function (prestationId) {
        var filtered = this._messages.filter(function (msg) { return msg.prestationId === prestationId; });
        var sorted = filtered.sort(function (a, b) {
            return a.sentAt - b.sentAt;
        });
        return sorted;
    };
    MessageBox.prototype.AddMessage = function (prestation, text, attachFile) {
        return __awaiter(this, void 0, void 0, function () {
            var senderType, message;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        senderType = EnumMessageSender.translator;
                        if (prestation.Data.uid === this._user.Id) {
                            senderType = EnumMessageSender.client;
                        }
                        message = {
                            // Id of the user
                            clientId: prestation.Data.uid,
                            // Id of the translator
                            translatorId: prestation.Data.traducteurId,
                            // The id of the message box
                            prestationId: prestation.Id,
                            // The sender
                            sender: senderType,
                            // The sender uid
                            senderUid: this._user.Id,
                            // The text of the message
                            text: text,
                            // The send date
                            sentAt: Date.now(),
                        };
                        if (!!attachFile) {
                            message['file'] = attachFile;
                        }
                        // Add the message
                        return [4 /*yield*/, this._user.DB.collection(FIREBASE_MESSAGES_COLLECTION).add(message)];
                    case 1:
                        // Add the message
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MessageBox.InitForClientAndPrestation = function (user, prestationId) {
        return __awaiter(this, void 0, void 0, function () {
            var query, snapshot;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = user.DB.collection(FIREBASE_MESSAGES_COLLECTION)
                            .where('clientId', '==', user.Id)
                            .where('prestationId', '==', prestationId);
                        return [4 /*yield*/, query.get()];
                    case 1:
                        snapshot = _a.sent();
                        return [2 /*return*/, new MessageBox(user, query, snapshot)];
                }
            });
        });
    };
    MessageBox.InitForClient = function (user) {
        return __awaiter(this, void 0, void 0, function () {
            var query, snapshot;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = user.DB.collection(FIREBASE_MESSAGES_COLLECTION)
                            .where('clientId', '==', user.Id);
                        return [4 /*yield*/, query.get()];
                    case 1:
                        snapshot = _a.sent();
                        return [2 /*return*/, new MessageBox(user, query, snapshot)];
                }
            });
        });
    };
    MessageBox.InitForTranslatorAndPrestation = function (translator, prestation) {
        return __awaiter(this, void 0, void 0, function () {
            var query, snapshot;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = translator.User.DB.collection(FIREBASE_MESSAGES_COLLECTION)
                            .where('translatorId', '==', translator.Id)
                            .where('prestationId', '==', prestation.Id);
                        return [4 /*yield*/, query.get()];
                    case 1:
                        snapshot = _a.sent();
                        return [2 /*return*/, new MessageBox(translator.User, query, snapshot, false)];
                }
            });
        });
    };
    return MessageBox;
}());
export { MessageBox };
