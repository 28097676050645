var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CreatePrestationSchema, EnumPrestationState } from '../interfaces/IPrestation';
import { EnumTraducteurServiceExceptionType } from '../interfaces/ITraducteurService';
import { Price } from '../utility/Price';
import * as UUID from 'uuid';
import { EnumFileTypes } from '../interfaces/IFile';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
var FIREBASE_PRESTATION_COLLECTION = 'prestations';
var FIREBASE_TRADUCTEUR_SERVICE_COLLECTION = 'traducteur_services';
var FIREBASE_TEMPLATE_COLLECTION = 'templates';
var FIREBASE_TRADUCTEUR_COLLECTION = 'traducteurs';
/**
 * Represents a client order.
 * Consult IPrestation.ts for the structure of the data.
 * User the Data getter to get the data regarding the order, but do not modify this reference.
 * All modifications must be made through the proper methods of this class in order assure correct state evolution.
 */
var Prestation = /** @class */ (function () {
    /**
     * Do not invoke. Rather user TraducteurPrestations or ClientPrestations in order to get a list of Prestations.
     * @param user
     * @param isTraducteur
     * @param docRef
     * @param raw
     */
    function Prestation(user, isTraducteur, docRef, id, raw, watch) {
        var _this = this;
        this._user = user;
        this._docRef = docRef;
        this._id = id;
        this._raw = raw;
        this._isTraducteur = isTraducteur;
        this._prestationSubject = new BehaviorSubject(this);
        if (watch) {
            this._stopListening = docRef.onSnapshot(function (snapshot) {
                if (snapshot.exists) {
                    _this._raw = snapshot.data();
                    _this._prestationSubject.next(_this);
                }
            }, function (err) {
            });
        }
    }
    Prestation.prototype.cleanup = function () {
        if (this._stopListening) {
            this._stopListening();
            this._stopListening = null;
        }
    };
    Object.defineProperty(Prestation.prototype, "Id", {
        get: function () {
            return this._id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Prestation.prototype, "Data", {
        /**
         * Get the data for this prestation. If you modify this reference, it will have no impact on the server. Rather call the appropriate function on this class.
         */
        get: function () {
            return this._raw;
        },
        enumerable: true,
        configurable: true
    });
    Prestation.prototype.Watch = function (observer) {
        return this._prestationSubject.subscribe(observer);
    };
    /**
     * (TRANSLATOR) Accept a prestation : call by a translator when he/she wants to accept an order. The client will be notified and asked to pay for the order.
     */
    Prestation.prototype.AcceptPrestation = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._isTraducteur) {
                            throw Error('Action denied');
                        }
                        if (this.Data.state !== EnumPrestationState.WaitingForTranslator) {
                            throw Error('Not in correct state for validation: ' + this.Data.state);
                        }
                        return [4 /*yield*/, this.doUpdate({
                                state: EnumPrestationState.WaitingForPayment,
                                acceptedByTranslatorAt: Date.now()
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * (TRANSLATOR) Refuse a prestation: call by a translator when they refuse an order. The client will be notified and asked to choose another translator.
     */
    Prestation.prototype.RefusePrestation = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._isTraducteur) {
                            throw Error('Action denied');
                        }
                        if (this.Data.state !== EnumPrestationState.WaitingForTranslator) {
                            throw Error('Not in correct state for validation: ' + this.Data.state);
                        }
                        return [4 /*yield*/, this.doUpdate({
                                state: EnumPrestationState.RefusedByTranslator,
                                refusedByTranslatorAt: Date.now()
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * (CLIENT) Cancel a prestation: call by a client if they decide to cancel an order before making a payment
     */
    Prestation.prototype.CancelPrestation = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this._isTraducteur) {
                            throw Error('Action denied');
                        }
                        if (this.Data.state !== EnumPrestationState.WaitingForTranslator) {
                            throw Error('Not in correct state for cancellation: ' + this.Data.state);
                        }
                        return [4 /*yield*/, this.doUpdate({
                                state: EnumPrestationState.CancelledByClient,
                                cancelledByClientAt: Date.now()
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * (CLIENT) Validate a prestation
     */
    Prestation.prototype.ClientValidatePrestation = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this._isTraducteur) {
                            throw Error('Action denied');
                        }
                        if (this.Data.state !== EnumPrestationState.WaitingForValidationFromClient) {
                            throw Error('Not in correct state for validation: ' + this.Data.state);
                        }
                        return [4 /*yield*/, this.doUpdate({
                                state: EnumPrestationState.Validated,
                                validatedByClientAt: Date.now()
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Prestation.prototype.UpdateDocumentTranslation = function (document, translation) {
        return __awaiter(this, void 0, void 0, function () {
            var updater;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._isTraducteur) {
                            throw Error('Action denied');
                        }
                        if (this.Data.state !== EnumPrestationState.Translating && this.Data.state !== EnumPrestationState.WaitingForValidationFromClient) {
                            throw Error('Not in correct state for update: ' + this.Data.state);
                        }
                        updater = this.Data.documents.find(function (doc) { return doc.deviceStorageId === document.deviceStorageId; });
                        if (!updater) return [3 /*break*/, 2];
                        updater.translation = translation;
                        return [4 /*yield*/, this._docRef.update({
                                documents: this.Data.documents
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    Prestation.prototype.UploadTranslatedFile = function (storage, document, filename, file) {
        return __awaiter(this, void 0, void 0, function () {
            var uid, refId, storageRef;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._isTraducteur) {
                            throw Error('Action denied');
                        }
                        if (this.Data.state !== EnumPrestationState.Translating && this.Data.state !== EnumPrestationState.WaitingForValidationFromClient) {
                            throw Error('Not in correct state for updating: ' + this.Data.state);
                        }
                        uid = UUID.v4();
                        refId = this.Data.uid + '/' + this.Data.deviceStorageId + '/' + document.deviceStorageId + '/' + uid;
                        storageRef = storage.ref(refId);
                        return [4 /*yield*/, storageRef.put(file)
                                .then(function (fileSnapshot) {
                                return _this._user.DB.runTransaction(function (t) {
                                    return t.get(_this._docRef)
                                        .then(function (snapshot) {
                                        var locked = snapshot.data();
                                        var docToUpdate = locked.documents.find(function (doc) { return doc.deviceStorageId === document.deviceStorageId; });
                                        if (docToUpdate) {
                                            var fileDef = {
                                                type: EnumFileTypes.PDF,
                                                ext: '.pdf',
                                                name: filename,
                                                deviceStorageId: uid
                                            };
                                            docToUpdate.translated = [fileDef];
                                            console.log(docToUpdate);
                                            // Update the document list
                                            return t.update(_this._docRef, {
                                                documents: locked.documents,
                                                lastModifiedAt: Date.now()
                                            });
                                        }
                                    });
                                });
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Prestation.prototype.DeleteTranslatedFile = function (storage, document, file) {
        return __awaiter(this, void 0, void 0, function () {
            var fileRefId;
            var _this = this;
            return __generator(this, function (_a) {
                if (!this._isTraducteur) {
                    throw Error('Action denied');
                }
                if (this.Data.state !== EnumPrestationState.Translating) {
                    throw Error('Not in correct state for validation: ' + this.Data.state);
                }
                fileRefId = this.Data.uid + '/' + this.Data.deviceStorageId + '/' + document.deviceStorageId + '/' + file.deviceStorageId;
                return [2 /*return*/, this._user.DB.runTransaction(function (t) {
                        return t.get(_this._docRef)
                            .then(function (snapshot) {
                            var locked = snapshot.data();
                            var docToUpdate = locked.documents.find(function (doc) { return doc.deviceStorageId === document.deviceStorageId; });
                            if (docToUpdate) {
                                // Recreate the list without the passed file
                                docToUpdate.translated = docToUpdate.translated.filter(function (f) {
                                    return (f.deviceStorageId !== file.deviceStorageId);
                                });
                                // Update the document list
                                return t.update(_this._docRef, {
                                    documents: locked.documents,
                                    lastModifiedAt: Date.now()
                                });
                            }
                            else {
                                return Promise.resolve(null);
                            }
                        });
                    })
                        .then(function () {
                        var fileRef = storage.ref(fileRefId);
                        return fileRef.delete()
                            .catch(function (err) {
                            console.warn('Error removing file: ' + fileRefId);
                        });
                    })];
            });
        });
    };
    Prestation.prototype.Validate = function () {
        return __awaiter(this, void 0, void 0, function () {
            var valid;
            return __generator(this, function (_a) {
                if (!this._isTraducteur) {
                    throw Error('Action denied');
                }
                if (this.Data.state !== EnumPrestationState.Translating) {
                    throw Error('Not in correct state for validation: ' + this.Data.state);
                }
                valid = true;
                this.Data.documents.forEach(function (doc) {
                    if (!doc.translated || doc.translated.length === 0) {
                        valid = false;
                    }
                });
                if (!valid) {
                    throw Error('You have not translated all the documents! Cannot validate.');
                }
                this.doUpdate({
                    state: EnumPrestationState.WaitingForValidationFromClient,
                    completedAt: Date.now()
                });
                return [2 /*return*/];
            });
        });
    };
    Prestation.prototype.doUpdate = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        data.lastModifiedAt = Date.now();
                        return [4 /*yield*/, this._user.DB.runTransaction(function (t) {
                                return t.get(_this._docRef)
                                    .then(function (snapshot) {
                                    // Update the document list
                                    return t.update(_this._docRef, data);
                                });
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Create a new prestation. In general this is called by the Hiero app and you will not need to call this function on the web.
     * @param user
     * @param prestation
     */
    Prestation.Create = function (user, prestation) {
        return __awaiter(this, void 0, void 0, function () {
            var date, validated, docRef, snapshot, raw;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // Set the user id for this prestation
                        prestation.uid = user.Id;
                        date = Date.now();
                        prestation.createdAt = date;
                        prestation.lastModifiedAt = date;
                        prestation.sentToTranslatorAt = date;
                        // Set the state
                        prestation.state = EnumPrestationState.WaitingForTranslator;
                        return [4 /*yield*/, CreatePrestationSchema.validate(prestation, {
                                strict: true,
                                stripUnknown: true,
                                recursive: true
                            })];
                    case 1:
                        validated = _a.sent();
                        return [4 /*yield*/, user.DB.collection(FIREBASE_PRESTATION_COLLECTION).add(validated)];
                    case 2:
                        docRef = _a.sent();
                        return [4 /*yield*/, docRef.get()];
                    case 3:
                        snapshot = _a.sent();
                        raw = snapshot.data();
                        return [2 /*return*/, new Prestation(user, false, docRef, snapshot.id, raw)];
                }
            });
        });
    };
    Prestation.Load = function (user, isTraducteur, prestationId, watch) {
        return __awaiter(this, void 0, void 0, function () {
            var doc, snapshot;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        doc = user.DB.collection(FIREBASE_PRESTATION_COLLECTION).doc(prestationId);
                        return [4 /*yield*/, doc.get()];
                    case 1:
                        snapshot = _a.sent();
                        if (!snapshot.exists) {
                            return [2 /*return*/, Promise.reject('No prestation found with that id')];
                        }
                        else {
                            return [2 /*return*/, new Prestation(user, isTraducteur, doc, prestationId, snapshot.data(), watch)];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Find the euclidean distance between two coordinates, modified to work in geodesic space
     * @param coord0
     * @param coord1
     */
    Prestation.GeoDistance = function (coord0, coord1) {
        var degLen = 110.25;
        var x = coord0.latitude - coord1.latitude;
        var y = (coord0.longitude - coord1.longitude) * Math.cos(coord1.latitude);
        return degLen * Math.sqrt(x * x + y * y);
    };
    /**
     * Helper function for the mobile app, that searched for translators that match the order.
     * @param user The client requesting the list
     * @param prestation The details of the order
     * @param coords The coordinates of the user.
     * @param settings The global settings for Hiero.
     */
    Prestation.FindTraducteurs = function (user, prestation, coords, settings) {
        return __awaiter(this, void 0, void 0, function () {
            var docTypeSet, templateQuery, templateMap, templateDocMap, templateSnapshot, query, snapshot, translatorMap, promises, finalResults, sortedResults, repackagedResults;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        docTypeSet = new Set();
                        prestation.documents.forEach(function (doc) {
                            if (!docTypeSet.has(doc.documentTypeId)) {
                                docTypeSet.add(doc.documentTypeId);
                            }
                        });
                        templateQuery = user.DB.collection(FIREBASE_TEMPLATE_COLLECTION)
                            .where('srcLanguageIso639', '==', prestation.srcLanguageIso639)
                            .where('destLanguageIso639', '==', prestation.destLanguageIso639)
                            .where('srcCountryCode', '==', prestation.srcCountryCode);
                        templateMap = new Map();
                        templateDocMap = new Map();
                        return [4 /*yield*/, templateQuery.get()];
                    case 1:
                        templateSnapshot = _a.sent();
                        templateSnapshot.docs.forEach(function (tempSnap) {
                            var template = tempSnap.data();
                            if (docTypeSet.has(template.documentTypeId)) {
                                // Key by template Id
                                templateMap.set(tempSnap.id, template);
                                // Key by documentType
                                templateDocMap.set(template.documentTypeId, tempSnap.id);
                            }
                        });
                        // If the number of templates is not the same as the number of unique document
                        // types, quit early... cannot fill the order
                        if (templateDocMap.size !== docTypeSet.size) {
                            console.log('Not enough templates to match all document types for this order');
                            return [2 /*return*/, []];
                        }
                        query = user.DB.collection(FIREBASE_TRADUCTEUR_SERVICE_COLLECTION)
                            .where('srcLanguageIso639', '==', prestation.srcLanguageIso639)
                            .where('destLanguageIso639', '==', prestation.destLanguageIso639);
                        return [4 /*yield*/, query.get()];
                    case 2:
                        snapshot = _a.sent();
                        translatorMap = new Map();
                        snapshot.docs.forEach(function (docSnapshot) {
                            var service = docSnapshot.data();
                            console.log('SERVICE MATCH: ' + docSnapshot.id);
                            // Go through all service types, and make sure we have all document types
                            /*let matches = 0;
                            service.types.forEach(
                              (servDocType: ITraducteurServiceDocumentType) => {
                                if (docTypeSet.has(servDocType.documentTypeId)) {
                                  matches += 1;
                                }
                              }
                            );*/
                            // Go through exceptions to make sure that this service matches
                            var hasException = false;
                            if (service.exceptions) {
                                var exception = service.exceptions.find(function (exc) {
                                    return templateMap.has(exc.templateId) && exc.type === EnumTraducteurServiceExceptionType.DO_NOT_HANDLE;
                                });
                                hasException = !!exception;
                            }
                            if (!hasException) {
                                console.log('DOCUMENTS MATCH PERFECTLY');
                                // Perfect match
                                var sortResult = {
                                    distance: -1,
                                    translatorId: service.traducteurId,
                                    services: [],
                                    translator: null,
                                };
                                if (translatorMap.has(service.traducteurId)) {
                                    // Already have, get
                                    sortResult = translatorMap.get(service.traducteurId);
                                }
                                else {
                                    // Add
                                    translatorMap.set(service.traducteurId, sortResult);
                                }
                                sortResult.services.push(service);
                            }
                        });
                        promises = [];
                        finalResults = [];
                        translatorMap.forEach(function (service, translatorId) {
                            var doc = user.DB.collection(FIREBASE_TRADUCTEUR_COLLECTION).doc(translatorId);
                            promises.push(doc.get()
                                .then(function (translatorSnapshot) {
                                if (translatorSnapshot.exists) {
                                    service.translator = translatorSnapshot.data();
                                    service.distance = _this.GeoDistance(service.translator.coords, coords);
                                    console.log('Found translator at: ' + service.distance);
                                    finalResults.push(service);
                                }
                            })
                                .catch(function (err) {
                                console.warn(err.message);
                            }));
                        });
                        // Wait for all translators to result
                        return [4 /*yield*/, Promise.all(promises)];
                    case 3:
                        // Wait for all translators to result
                        _a.sent();
                        sortedResults = finalResults.sort(function (a, b) {
                            return (a.distance - b.distance);
                        });
                        repackagedResults = [];
                        sortedResults.forEach(function (sortResult) {
                            var overallPrice = new Price(0, settings.Current.tva, settings.Current.margin);
                            var res = {
                                distance: sortResult.distance,
                                traducteurId: sortResult.translatorId,
                                traducteur: sortResult.translator,
                                documents: [],
                                price: overallPrice.breakdown
                            };
                            // Go over each document in the prestation and find its price
                            prestation.documents.forEach(function (doc) {
                                // Find template for doc
                                var templateId = templateDocMap.get(doc.documentTypeId);
                                var template = templateMap.get(templateId);
                                var finalPrice = template.priceHT;
                                // Choose first service, because if arrive here, should all be same
                                var service = sortResult.services[0];
                                if (service.exceptions) {
                                    var exception = service.exceptions.find(function (exc) {
                                        // This template has the same docId as the one under consideration
                                        return (exc.templateId === templateId && exc.type === EnumTraducteurServiceExceptionType.DIFFERENT_PRICE);
                                    });
                                    if (exception) {
                                        finalPrice = exception.priceHT;
                                    }
                                }
                                var price = new Price(finalPrice, settings.Current.tva, settings.Current.margin);
                                // Item price
                                res.documents.push({
                                    price: price.breakdown,
                                    documentTypeId: doc.documentTypeId
                                });
                                // Total price
                                overallPrice.add(finalPrice);
                            });
                            res.price = overallPrice.breakdown;
                            console.log(res);
                            repackagedResults.push(res);
                        });
                        return [2 /*return*/, repackagedResults];
                }
            });
        });
    };
    return Prestation;
}());
export { Prestation };
