import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { PartialObserver, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subscription } from 'rxjs/Subscription';
import { Traducteur } from '../../../common/src/bdd/traducteur/Traducteur';
import { IPrestationNotification, TraducteurPrestations } from '../../../common/src/bdd/traducteur/TraducteurPrestations';
import { AuthService } from '../../../common/src/bdd/user/AuthService';
import { ERoleType, User } from '../../../common/src/bdd/user/User';
import { Config } from '../../../common/src/services/config.service';
import { GoogleGeo } from './google.services';
import { Prestation } from '../../../common/src/bdd/prestation/Prestation';
import { MessageNotifications } from '../../../common/src/bdd/messaging/MessageNotifications';
import { IMessageNotification } from '../../../common/src/bdd/interfaces/IMessaging';
import { ITraducteur } from '../../../common/src/bdd/interfaces/ITraducteur';


export interface IPrestationMessageEvent {
  prestationId: string;
  notifier: MessageNotifications;
}

@Injectable()
export class HieroBDD {

  private _db: firebase.firestore.Firestore;
  private _auth: firebase.auth.Auth;
  private _authService: AuthService;
  private _storage: firebase.storage.Storage;

  // App specific
  private _traducteurSubject: BehaviorSubject<Traducteur>;
  private _traducteurPresentSubject: Subject<Traducteur>;
  private _traducteurProfileSubject: BehaviorSubject<ITraducteur>;

  private _traducteurPrestationsSubject: BehaviorSubject<TraducteurPrestations>;
  private _prestationEventSubject: Subject<IPrestationNotification>;
  private _prestationMessageNotifiersSubject: Subject<Map<string, MessageNotifications|null>>;

  private _prestationEventSubscription: Subscription;
  private _prestationListSubscription: Subscription;

  private _notifierMap: Map<string, MessageNotifications|null>;
  private _notifierSubscriptionMap: Map<string, Subscription|null>;


  constructor(
    private config: Config,
    private google: GoogleGeo
  ) {
    // Initialize Firebase
    firebase.initializeApp(this.config.Environment.firebase);

    // Create the database object
    this._db = firebase.firestore();
    this._auth = firebase.auth();
    this._storage = firebase.storage();

    this._traducteurSubject = new BehaviorSubject(null);
    this._traducteurPresentSubject = new Subject<Traducteur>();
    this._traducteurProfileSubject = new BehaviorSubject(null);

    this._traducteurPrestationsSubject = new BehaviorSubject<TraducteurPrestations>(null);
    this._prestationEventSubject = new Subject<IPrestationNotification>();

    this._notifierMap = new Map<string, MessageNotifications|null>();
    this._notifierSubscriptionMap = new Map<string, Subscription|null>();
    this._prestationMessageNotifiersSubject = new BehaviorSubject<Map<string, MessageNotifications|null>>(this._notifierMap);

    // Setup the service provider
    this._authService = new AuthService(this._auth, this._db, ERoleType.traducteur);

    // Setup extra login steps:
    // 1/ Login automatically as a translator
    this._authService.extraLoginSteps.push(
      async (user: User) => {
        try {

          // const idtoken = await user.GetIDToken();
          // console.log(idtoken);

          const traducteur: Traducteur = await Traducteur.Init(user);
          this._traducteurSubject.next(traducteur);
          this._traducteurPresentSubject.next(traducteur);

          // GLOBAL SUBSCRIBE TO PROFILE
          traducteur.WatchProfile({
            next: (profile: ITraducteur) => {
              this._traducteurProfileSubject.next(profile);
            }
          });

          // GLOBAL SUBSCRIBE TO PRESTATIONS 
          TraducteurPrestations.Init(traducteur, true, [], [])
          .then(
            (prestations: TraducteurPrestations) => {
              this._traducteurPrestationsSubject.next(prestations);

              // Subscribe to the filtered prestation list (that is shown)
              // This will create subscriptions on each shown prestation for the message queues that may exist
              // on each prestation
              this._prestationListSubscription = prestations.WatchList({
                next: (newPrestations: Prestation[]) => {
                  this.handleNotifierUpdates(newPrestations);
                }
              });

              // Unsubscribe the old one to prevent memory leaks
              if (this._prestationEventSubscription) {
                this._prestationEventSubscription.unsubscribe();
              }

              // Subscribe this one
              this._prestationEventSubscription = prestations.WatchEvents({
                next: (event: IPrestationNotification) => {
                  this._prestationEventSubject.next(event);
                }
              });
            }
          );

        } catch (err) {
          this._traducteurSubject.next(null);
          this._traducteurPresentSubject.next(null);
          throw err;
        }
      }
    );

    // Set up a watch on user, if user becomes null, so does traducteur
    this._authService.WatchUser({
      next: (user) => {
        if (user == null) {
          // User logs out
          this.handleNotifierUpdates([]);

          const prestationList =  this._traducteurPrestationsSubject.value;
          if (prestationList) {
            prestationList.cleanup();
          }
          this._traducteurPrestationsSubject.next(null);

          // Unsubscribe listener for prestation list
          if (this._prestationListSubscription) {
            this._prestationListSubscription.unsubscribe();
            this._prestationListSubscription = null;
          }

          // Unsubscribe the old one to prevent memory leaks
          if (this._prestationEventSubscription) {
            this._prestationEventSubscription.unsubscribe();
          }

          this._traducteurSubject.next(null);
          this._traducteurPresentSubject.next(null);
        }
      }
    });



    // Start listener
    this.Auth.Listen();
  }

  get DB(): firebase.firestore.Firestore {
    return this._db;
  }

  get Auth(): AuthService {
    return this._authService;
  }

  get Storage(): firebase.storage.Storage {
    return this._storage;
  }

  public WatchTraducteur(observer: PartialObserver<Traducteur>): Subscription {
    return this._traducteurSubject.subscribe(observer);
  }

  public WatchTraducteurPresent(observer: PartialObserver<Traducteur>): Subscription {
    return this._traducteurPresentSubject.subscribe(observer);
  }

  public get Traducteur(): Traducteur|null {
    return this._traducteurSubject.value;
  }

  public WatchTraducteurProfile(observer: PartialObserver<ITraducteur>): Subscription {
    return this._traducteurProfileSubject.subscribe(observer);
  }


  public WatchTraducteurPrestations(observer: PartialObserver<TraducteurPrestations>): Subscription {
    return this._traducteurPrestationsSubject.subscribe(observer);
  }

  public WatchPrestationEvent(observer: PartialObserver<IPrestationNotification>): Subscription {
    return this._prestationEventSubject.subscribe(observer);
  }


  /////////// MESSAGE NOTIFICATIONS

  private handleNotifierUpdates(prestations: Prestation[]) {

    if (!this._traducteurSubject.value) {
      return;
    }

    const notSet: Set<string> = new Set<string>();
    prestations.forEach(
      (prestation) => {
        notSet.add(prestation.Id);

        if (!this._notifierMap.has(prestation.Id)) {
          // Set to null, to mark the place
          this._notifierMap.set(prestation.Id, null);
          this._notifierSubscriptionMap.set(prestation.Id, null);

          MessageNotifications.InitForTranslatorPrestation(this._traducteurSubject.value, prestation.Id)
          .then(
            (not: MessageNotifications) => {
              this._notifierMap.set(prestation.Id, not);
              this._notifierSubscriptionMap.set(prestation.Id,
                not.WatchNotifications({
                  next: (newNotification: IMessageNotification) => {
                    this._prestationMessageNotifiersSubject.next(this._notifierMap);
                  }
                })
              );
            }
          );
        }
      }
    );

    const toRemove: string[] = [];
    this._notifierMap.forEach(
      (msgNot, key) => {
        if (!notSet.has(key)) {
          // Signal for removal
          toRemove.push(key);
        }
      }
    );

    toRemove.forEach(
      (id: string) => {
        const msgNot = this._notifierMap.get(id);
        if (msgNot) {
          msgNot.cleanup();
        }

        const sub = this._notifierSubscriptionMap.get(id);
        if (sub) {
          sub.unsubscribe();
        }
        this._notifierMap.delete(id);
        this._notifierSubscriptionMap.delete(id);
      }
    );

  }

  public async SetSeen(prestationId: string) {
    const not: MessageNotifications = this._notifierMap.get(prestationId);
    if (not) {
      try {
        await not.SetSeen();
      } catch (err) {
        console.log(err);
      }
    }
  }

  public GetNotifierFor(prestationId: string) {
    return this._notifierMap.get(prestationId);
  }

  public WatchPrestationMessageNotifiers(observer: PartialObserver<Map<string, MessageNotifications>>): Subscription {
    return this._prestationMessageNotifiersSubject.subscribe(observer);
  }

}
