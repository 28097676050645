/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./prestationEditor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../../common/src/components/pdfeditor/pdfeditor.component.ngfactory";
import * as i4 from "../../../../../../../common/src/components/pdfeditor/pdfeditor.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../../../../../common/src/modules/localisation/localise.pipe";
import * as i7 from "../../../../../../../common/src/modules/localisation/localisation.service";
import * as i8 from "../../../../../../../common/src/utility/app-wait/app-wait.component.ngfactory";
import * as i9 from "../../../../../../../common/src/utility/app-wait/app-wait.component";
import * as i10 from "./prestationEditor.component";
import * as i11 from "../../../../../services/hierobdd.service";
import * as i12 from "@ng-bootstrap/ng-bootstrap";
var styles_PrestationEditorComponent = [i0.styles];
var RenderType_PrestationEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PrestationEditorComponent, data: {} });
export { RenderType_PrestationEditorComponent as RenderType_PrestationEditorComponent };
function View_PrestationEditorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-file-image-o"]], null, null, null, null, null))], null, null); }
function View_PrestationEditorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-file-pdf-o"]], null, null, null, null, null))], null, null); }
function View_PrestationEditorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "btn btn-outline-info btn-sm prestation-file-button mr-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openFile(_co.prestation.Data, _co.document, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PrestationEditorComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PrestationEditorComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.type === 1); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type === 0); _ck(_v, 4, 0, currVal_1); }, null); }
function View_PrestationEditorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "alert alert-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" No template could be found for this document! Please contact "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["href", "mailto:contact@hiero-support.com"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["support."]))], null, null); }
function View_PrestationEditorComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pdf-editor", [], null, [[null, "update"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("update" === en)) {
        var pd_0 = (_co.onUpdate($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_PDFEditorComponent_0, i3.RenderType_PDFEditorComponent)), i1.ɵdid(1, 4767744, null, 0, i4.PDFEditorComponent, [i1.ChangeDetectorRef, i1.NgZone], { objectEntry: [0, "objectEntry"] }, { update: "update" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pdfRender; _ck(_v, 1, 0, currVal_0); }, null); }
function View_PrestationEditorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 31, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "card mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "card-body d-flex flex-row align-items-center justify-content-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "button", [["class", "btn btn-info"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i5.RouterLink, [i5.Router, i5.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 3), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-arrow-circle-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h5", [["class", "mb-0 ml-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["class", "btn btn-success float-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.uploadFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵppd(11, 1), (_l()(), i1.ɵeld(12, 0, null, null, 19, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 7, "div", [["class", "row mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 6, "div", [["class", "col d-flex flex-row justify-content-center align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, "p", [["class", "mb-0 mr-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, ["", ""])), i1.ɵppd(18, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PrestationEditorComponent_2)), i1.ɵdid(20, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(21, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 4, "div", [["class", "col-6 offset-3"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PrestationEditorComponent_5)), i1.ɵdid(24, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PrestationEditorComponent_6)), i1.ɵdid(26, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(27, 0, null, null, 4, "div", [["class", "row mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 3, "div", [["class", "col-6 offset-3 d-flex align-items-center justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 2, "button", [["class", "btn btn-warning"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resetTemplate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(30, null, ["", ""])), i1.ɵppd(31, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 5, 0, "/app", "prestation", _co.prestation.Id); _ck(_v, 4, 0, currVal_0); var currVal_4 = _co.document.original; _ck(_v, 20, 0, currVal_4); var currVal_5 = _co.errorLoadingTemplate; _ck(_v, 24, 0, currVal_5); var currVal_6 = !!_co.pdfData; _ck(_v, 26, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getDocumentName(_co.document.documentTypeId); _ck(_v, 8, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i1.ɵnov(_v.parent, 0), "prestation_document_validate")); _ck(_v, 10, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 17, 0, _ck(_v, 18, 0, i1.ɵnov(_v.parent, 0), "prestation_document_show_original")); _ck(_v, 17, 0, currVal_3); var currVal_7 = i1.ɵunv(_v, 30, 0, _ck(_v, 31, 0, i1.ɵnov(_v.parent, 0), "prestation_document_button_reset")); _ck(_v, 30, 0, currVal_7); }); }
export function View_PrestationEditorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.LocalisePipe, [i7.LocalisationService]), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PrestationEditorComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-wait", [], null, null, null, i8.View_AppWaitComponent_0, i8.RenderType_AppWaitComponent)), i1.ɵdid(6, 573440, null, 0, i9.AppWaitComponent, [], { show: [0, "show"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !!_co.prestation; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.busy; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_PrestationEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_PrestationEditorComponent_0, RenderType_PrestationEditorComponent)), i1.ɵdid(1, 4374528, null, 0, i10.PrestationEditorComponent, [i11.HieroBDD, i5.ActivatedRoute, i5.Router, i1.ChangeDetectorRef, i7.LocalisationService, i12.NgbModal], null, null)], null, null); }
var PrestationEditorComponentNgFactory = i1.ɵccf("ng-component", i10.PrestationEditorComponent, View_PrestationEditorComponent_Host_0, {}, {}, []);
export { PrestationEditorComponentNgFactory as PrestationEditorComponentNgFactory };
