/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/src/modules/localisation/localise.pipe";
import * as i2 from "../../../../common/src/modules/localisation/localisation.service";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "./not_found.component";
var styles_NotFoundComponent = ["./not_found.component.scss"];
var RenderType_NotFoundComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
export function View_NotFoundComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.LocalisePipe, [i2.LocalisationService]), (_l()(), i0.ɵeld(1, 0, null, null, 18, "div", [["class", "error"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "error-code m-b-10"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["404"])), (_l()(), i0.ɵeld(4, 0, null, null, 15, "div", [["class", "error-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "error-message"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵppd(7, 1), (_l()(), i0.ɵeld(8, 0, null, null, 5, "div", [["class", "error-desc m-b-30"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, [" ", ""])), i0.ɵppd(10, 1), (_l()(), i0.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(13, 1), (_l()(), i0.ɵeld(14, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 4, "a", [["class", "btn btn-success p-l-20 p-r-20"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(16, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(17, 1), (_l()(), i0.ɵted(18, null, ["", ""])), i0.ɵppd(19, 1)], function (_ck, _v) { var currVal_5 = _ck(_v, 17, 0, "/app"); _ck(_v, 16, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i0.ɵnov(_v, 0), "not_found_title")); _ck(_v, 6, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i0.ɵnov(_v, 0), "not_found_subtitle")); _ck(_v, 9, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i0.ɵnov(_v, 0), "not_found_action")); _ck(_v, 12, 0, currVal_2); var currVal_3 = i0.ɵnov(_v, 16).target; var currVal_4 = i0.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_3, currVal_4); var currVal_6 = i0.ɵunv(_v, 18, 0, _ck(_v, 19, 0, i0.ɵnov(_v, 0), "not_found_home")); _ck(_v, 18, 0, currVal_6); }); }
export function View_NotFoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i0.ɵdid(1, 49152, null, 0, i5.NotFoundComponent, [], null, null)], null, null); }
var NotFoundComponentNgFactory = i0.ɵccf("ng-component", i5.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };
