module.exports = {
	fr: {
		login_caption_title: "Hierø - Interface traducteur",
		login_caption_subtitle: "Un logiciel pensé par des traducteurs pour des traducteurs.",
		login_main_logo: "Hierø",
		login_main_title: "Interface traducteur",
		login_main_subtitle: "Accédez à vos missions ainsi qu'à l'éditeur de document grâce à l'interface traducteur Hiero !",
		login_main_connect: "Se connecter",
		login_main_email: "E-mail",
		login_main_email_placeholder: "Renseignez votre adresse mail",
		login_main_email_help: "Veuillez vous identifier avec votre adresse mail.",
		login_main_password: "Mot de passe",
		login_main_password_placeholder: "Indiquez votre mot de passe",
		login_main_password_help: "",
		login_main_button: "Se connecter",
		login_main_to_signup: "Pas encore inscrit ? Inscrivez-vous !",
		login_main_copyright: "© Hierø - By'Me Univers 2019",
		login_error_required_field: "Champ obligatoire",
		login_error_invalid_email: "L'adresse saisie n'est pas valide.",
		login_error_email_not_found: "L'adresse mail n'existe pas.",
		signup_caption_title: "Hierø - Interface traducteur",
		signup_caption_subtitle: "Choisissez Hierø et gagnez en visibilité !",
		signup_main_logo: "Hierø",
		signup_main_title: "Inscription",
		signup_main_subtitle: "Créez votre compte, rejoignez notre interface et devenez visible auprès de nos nombreux utilisateurs !",
		signup_main_email: "Adresse mail",
		signup_main_email_placeholder: "",
		signup_main_email_help: "",
		signup_main_password: "Mot de passe",
		signup_main_password_placeholder: "",
		signup_main_password_help: "",
		signup_main_confirm: "Confirmer votre mot de passe",
		signup_main_confirm_placeholder: "",
		signup_main_confirm_help: "",
		signup_main_surname: "Nom",
		signup_main_surname_placeholder: "",
		signup_main_surname_help: "",
		signup_main_name: "Prénom",
		signup_main_name_placeholder: "",
		signup_main_name_help: "",
		signup_main_telephone: "Téléphone",
		signup_main_telephone_placeholder: "",
		signup_main_telephone_help: "",
		signup_main_button: "S'inscrire",
		signup_main_to_signup: "Déjà membre ? Se connecter",
		signup_main_copyright: "© Hierø - By'Me Univers 2019",
		signup_error_required_field: "Champ obligatoire",
		signup_error_invalid_email: "L'adresse saisie n'est pas valide.",
		signup_error_email_not_found: "L'adresse mail n'existe pas.",
		signup_error_min_password: "Minimum 8 caractères",
		signup_error_max_password: "Maximum 30 caractères",
		signup_error_passwords_do_not_match: "Les mots de passe ne correspondent pas",

		login_forgot_password: "Mot de passe oublié ?",
		reset_password_main: "Réinitialiser votre mot de passe",
		reset_password_email_title: "Email",
		reset_password_email_placeholder: "",
		reset_password_email_help: "",
		reset_password_send: "Envoyer",
		reset_password_login: "Connexion",
		reset_password_success: "Un email a été envoyé à votre adresse. Cliquez ensuite sur le lien pour modifier votre mot de passe.",
		reset_password_error_required: "Champ obligatoire",
		reset_password_error_invalid_email: "L'adresse saisie n'est pas valide.",
		reset_password_error_user_not_found: "L'adresse mail n'existe pas.",

		main_header_title: "Hierø -",
		main_header_subtitle: "Interface traducteur",
		main_header_user_menu_logout: "Se déconnecter",
		main_left_menu_title: "Traducteur assermenté",
		main_left_menu_section_navigation: "Menu de navigation",
		main_left_menu_section_navigation_missions: "Mes missions",
		main_left_menu_section_parameters: "Paramètres",
		main_left_menu_section_parameters_agency: "Mon agence",
		main_left_menu_section_parameters_services: "Mes services",
		main_left_menu_section_parameters_user: "Mon compte",
		main_left_menu_section_parameters_support: "Support",
		agency_setup_title: "À propos de votre agence",
		agency_setup_explanation: "Hierø propose vos services aux clients proches de vos locaux.<br>Merci de nous préciser le nom de votre entreprise ainsi que votre adresse postale. <br>Ces informations seront proposées aux clients lorsqu'ils cherchent un traducteur.",
		agency_setup_companyname: "Raison sociale",
		agency_setup_companyname_placeholder: "",
		agency_setup_companyname_help: "Le nom de l'entreprise qui sera proposé aux clients.",
		agency_setup_address: "Adresse",
		agency_setup_address_placeholder: "",
		agency_setup_address_help: "Commencez à taper votre adresse, et sélectionnez la bonne adresse dans le menu déroulant.",
		agency_setup_addresscompl: "Complément d'adresse",
		agency_setup_addresscompl_placeholder: "",
		agency_setup_addresscompl_help: "Bâtiment, escalier, digicode, etc. Lorsque vos clients viennent chercher leurs documents, ils doivent avoir toutes les informations nécessaires.",
		agency_setup_tel: "Télephone",
		agency_setup_tel_placeholder: "",
		agency_setup_tel_help: "Le numéro de téléphone à utiliser pour toute correspondance avec vos clients.",
		agency_setup_email: "Adresse mail",
		agency_setup_email_placeholder: "",
		agency_setup_email_help: "L'adresse mail à utiliser pour toute correspondance avec vos clients. Veuillez en indiquer une autre s'il ne s'agit pas de votre adresse d'inscription sur Hierø.",
		agency_setup_save: "Enregistrer",
		agency_setup_error_required_field: "Champ obligatoire",
		agency_setup_error_invalid_email: "L'adresse saisie n'est pas valide.",
		agency_setup_error_valid_address: "Veuillez sélectionner une des adresses proposées dans le menu déroulant.",

		agency_address_not_found: "Votre adresse est introuvable ?",
		agency_address_title: "Saisir manuellement vos coordonnées",
		agency_address_address: "Adresse :",
		agency_address_number: "N°",
		agency_address_street: "Rue",
		agency_address_code: "Code postal",
		agency_address_city: "Ville",
		agency_address_country: "Pays",
		agency_address_coords: "Coordonnées GPS :",
		agency_address_coords_lat: "Latitude",
		agency_address_coords_long: "Longitude",
		agency_address_cancel: "Annuler",
		agency_address_validate: "Valider",
		

		section_agency_title: "Mon agence",
		section_services_title: "Mes services",
		section_account_title: "Mon compte",
		section_support_title: "Support",
		section_tasks_title: "Mes missions",
		section_task_title: "Effectuer une mission",
		section_document_title: "Traduire un document",
		notifications_new_task: "Vous avez une nouvelle mission!",
		notifications_task_confirmed: "La mission a été confirmée. Vous pouvez débuter la traduction.",
		notifications_task_validated: "La mission vient d'être validée par le client",
		tasks_filters: "Filtres",
		tasks_sent_on: "Envoyé le",
		tasks_src_lang: "Langue d'origine",
		tasks_src_country: "Pays d'origine",
		tasks_dst_country: "Traduire vers",
		tasks_state: "Progression",
		tasks_price: "Prix (HT)",
		state_cancelled: "Annulé par le client",
		state_waiting_for_translator: "Nouveau",
		state_waiting_for_payment: "En attente de paiement",
		state_refused_by_translator: "Mission refusée",
		state_translating: "En cours de traduction",
		state_waiting_for_client_validation: "Finie. En attente de validation par le client.",
		state_validated: "Validée par le client",
		prestation_breadcrumb_tasks: "Mes missions",
		prestation_order_number: "N° de commande :",
		prestation_next_action_title: "Prochaines actions",
		prestation_next_action_accept_order: "Accepter la commande",
		prestation_next_action_accept_order_expl: "Cliquez ici pour accepter la commande. Le client procèdera ensuite au paiement. Une fois le paiement effectué, vous pourrez commencer la traduction.",
		prestation_next_action_reject_order: "Refuser la commande",
		prestation_next_action_reject_order_expl: "Cliquez ici pour refuser la commande.",
		prestation_next_action_rejected: "Vous avez refusé cette commande.",
		prestation_next_action_waiting_for_payment: "En attente du paiement par le client.",
		prestation_next_action_translate_expl: "Veuillez procéder à la traduction de chaque document de cette commande en cliquant sur le bouton 'Traduire'. Une fois que tous les documents sont prêts, cliquez sur le bouton 'Envoyer au client'. Pour toute question ou précision, vous pouvez directement échanger avec le client via le messagerie.",
		prestation_next_action_translate_all_done_expl: "Vous avez terminé tous les documents de cette commande.",
		prestation_next_action_translate_confirm: "Envoyer au client pour validation",
		prestation_next_action_waiting_for_validation: "Le client est en train de valider la traduction.",
		prestation_next_action_validated: "Commande validée par le client.",
		prestation_order_details: "Détails de la commande :",
		prestation_order_from: "De",
		prestation_order_to: "à",
		prestation_order_country_origin: "Pays d'origine :",
		prestation_order_document_count: "Documents à traduire :",
		prestation_order_price: "Prix (HT) :",
		prestation_order_date: "Commande créée le :",
		prestation_order_documents_title: "Documents à traiter :",
		prestation_order_document_type: "Type de document",
		prestation_order_document_originals: "Originaux",
		prestation_order_document_translated: "Traduit(s)",
		prestation_order_document_not_yet_translated: "Pas encore de traduction",
		prestation_order_document_translate: "Traduire !",
		prestation_order_messaging_title: "Messagerie",
		prestation_order_messaging_send: "Envoyer",
		prestation_order_messaging_client: "Client :",
		prestation_order_messaging_me: "Moi :",
		prestation_order_confirm_ok: "Ok",
		prestation_order_confirm_cancel: "Annuler",
		prestation_order_validate_title: "Confirmer",
		prestation_order_validate_msg: "Etes-vous sûr de vouloir valider cette commande ? Le client recevra une notification et aura désormais accès aux documents traduits.",
		prestation_order_accept_title: "Confirmer",
		prestation_order_accept_msg: "Etes-vous sûr de vouloir accepter cette commande ?",
		prestation_order_refuse_title: "Confirmer",
		prestation_order_refuse_msg: "Etes-vous sûr de vouloir refuser cette commande ?",
		prestation_document_validate: "Valider la traduction !",
		prestation_document_show_original: "Visionner l'original dans un autre onglet :",
		prestation_document_edit: "Éditeur de document",
		prestation_document_edit_expl: "Le texte dans le champ ci-dessus est souligné dans le document ci-dessous. En saisissant le text traduit dans le champ, le document sera automatiquement mis à jour. Utilisez les flèches pour à travers le document.",
		prestation_document_get_browser: "Vous ne voyez pas le PDF ? Veuillez utiliser un navigateur récent comme",
		prestation_document_button_reset: "Réinitialiser le document",
		prestation_document_confirm_title: "Confirmer",
		prestation_document_confirm_message: "Êtes-vous sûr de vouloir réinitialiser la traduction ?  Toutes vos modifications seront perdues !",
		prestation_document_confirm_oui: "Oui",
		prestation_document_confirm_non: "Non",
		account_expl: "Veuillez renseigner vos coordonnées, pour vous contacter si necessaire.",
		account_save: "Enregistrer",
		account_surname: "Nom",
		account_surname_placeholder: "",
		account_surname_help: "",
		account_name: "Prénom",
		account_name_placeholder: "",
		account_name_help: "",
		account_telephone: "Téléphone",
		account_telephone_placeholder: "",
		account_telephone_help: "",
		account_error_required: "Champ obligatoire",
		agency_expl_1_title: "Raison sociale",
		agency_expl_1_expl: "Précisez le nom de votre entreprise afin que vos clients puissent vous retrouver.",
		agency_expl_2_title: "Géolocalisation",
		agency_expl_2_expl: "L'application nécessite vos coordonnées afin de vous mettre en relation avec les clients les plus proches de vous.",
		agency_expl_3_title: "Activation du compte",
		agency_expl_3_expl: "Votre profil sera visible en ligne une fois la sauvegarde des informations effectuée.",
		services_add_button: "Ajouter une nouvelle offre...",
		services_none_yet: "Pour être visible sur la plateforme Hierø, veuillez préciser les types de prestations que vous proposez.",
		services_add_expl: "Veuillez préciser les les langues que vous pratiquez, puis cliquez sur 'Ajouter'.",
		services_add_src_lang: "Langue d'origine",
		services_dst_lang: "Vers",
		services_add: "Ajouter !",
		services_error_add: "Cette combinaison de langues n'est pas encore possible avec Hierø. Contactez-nous pour plus d'informations !",
		services_dont_see_title: "Je n'arrive pas à consulter mes langues.",
		services_dont_see_response: "Hierø facilite votre travail de traduction en vous fournissant des modèles de vos documents prêts à l'emploi. Nous développons continuellement notre gamme de documents. Veuillez nous contacter à <a href='mailto:contact@hiero-support.com'>contact@hiero-support.com</a> pour demander d'autres langues.",
		services_list_title: "Votre liste d'offres de service",
		services_expl: "Cliquez sur chaque offre afin de personnaliser les documents que vous gérez. Vous pouvez soit choisir de refuser un certain type de document en désactivant la bouton, soit préciser votre prix personnel pour traiter ce type de document.",
		services_list_active: "Activé",
		services_list_country: "Pays",
		services_list_document_type: "Type de document",
		services_list_average_price: "Prix moyen (HT)",
		services_list_your_price: "Votre prix (HT)",
		services_list_your_price_reset: "Réinitialiser",
		services_list_delete: "Supprimer",
		support_title: "Nos locaux",
		support_company: "By'Me Univers",
		support_addr1: "15 Avenue de Norvège",
		support_addr2: "91140 VILLEBON-SUR-YVETTE",
		support_addr3: "France",
		support_contact: "Contact",
		support_tel: "Tel : (+33) 1 80 38 30 46",
		support_email: "Mail : <a href='mailto:contact@hiero-support.com'>contact@hiero-support.com</a>",
		not_found_title: "Élement introuvable",
		not_found_subtitle: "La page que vous avez demandée n'existe pas",
		not_found_action: "Contactez <a href='mailto:contact@hiero-support.com'>le support</a> si le problème persiste.",
		not_found_home: "Accueil",
		AF: "Afghanistan",
		ZA: "Afrique du Sud",
		AL: "Albanie",
		DZ: "Algérie",
		DE: "Allemagne",
		AD: "Andorre",
		AO: "Angola",
		AI: "Anguilla",
		AQ: "Antarctique",
		AG: "Antigua-et-Barbuda",
		AN: "Antilles néerlandaises",
		SA: "Arabie saoudite",
		AR: "Argentine",
		AM: "Arménie",
		AW: "Aruba",
		AU: "Australie",
		AT: "Autriche",
		AZ: "Azerbaïdjan",
		BS: "Bahamas",
		BH: "Bahreïn",
		BD: "Bangladesh",
		BB: "Barbade",
		BY: "Bélarus",
		BE: "Belgique",
		BZ: "Belize",
		BJ: "Bénin",
		BM: "Bermudes",
		BT: "Bhoutan",
		BO: "Bolivie",
		BA: "Bosnie-Herzégovine",
		BW: "Botswana",
		BR: "Brésil",
		BN: "Brunéi Darussalam",
		BG: "Bulgarie",
		BF: "Burkina Faso",
		BI: "Burundi",
		KH: "Cambodge",
		CM: "Cameroun",
		CA: "Canada",
		CV: "Cap-Vert",
		EA: "Ceuta et Melilla",
		CL: "Chili",
		CN: "Chine",
		CY: "Chypre",
		CO: "Colombie",
		KM: "Comores",
		CG: "Congo-Brazzaville",
		KP: "Corée du Nord",
		KR: "Corée du Sud",
		CR: "Costa Rica",
		CI: "Côte d’Ivoire",
		HR: "Croatie",
		CU: "Cuba",
		DK: "Danemark",
		DG: "Diego Garcia",
		DJ: "Djibouti",
		DM: "Dominique",
		EG: "Égypte",
		SV: "El Salvador",
		AE: "Émirats arabes unis",
		EC: "Équateur",
		ER: "Érythrée",
		ES: "Espagne",
		EE: "Estonie",
		VA: "État de la Cité du Vatican",
		FM: "États fédérés de Micronésie",
		US: "États-Unis",
		ET: "Éthiopie",
		FJ: "Fidji",
		FI: "Finlande",
		FR: "France",
		GA: "Gabon",
		GM: "Gambie",
		GE: "Géorgie",
		GS: "Géorgie du Sud et les îles Sandwich du Sud",
		GH: "Ghana",
		GI: "Gibraltar",
		GR: "Grèce",
		GD: "Grenade",
		GL: "Groenland",
		GP: "Guadeloupe",
		GU: "Guam",
		GT: "Guatemala",
		GG: "Guernesey",
		GN: "Guinée",
		GQ: "Guinée équatoriale",
		GW: "Guinée-Bissau",
		GY: "Guyana",
		GF: "Guyane française",
		HT: "Haïti",
		HN: "Honduras",
		HU: "Hongrie",
		BV: "Île Bouvet",
		CX: "Île Christmas",
		CP: "Île Clipperton",
		AC: "Île de l'Ascension",
		IM: "Île de Man",
		NF: "Île Norfolk",
		AX: "Îles Åland",
		KY: "Îles Caïmans",
		IC: "Îles Canaries",
		CC: "Îles Cocos - Keeling",
		CK: "Îles Cook",
		FO: "Îles Féroé",
		HM: "Îles Heard et MacDonald",
		FK: "Îles Malouines",
		MP: "Îles Mariannes du Nord",
		MH: "Îles Marshall",
		UM: "Îles Mineures Éloignées des États-Unis",
		SB: "Îles Salomon",
		TC: "Îles Turks et Caïques",
		VG: "Îles Vierges britanniques",
		VI: "Îles Vierges des États-Unis",
		IN: "Inde",
		ID: "Indonésie",
		IQ: "Irak",
		IR: "Iran",
		IE: "Irlande",
		IS: "Islande",
		IL: "Israël",
		IT: "Italie",
		JM: "Jamaïque",
		JP: "Japon",
		JE: "Jersey",
		JO: "Jordanie",
		KZ: "Kazakhstan",
		KE: "Kenya",
		KG: "Kirghizistan",
		KI: "Kiribati",
		KW: "Koweït",
		LA: "Laos",
		LS: "Lesotho",
		LV: "Lettonie",
		LB: "Liban",
		LR: "Libéria",
		LY: "Libye",
		LI: "Liechtenstein",
		LT: "Lituanie",
		LU: "Luxembourg",
		MK: "Macédoine",
		MG: "Madagascar",
		MY: "Malaisie",
		MW: "Malawi",
		MV: "Maldives",
		ML: "Mali",
		MT: "Malte",
		MA: "Maroc",
		MQ: "Martinique",
		MU: "Maurice",
		MR: "Mauritanie",
		YT: "Mayotte",
		MX: "Mexique",
		MD: "Moldavie",
		MC: "Monaco",
		MN: "Mongolie",
		ME: "Monténégro",
		MS: "Montserrat",
		MZ: "Mozambique",
		MM: "Myanmar",
		NA: "Namibie",
		NR: "Nauru",
		NP: "Népal",
		NI: "Nicaragua",
		NE: "Niger",
		NG: "Nigéria",
		NU: "Niue",
		NO: "Norvège",
		NC: "Nouvelle-Calédonie",
		NZ: "Nouvelle-Zélande",
		OM: "Oman",
		UG: "Ouganda",
		UZ: "Ouzbékistan",
		PK: "Pakistan",
		PW: "Palaos",
		PA: "Panama",
		PG: "Papouasie-Nouvelle-Guinée",
		PY: "Paraguay",
		NL: "Pays-Bas",
		PE: "Pérou",
		PH: "Philippines",
		PN: "Pitcairn",
		PL: "Pologne",
		PF: "Polynésie française",
		PR: "Porto Rico",
		PT: "Portugal",
		QA: "Qatar",
		HK: "R.A.S. chinoise de Hong Kong",
		MO: "R.A.S. chinoise de Macao",
		QO: "Régions éloignées de l’Océanie",
		CF: "République centrafricaine",
		CD: "République démocratique du Congo",
		DO: "République dominicaine",
		CZ: "République tchèque",
		RE: "Réunion",
		RO: "Roumanie",
		GB: "Royaume-Uni",
		RU: "Russie",
		RW: "Rwanda",
		EH: "Sahara occidental",
		BL: "Saint-Barthélemy",
		KN: "Saint-Kitts-et-Nevis",
		SM: "Saint-Marin",
		MF: "Saint-Martin",
		PM: "Saint-Pierre-et-Miquelon",
		VC: "Saint-Vincent-et-les Grenadines",
		SH: "Sainte-Hélène",
		LC: "Sainte-Lucie",
		WS: "Samoa",
		AS: "Samoa américaines",
		ST: "Sao Tomé-et-Principe",
		SN: "Sénégal",
		RS: "Serbie",
		CS: "Serbie-et-Monténégro",
		SC: "Seychelles",
		SL: "Sierra Leone",
		SG: "Singapour",
		SK: "Slovaquie",
		SI: "Slovénie",
		SO: "Somalie",
		SD: "Soudan",
		LK: "Sri Lanka",
		SE: "Suède",
		CH: "Suisse",
		SR: "Suriname",
		SJ: "Svalbard et Île Jan Mayen",
		SZ: "Swaziland",
		SY: "Syrie",
		TJ: "Tadjikistan",
		TW: "Taïwan",
		TZ: "Tanzanie",
		TD: "Tchad",
		TF: "Terres australes et antarctiques françaises",
		IO: "Territoire britannique de l'océan Indien",
		PS: "Territoire palestinien",
		TH: "Thaïlande",
		TL: "Timor oriental",
		TG: "Togo",
		TK: "Tokelau",
		TO: "Tonga",
		TT: "Trinité-et-Tobago",
		TA: "Tristan da Cunha",
		TN: "Tunisie",
		TM: "Turkménistan",
		TR: "Turquie",
		TV: "Tuvalu",
		UA: "Ukraine",
		EU: "Union européenne",
		UY: "Uruguay",
		VU: "Vanuatu",
		VE: "Venezuela",
		VN: "Viêt Nam",
		WF: "Wallis-et-Futuna",
		YE: "Yémen",
		ZM: "Zambie",
		ZW: "Zimbabwe",
	},
	en: {
		login_caption_title: "Hierø - Translator Interface",
		login_caption_subtitle: "A software designed by translators for translators.",
		login_main_logo: "Hierø",
		login_main_title: "Translator Interface",
		login_main_subtitle: "Access your tasks and the document editor using the Hierø translator interface!",
		login_main_connect: "Log in",
		login_main_email: "Email",
		login_main_email_placeholder: "Enter your email address",
		login_main_email_help: "Use your email address to log in.",
		login_main_password: "Password",
		login_main_password_placeholder: "Please enter your password.",
		login_main_password_help: "",
		login_main_button: "Log in",
		login_main_to_signup: "Do not have an account? Sign up here!",
		login_main_copyright: "© Hierø - By'Me Univers 2019",
		login_error_required_field: "Required field",
		login_error_invalid_email: "The email you provided is not valid.",
		login_error_email_not_found: "This email is not registered.",
		signup_caption_title: "Hierø - Translator Interface",
		signup_caption_subtitle: "Choose Hierø and increase your visibility!",
		signup_main_logo: "Hierø",
		signup_main_title: "Sign up",
		signup_main_subtitle: "Create your account, join our interface and become more visible to our users!",
		signup_main_email: "Email",
		signup_main_email_placeholder: "",
		signup_main_email_help: "",
		signup_main_password: "Password",
		signup_main_password_placeholder: "",
		signup_main_password_help: "",
		signup_main_confirm: "Confirm your password",
		signup_main_confirm_placeholder: "",
		signup_main_confirm_help: "",
		signup_main_surname: "Surname",
		signup_main_surname_placeholder: "",
		signup_main_surname_help: "",
		signup_main_name: "Name",
		signup_main_name_placeholder: "",
		signup_main_name_help: "",
		signup_main_telephone: "Phone",
		signup_main_telephone_placeholder: "",
		signup_main_telephone_help: "",
		signup_main_button: "Sign up!",
		signup_main_to_signup: "Already have an account? Log in",
		signup_main_copyright: "© Hierø - By'Me Univers 2019",
		signup_error_required_field: "Required field",
		signup_error_invalid_email: "The email you provided is not valid.",
		signup_error_email_not_found: "This email is not registered.",
		signup_error_min_password: "Minimum 8 characters",
		signup_error_max_password: "Maximum 30 characters",
		signup_error_passwords_do_not_match: "Your passwords do not match",

		login_forgot_password: "Forgot your password?",
		reset_password_main: "Reset your password",
		reset_password_email_title: "Email",
		reset_password_email_placeholder: "",
		reset_password_email_help: "",
		reset_password_send: "Send",
		reset_password_login: "Log in",
		reset_password_success: "Email sent successfully. Please check your email and click the link in order to set a new password.",
		reset_password_error_required: "Required field.",
		reset_password_error_invalid_email: "The email you provided is not valid.",
		reset_password_error_user_not_found: "This email is not registered.",

		main_header_title: "Hierø -",
		main_header_subtitle: "Translator Interface",
		main_header_user_menu_logout: "Log out",
		main_left_menu_title: "Certified translator",
		main_left_menu_section_navigation: "Menu",
		main_left_menu_section_navigation_missions: "My tasks",
		main_left_menu_section_parameters: "Configuration",
		main_left_menu_section_parameters_agency: "My agency",
		main_left_menu_section_parameters_services: "My services",
		main_left_menu_section_parameters_user: "My Account",
		main_left_menu_section_parameters_support: "Support",
		agency_setup_title: "About your agency",
		agency_setup_explanation: "Hierø offers your services to clients close to your offices. <br>Please provide the name of your company and your address.<br>This information will be provided to clients while searching for a translator.",
		agency_setup_companyname: "Company name",
		agency_setup_companyname_placeholder: "",
		agency_setup_companyname_help: "The name of the company that will be presented to clients.",
		agency_setup_address: "Address",
		agency_setup_address_placeholder: "",
		agency_setup_address_help: "Start typing your address, then select the correct one in the dropdown menu.",
		agency_setup_addresscompl: "Address precisions",
		agency_setup_addresscompl_placeholder: "",
		agency_setup_addresscompl_help: "Building, staircase, code, etc. When your clients pick up their documents, they must have all the necessary information.",
		agency_setup_tel: "Telephone",
		agency_setup_tel_placeholder: "",
		agency_setup_tel_help: "The phone number to use to discuss with your clients.",
		agency_setup_email: "Email",
		agency_setup_email_placeholder: "",
		agency_setup_email_help: "The email address to use when corresponding with your clients. Please indicate a new email, should it not be your Hierø registration email.",
		agency_setup_save: "Save",
		agency_setup_error_required_field: "Required field",
		agency_setup_error_invalid_email: "The email you provided is not valid.",
		agency_setup_error_valid_address: "You must select a valid address from the dropdown menu.",

		agency_address_not_found: "Your address was not found?",
		agency_address_title: "Manually enter your details",
		agency_address_address: "Address:",
		agency_address_number: "N°",
		agency_address_street: "Street",
		agency_address_code: "Postal code",
		agency_address_city: "City",
		agency_address_country: "Country",
		agency_address_coords: "GPS coordinates:",
		agency_address_coords_lat: "Latitude",
		agency_address_coords_long: "Longitude",
		agency_address_cancel: "Cancel",
		agency_address_validate: "Continue",

		section_agency_title: "My agency",
		section_services_title: "My services",
		section_account_title: "My account",
		section_support_title: "Support",
		section_tasks_title: "My tasks",
		section_task_title: "Task",
		section_document_title: "Translate a document",
		notifications_new_task: "You have a new task!",
		notifications_task_confirmed: "Your task has just been confirmed. You can start translating.",
		notifications_task_validated: "Your client validated the task.",
		tasks_filters: "Filters",
		tasks_sent_on: "Sent on",
		tasks_src_lang: "Original language",
		tasks_src_country: "Original country",
		tasks_dst_country: "Translate to",
		tasks_state: "Order progress",
		tasks_price: "Price (excl.)",
		state_cancelled: "Canceled by the client",
		state_waiting_for_translator: "New!",
		state_waiting_for_payment: "Waiting for payment",
		state_refused_by_translator: "Mission refused",
		state_translating: "Translation in-progress",
		state_waiting_for_client_validation: "Done, waiting for client approval",
		state_validated: "Approved by client",
		prestation_breadcrumb_tasks: "My tasks",
		prestation_order_number: "Order number:",
		prestation_next_action_title: "Next steps",
		prestation_next_action_accept_order: "Accept the order",
		prestation_next_action_accept_order_expl: "Click here to accept the order. The client will then proceed with the payment. Once the payment has been completed, you can start the translation.",
		prestation_next_action_reject_order: "Refuse the order",
		prestation_next_action_reject_order_expl: "Click here to refuse the order.",
		prestation_next_action_rejected: "You refused the order.",
		prestation_next_action_waiting_for_payment: "Waiting for the client to make payment.",
		prestation_next_action_translate_expl: "Please proceed with the translation of each document in this order by clicking the 'Translate' button. When all documents are ready, click the 'Send to client' button. For any questions or details, you can chat directly with the client through our messaging service.",
		prestation_next_action_translate_all_done_expl: "You have finished all documents in this order.",
		prestation_next_action_translate_confirm: "Send to client for verification",
		prestation_next_action_waiting_for_validation: "The client is busy verifying the order.",
		prestation_next_action_validated: "The order was verified by the client.",
		prestation_order_details: "Order details:",
		prestation_order_from: "From",
		prestation_order_to: "to",
		prestation_order_country_origin: "Country of origin :",
		prestation_order_document_count: "Documents to translate :",
		prestation_order_price: "Price (excl.) :",
		prestation_order_date: "Order created on :",
		prestation_order_documents_title: "Documents to translate :",
		prestation_order_document_type: "Document type",
		prestation_order_document_originals: "Originals",
		prestation_order_document_translated: "Translated",
		prestation_order_document_not_yet_translated: "No translation yet!",
		prestation_order_document_translate: "Translate!",
		prestation_order_messaging_title: "Chat",
		prestation_order_messaging_send: "Send",
		prestation_order_messaging_client: "Client :",
		prestation_order_messaging_me: "Me :",
		prestation_order_confirm_ok: "Ok",
		prestation_order_confirm_cancel: "Cancel",
		prestation_order_validate_title: "Confirm",
		prestation_order_validate_msg: "Are you sure you want to finalise this order? The client will receive a notification and will have access to the the translated documents.",
		prestation_order_accept_title: "Confirm",
		prestation_order_accept_msg: "Are you sure you wish to accept this order?",
		prestation_order_refuse_title: "Confirm",
		prestation_order_refuse_msg: "Are you sure you wish to refuse this order?",
		prestation_document_validate: "Finalise and save!",
		prestation_document_show_original: "Show the original in another tab :",
		prestation_document_edit: "Document editor",
		prestation_document_edit_expl: "The text in the field above is highlighted in the document below. When you modify the text above, the document will automatically be updated. Use the arrows to navigate between phrases in the document.",
		prestation_document_get_browser: "You do not see the translated PDF? Please use an up to date browser like",
		prestation_document_button_reset: "Reset the document",
		prestation_document_confirm_title: "Confirmation",
		prestation_document_confirm_message: "Are you sure you want to reset this document? You will lose all your changes!",
		prestation_document_confirm_oui: "Yes",
		prestation_document_confirm_non: "No",		
		account_expl: "Please indicate your personal contact details in the event we need to get in touch.",
		account_save: "Save",
		account_surname: "Surname",
		account_surname_placeholder: "",
		account_surname_help: "",
		account_name: "Name",
		account_name_placeholder: "",
		account_name_help: "",
		account_telephone: "Telephone",
		account_telephone_placeholder: "",
		account_telephone_help: "",
		account_error_required: "Required",
		agency_expl_1_title: "Business name",
		agency_expl_1_expl: "Specify your business name so that your clients can find you.",
		agency_expl_2_title: "Geolocalisation",
		agency_expl_2_expl: "The application needs your coordonates in order to put you in touch with clients close to you.",
		agency_expl_3_title: "Activate your account",
		agency_expl_3_expl: "Your profile will be visible once you have saved the information.",
		services_add_button: "Add a new offer...",
		services_none_yet: "In order to be visible in the Hierø app, you need to specify what kind of translations you provide.",
		services_add_expl: "Please select the language you can translate from and to, then click 'Add'.",
		services_add_src_lang: "Original language",
		services_dst_lang: "To",
		services_add: "Add!",
		services_error_add: "This combination of languages is not yet supported by Hierø. Please contact us for more information!",
		services_dont_see_title: "I can't see my languages.",
		services_dont_see_response: "Hierø facilitates your translations by providing ready-made templates for specific documents. We are continually adding to our database, so do not hesitate to contact us at <a href='mailto:contact@hiero-support.com'>contact@hiero-support.com</a> to add more languages.",
		services_list_title: "Your list of services",
		services_expl: "Click on each service in order to customize the documents you will accept. You can either choose not to handle certain document types by disabling the toggle, or you can modify the amount you charge for that document by filling in the field for that document type.",
		services_list_active: "Enabled",
		services_list_country: "Country",
		services_list_document_type: "Document type",
		services_list_average_price: "Average price (excl.)",
		services_list_your_price: "Your price (excl.)",
		services_list_your_price_reset: "Reset",
		services_list_delete: "Remove",
		support_title: "Our offices",
		support_company: "By'Me Univers",
		support_addr1: "15 Avenue de Norvège",
		support_addr2: "91140 VILLEBON-SUR-YVETTE",
		support_addr3: "France",
		support_contact: "Contact",
		support_tel: "Tel: (+33) 1 80 38 30 46",
		support_email: "Email: <a href='mailto:contact@hiero-support.com'>contact@hiero-support.com</a>",
		not_found_title: "Element not found",
		not_found_subtitle: "The page you requested does not exist",
		not_found_action: "Contact <a href='mailto:contact@hiero-support.com'>support</a> if the problem persists.",
		not_found_home: "Home",
		AF: "Afghanistan",
		ZA: "South Africa",
		AL: "Albania",
		DZ: "Algeria",
		DE: "Germany",
		AD: "Andorra",
		AO: "Angola",
		AI: "Anguilla",
		AQ: "Antarctica",
		AG: "Antigua and Barbuda",
		AN: "Netherlands Antilles",
		SA: "Saudi Arabia",
		AR: "Argentina",
		AM: "Armenia",
		AW: "Aruba",
		AU: "Australia",
		AT: "Austria",
		AZ: "Azerbaijan",
		BS: "Bahamas",
		BH: "Bahrain",
		BD: "Bangladesh",
		BB: "Barbados",
		BY: "Belarus",
		BE: "Belgium",
		BZ: "Belize",
		BJ: "Benin",
		BM: "Bermuda",
		BT: "Bhutan",
		BO: "Bolivia",
		BA: "Bosnia and Herzegovina",
		BW: "Botswana",
		BR: "Brazil",
		BN: "Brunei",
		BG: "Bulgaria",
		BF: "Burkina Faso",
		BI: "Burundi",
		KH: "Cambodia",
		CM: "Cameroon",
		CA: "Canada",
		CV: "Cape Verde",
		EA: "Ceuta and Melilla",
		CL: "Chile",
		CN: "China",
		CY: "Cyprus",
		CO: "Colombia",
		KM: "Comoros",
		CG: "Congo-Brazzaville",
		KP: "North Korea",
		KR: "South Korea",
		CR: "Costa Rica",
		CI: "Ivory Coast",
		HR: "Croatia",
		CU: "Cuba",
		DK: "Denmark",
		DG: "Diego Garcia",
		DJ: "Djibouti",
		DM: "Dominica",
		EG: "Egypt",
		SV: "El Salvador",
		AE: "United Arab Emirates",
		EC: "Ecuador",
		ER: "Eritrea",
		ES: "Spain",
		EE: "Estonia",
		VA: "Vatican",
		FM: "Micronesia",
		US: "United States of America",
		ET: "Ethiopia",
		FJ: "Fiji",
		FI: "Finland",
		FR: "France",
		GA: "Gabon",
		GM: "Gambia",
		GE: "Georgia",
		GS: "South Georgia and the South Sandwich Islands",
		GH: "Ghana",
		GI: "Gibraltar",
		GR: "Greece",
		GD: "Granada",
		GL: "Greenland",
		GP: "Guadeloupe",
		GU: "Guam",
		GT: "Guatemala",
		GG: "Guernsey",
		GN: "Guinea",
		GQ: "Equatorial Guinea",
		GW: "Guinea-Bissau",
		GY: "Guyana",
		GF: "French Guyana",
		HT: "Haiti",
		HN: "Honduras",
		HU: "Hungary",
		BV: "Bouvet Island",
		CX: "Christmas Island",
		CP: "Clipperton Island",
		AC: "Ascension Island",
		IM: "Isle of Man",
		NF: "Norfolk Island",
		AX: "Åland Islands",
		KY: "Cayman Islands",
		IC: "Canary Islands",
		CC: "Cocos (Keeling) Islands",
		CK: "Cook Islands",
		FO: "Faroe Islands",
		HM: "Heard Island and McDonald Islands",
		FK: "Falkland Islands",
		MP: "Northern Mariana Islands",
		MH: "Marshall Islands",
		UM: "United States Minor Outlying Islands",
		SB: "Solomon Islands",
		TC: "Turks and Caicos Islands",
		VG: "British Virgin Islands",
		VI: "U.S. Virgin Islands",
		IN: "India",
		ID: "Indonesia",
		IQ: "Iraq",
		IR: "Iran",
		IE: "Ireland",
		IS: "Iceland",
		IL: "Israel",
		IT: "Italy",
		JM: "Jamaica",
		JP: "Japan",
		JE: "Jersey",
		JO: "Jordan",
		KZ: "Kazakhstan",
		KE: "Kenya",
		KG: "Kyrgyzstan",
		KI: "Kiribati",
		KW: "Kuwait",
		LA: "Laos",
		LS: "Lesotho",
		LV: "Latvia",
		LB: "Lebanon",
		LR: "Liberia",
		LY: "Libya",
		LI: "Liechtenstein",
		LT: "Lithuania",
		LU: "Luxembourg",
		MK: "Macedonia",
		MG: "Madagascar",
		MY: "Malaysia",
		MW: "Malawi",
		MV: "Maldives",
		ML: "Mali",
		MT: "Malta",
		MA: "Morocco",
		MQ: "Martinique",
		MU: "Mauritius",
		MR: "Mauritania",
		YT: "Mayotte",
		MX: "Mexico",
		MD: "Moldova",
		MC: "Monaco",
		MN: "Mongolia",
		ME: "Montenegro",
		MS: "Montserrat",
		MZ: "Mozambique",
		MM: "Myanmar",
		NA: "Namibia",
		NR: "Nauru",
		NP: "Nepal",
		NI: "Nicaragua",
		NE: "Niger",
		NG: "Nigeria",
		NU: "Niue",
		NO: "Norway",
		NC: "New Caledonia",
		NZ: "New Zealand",
		OM: "Oman",
		UG: "Uganda",
		UZ: "Uzbekistan",
		PK: "Pakistan",
		PW: "Palau",
		PA: "Panama",
		PG: "Papua New Guinea",
		PY: "Paraguay",
		NL: "Netherlands",
		PE: "Peru",
		PH: "Philippines",
		PN: "Pitcairn",
		PL: "Poland",
		PF: "French Polynesia",
		PR: "Puerto Rico",
		PT: "Portugal",
		QA: "Qatar",
		HK: "Hong Kong",
		MO: "Macao",
		QO: "Remote Oceania",
		CF: "Central African Republic",
		CD: "Democratic Republic of the Congo",
		DO: "Dominican Republic",
		CZ: "Czech Republic",
		RE: "Reunion",
		RO: "Romania",
		GB: "United Kingdom",
		RU: "Russia",
		RW: "Rwanda",
		EH: "Western Sahara",
		BL: "Saint Barthélemy",
		KN: "Saint Kitts and Nevis",
		SM: "San Marino",
		MF: "Saint Martin Island",
		PM: "Saint Pierre and Miquelon",
		VC: "Saint Vincent and the Grenadines",
		SH: "Saint Helena",
		LC: "Saint Lucia",
		WS: "Samoa",
		AS: "American Samoa",
		ST: "São Tomé and Príncipe",
		SN: "Senegal",
		RS: "Serbia",
		CS: "Serbia and Montenegro",
		SC: "Seychelles",
		SL: "Sierra Leone",
		SG: "Singapore",
		SK: "Slovakia",
		SI: "Slovenia",
		SO: "Somalia",
		SD: "Sudan",
		LK: "Sri Lanka",
		SE: "Sweden",
		CH: "Switzerland",
		SR: "Suriname",
		SJ: "Svalbard and Jan Mayen",
		SZ: "Swaziland",
		SY: "Syria",
		TJ: "Tajikistan",
		TW: "Taiwan",
		TZ: "Tanzania",
		TD: "Chad",
		TF: "French Southern and Antarctic Lands",
		IO: "British Indian Ocean Territory",
		PS: "Palestinian territories",
		TH: "Thailand",
		TL: "East Timor",
		TG: "Togo",
		TK: "Tokelau",
		TO: "Tonga",
		TT: "Trinidad and Tobago",
		TA: "Tristan da Cunha",
		TN: "Tunisia",
		TM: "Turkmenistan",
		TR: "Turkey",
		TV: "Tuvalu",
		UA: "Ukraine",
		EU: "European Union",
		UY: "Uruguay",
		VU: "Vanuatu",
		VE: "Venezuela",
		VN: "Vietnam",
		WF: "Wallis and Futuna",
		YE: "Yemen",
		ZM: "Zambia",
		ZW: "Zimbabwe",
	},
	es: {
		login_caption_title: "Hierø - Interfaz traductor",
		login_caption_subtitle: "Un programa informático diseñado por traductores para los traductores.",
		login_main_logo: "Hierø",
		login_main_title: "Interfaz del traductor",
		login_main_subtitle: "¡Accede a sus misiones y el editor de documentos con la interfaz traductor Hierø!",
		login_main_connect: "Conectarse",
		login_main_email: "Dirección electrónica",
		login_main_email_placeholder: "Indicar la dirección electrónica",
		login_main_email_help: "Inicie la sesión con su dirección electrónica",
		login_main_password: "Contraseña",
		login_main_password_placeholder: "Indicar su contraseña",
		login_main_password_help: "",
		login_main_button: "Conectarse",
		login_main_to_signup: "¿No dispone de una cuenta? ¡Registrarse!",
		login_main_copyright: "© Hierø - By'Me Univers 2019",
		login_error_required_field: "Campo obligatorio",
		login_error_invalid_email: "La dirección electrónica no es válida.",
		login_error_email_not_found: "La dirección electrónica no existe.",
		signup_caption_title: "Hierø - Interfaz del traductor",
		signup_caption_subtitle: "¡Elija Hierø y gane en visibilidad!",
		signup_main_logo: "Hierø",
		signup_main_title: "Inscripción",
		signup_main_subtitle: "¡Cree su cuenta, únase a nuestra interfaz y hágase en visible para nuestros usuarios!",
		signup_main_email: "Dirección electrónica",
		signup_main_email_placeholder: "",
		signup_main_email_help: "",
		signup_main_password: "Contraseña",
		signup_main_password_placeholder: "",
		signup_main_password_help: "",
		signup_main_confirm: "Confirmar vuestra contraseña",
		signup_main_confirm_placeholder: "",
		signup_main_confirm_help: "",
		signup_main_surname: "Apellido",
		signup_main_surname_placeholder: "",
		signup_main_surname_help: "",
		signup_main_name: "Nombre",
		signup_main_name_placeholder: "",
		signup_main_name_help: "",
		signup_main_telephone: "Teléfono",
		signup_main_telephone_placeholder: "",
		signup_main_telephone_help: "",
		signup_main_button: "Registrarse",
		signup_main_to_signup: "¿Dispone de una cuenta? Conectarse",
		signup_main_copyright: "© Hierø - By'Me Univers 2019",
		signup_error_required_field: "Campo obligatorio",
		signup_error_invalid_email: "La dirección electrónica no es válida.",
		signup_error_email_not_found: "La dirección electrónica no existe.",
		signup_error_min_password: "Al menos 8 caracteres",
		signup_error_max_password: "30 caracteres máximo",
		signup_error_passwords_do_not_match: "Las contraseñas no coinciden",

		login_forgot_password: "¿Has olvidado la contraseña?",
		reset_password_main: "Reiniciar la contraseña ",
		reset_password_email_title: "Dirección electrónica",
		reset_password_email_placeholder: "",
		reset_password_email_help: "",
		reset_password_send: "Enviar",
		reset_password_login: "Conexión",
		reset_password_success: "Se ha enviado un correo a su dirección electrónica. Luego haga clic en el enlace para cambiar la contraseña.",
		reset_password_error_required: "Campo obligatorio",
		reset_password_error_invalid_email: "La dirección electrónica no es válida",
		reset_password_error_user_not_found: "La dirección electrónica no existe",

		main_header_title: "Hierø -",
		main_header_subtitle: "Interfaz del traductor",
		main_header_user_menu_logout: "Desconectar",
		main_left_menu_title: "Traductor jurado",
		main_left_menu_section_navigation: "Menú de navegación",
		main_left_menu_section_navigation_missions: "Mis misiones",
		main_left_menu_section_parameters: "Configuración",
		main_left_menu_section_parameters_agency: "Mi agencia",
		main_left_menu_section_parameters_services: "Mis servicios",
		main_left_menu_section_parameters_user: "Mi cuenta",
		main_left_menu_section_parameters_support: "Soporte",
		agency_setup_title: "A propósito de su agencia",
		agency_setup_explanation: "Hierø ofrece sus servicios a los clientes cercanos de sus locales. <br> Por favor especifica su razón social y su dirección. <br>Se ofrecerá las informaciones a los clientes cuando busquen un traductor.",
		agency_setup_companyname: "Razón social",
		agency_setup_companyname_placeholder: "",
		agency_setup_companyname_help: "El nombre de la empresa que será sugerida a los clientes.",
		agency_setup_address: "Dirección",
		agency_setup_address_placeholder: "",
		agency_setup_address_help: "Empieza a escribir la dirección y después seleccione la dirección adecuada en el menú desplegable.",
		agency_setup_addresscompl: "Complementos de dirección",
		agency_setup_addresscompl_placeholder: "",
		agency_setup_addresscompl_help: "Edificio, escalera, código de acceso, etc. Cuando sus clientes recogen sus documentos, necesitan todas las informaciones.",
		agency_setup_tel: "Teléfono",
		agency_setup_tel_placeholder: "",
		agency_setup_tel_help: "El número de teléfono a utilizar para conversar con sus clientes.",
		agency_setup_email: "Dirección electrónica",
		agency_setup_email_placeholder: "",
		agency_setup_email_help: "La dirección electrónica a utilizar para conversar con sus clientes. Por favor indique otra si no es la dirección de registro de Hierø.",
		agency_setup_save: "Guardar",
		agency_setup_error_required_field: "Campo obligatorio",
		agency_setup_error_invalid_email: "La dirección electrónica no es válida.",
		agency_setup_error_valid_address: "Por favor escoge una de las direcciones propuestas en el menú desplegable.",

		agency_address_not_found: "¿No se puede encontrar su dirección?",
    agency_address_title: "Introducir manualmente sus datos",
    agency_address_address: "Dirección :",
    agency_address_number: "N.°",
    agency_address_street: "Calle",
    agency_address_code: "Código postal",
    agency_address_city: "Ciudad",
    agency_address_country: "País",
    agency_address_coords: "Coordenadas GPS:",
    agency_address_coords_lat: "Latitud",
    agency_address_coords_long: "Longitud",
    agency_address_cancel: "Cancelar",
    agency_address_validate: "Validar",

		section_agency_title: "Mi agencia",
		section_services_title: "Mis servicios",
		section_account_title: "Mi cuenta",
		section_support_title: "Soporte",
		section_tasks_title: "Mis misiones",
		section_task_title: "Efectuar una misión",
		section_document_title: "Traducir un documento",
		notifications_new_task: "¡Usted recibió una nueva misión!",
		notifications_task_confirmed: "La misión fue confirmada. Usted puede empezar la traducción.",
		notifications_task_validated: "El cliente validó la misión.",
		tasks_filters: "Filtros",
		tasks_sent_on: "Enviado el",
		tasks_src_lang: "Idioma original",
		tasks_src_country: "País de origen",
		tasks_dst_country: "Traducir al",
		tasks_state: "Estado",
		tasks_price: "Precio (neto)",
		state_cancelled: "Cancelado por el cliente",
		state_waiting_for_translator: "Nuevo",
		state_waiting_for_payment: "Esperando el pago",
		state_refused_by_translator: "Misión rechazada",
		state_translating: "Traducción pendiente",
		state_waiting_for_client_validation: "Terminada. Esperando la validación por el cliente",
		state_validated: "Validado por el cliente",
		prestation_breadcrumb_tasks: "Mis misiones",
		prestation_order_number: "Número de pedido:",
		prestation_next_action_title: "Próximas etapas",
		prestation_next_action_accept_order: "Aceptar el pedido",
		prestation_next_action_accept_order_expl: "Haga clic aquí para aceptar el pedido. Luego, el cliente procederá al pago. Una vez el pago realizado, puede empezar la traducción.",
		prestation_next_action_reject_order: "Rechazar el pedido",
		prestation_next_action_reject_order_expl: "Haga clic aquí para rechazar el pedido.",
		prestation_next_action_rejected: "Usted rechazó el pedido.",
		prestation_next_action_waiting_for_payment: "Esperando el pago por el cliente.",
		prestation_next_action_translate_expl: "Puede proceder a la traducción de cada documento en este pedido haciendo clic en el botón 'Traducir'. Una vez los documentos listos, haga clic en el botón 'Enviar al cliente'. Para cualquier pregunta o precisión, puede conversar con el cliente directamente por medio de la mensajería electrónica.",
		prestation_next_action_translate_all_done_expl: "Usted acabó todos los documentos de este pedido.",
		prestation_next_action_translate_confirm: "Enviar al cliente para validación",
		prestation_next_action_waiting_for_validation: "El cliente está validando la traducción.",
		prestation_next_action_validated: "Pedido confirmado por el cliente.",
		prestation_order_details: "Detalles del pedido:",
		prestation_order_from: "De",
		prestation_order_to: "Para",
		prestation_order_country_origin: "País de origen:",
		prestation_order_document_count: "Documentos para traducir:",
		prestation_order_price: "Precio (neto):",
		prestation_order_date: "Pedido hecho el:",
		prestation_order_documents_title: "Documentos para traducir:",
		prestation_order_document_type: "Tipo de documento",
		prestation_order_document_originals: "Originales",
		prestation_order_document_translated: "Traducido(s)",
		prestation_order_document_not_yet_translated: "Aún no hay traducción",
		prestation_order_document_translate: "¡Traducir!",
		prestation_order_messaging_title: "Mensajería electrónica",
		prestation_order_messaging_send: "Enviar",
		prestation_order_messaging_client: "Cliente:",
		prestation_order_messaging_me: "Yo:",
		prestation_order_confirm_ok: "Ok",
		prestation_order_confirm_cancel: "Cancelar",
		prestation_order_validate_title: "Confirmar",
		prestation_order_validate_msg: "¿Usted está seguro de que quiere confirmar el pedido? El cliente recibirá una notificación y podrá acceder a los documentos traducidos.",
		prestation_order_accept_title: "Confirmar",
		prestation_order_accept_msg: "¿Usted está seguro de que quiere aceptar este pedido?",
		prestation_order_refuse_title: "Confirmar",
		prestation_order_refuse_msg: "¿Usted está seguro de que quiere rechazar este pedido?",
		prestation_document_validate: "¡Confirmar la traducción!",
		prestation_document_show_original: "Ver el original en otra pestaña:",
		prestation_document_edit: "Editor de documento",
		prestation_document_edit_expl: "El texto en el campo más arriba es subrayado en el documento más abajo. Al entrar el texto traducido en el campo, el documento se actualizará automáticamente. Utilice las flechas para navegar el documento.",
		prestation_document_get_browser: "¿Usted no consigue ver el PDF? Por favor utilice un navegador reciente como",
		prestation_document_button_reset: "Reiniciar el documento",
		prestation_document_confirm_title: "Confirmar",
		prestation_document_confirm_message: "¿Usted está seguro de que quiere reiniciar la traducción? ¡Todos los cambios se perderán!",
		prestation_document_confirm_oui: "Sí",
		prestation_document_confirm_non: "No",
		account_expl: "Indique sus datos para que podemos contactarlo si es necesario.",
		account_save: "Guardar",
		account_surname: "Apellido",
		account_surname_placeholder: "",
		account_surname_help: "",
		account_name: "Nombre",
		account_name_placeholder: "",
		account_name_help: "",
		account_telephone: "Teléfono",
		account_telephone_placeholder: "",
		account_telephone_help: "",
		account_error_required: "Campo obligatorio",
		agency_expl_1_title: "Razón social",
		agency_expl_1_expl: "Especifique el nombre de su empresa para que los clientes puedan encontrarlo.",
		agency_expl_2_title: "Localización",
		agency_expl_2_expl: "La aplicación necesita su información de contacto para ponerlo en  contacto con los clientes más cercano a usted.",
		agency_expl_3_title: "Activar su cuenta",
		agency_expl_3_expl: "Su perfil será visible en línea, en cuanto haya guardado su información de contacto.",
		services_add_button: "Añadir una nueva oferta…",
		services_none_yet: "Para ser visible en la plataforma Hierø, especifique los tipos de traducciones que ofrece.",
		services_add_expl: "Por favor, especifique los idiomas que practica y luego haga clic en 'Añadir'.",
		services_add_src_lang: "Idioma original",
		services_dst_lang: "Al",
		services_add: "¡Añadir!",
		services_error_add: "Esta combinación de idiomas aún no es posible con Hierø. ¡Contáctenos para tener más información!",
		services_dont_see_title: "No puedo consultar mis idiomas.",
		services_dont_see_response: "Hierø simplifica vuestra traducción al proporcionar unos modelos de documentos listos par usar. Siempre desarrollamos nuestros modelos. Contàctenos por <a href='mailto:contact@hiero-support.com'>contact@hiero-support.com</a> para añadir otros idiomas.",
		services_list_title: "Su lista de servicios",
		services_expl: "Haga clic en cada servicio para personalizar los documentos que acepta. Puede rechazar un tipo de documento, deshabilitando el botón, o especificar su precio para procesar este tipo de documento.",
		services_list_active: "Activado",
		services_list_country: "País",
		services_list_document_type: "Tipo de documento",
		services_list_average_price: "Precio medio (neto)",
		services_list_your_price: "Su precio (neto)",
		services_list_your_price_reset: "Reiniciar",
		services_list_delete: "Borrar",
		support_title: "Nuestros locales",
		support_company: "By'Me Univers",
		support_addr1: "15 Avenue de Norvège",
		support_addr2: "91140 VILLEBON-SUR-YVETTE",
		support_addr3: "Francia",
		support_contact: "Contacto",
		support_tel: "Tel.: (+33) 1 80 38 30 46",
		support_email: "Correo electrónico: <a href='mailto:contact@hiero-support.com'>contact@hiero-support.com</a>",
		not_found_title: "Elemento no encontrado",
		not_found_subtitle: "La página que solicitó no existe.",
		not_found_action: "Contactar <a href='mailto:contact@hiero-support.com'>el soporte</a> si el problema persiste.",
		not_found_home: "Inicio",
		AF: "Afganistán",
		ZA: "Sudáfrica",
		AL: "Albania",
		DZ: "Argelia",
		DE: "Alemania",
		AD: "Andorra",
		AO: "Angola",
		AI: "Anguila",
		AQ: "Antártida",
		AG: "Antigua y Barbuda",
		AN: "Antillas Neerlandesas",
		SA: "Arabia Saudita",
		AR: "Argentina",
		AM: "Armenia",
		AW: "Aruba",
		AU: "Australia",
		AT: "Austria",
		AZ: "Azerbaiyán",
		BS: "Bahamas",
		BH: "Baréin",
		BD: "Bangladés",
		BB: "Barbados",
		BY: "Belarús",
		BE: "Bélgica",
		BZ: "Belice",
		BJ: "Benín",
		BM: "Bermudas",
		BT: "Bután",
		BO: "Bolivia",
		BA: "Bosnia y Herzegovina",
		BW: "Botsuana",
		BR: "Brasil",
		BN: "Brunéi",
		BG: "Bulgaria",
		BF: "Burkina Faso",
		BI: "Burundi",
		KH: "Camboya",
		CM: "Camerún",
		CA: "Canadá",
		CV: "Cabo Verde",
		EA: "Ceuta y Melilla",
		CL: "Chile",
		CN: "China",
		CY: "Chipre",
		CO: "Colombia",
		KM: "Comoras",
		CG: "Congo-Brazzaville",
		KP: "Corea del Norte",
		KR: "Corea del Sur",
		CR: "Costa Rica",
		CI: "Costa de Marfil",
		HR: "Croacia",
		CU: "Cuba",
		DK: "Dinamarca",
		DG: "Diego García",
		DJ: "Yibuti",
		DM: "Dominica",
		EG: "Egipto",
		SV: "El Salvador",
		AE: "Emiratos Árabes Unidos",
		EC: "Ecuador",
		ER: "Eritrea",
		ES: "España",
		EE: "Estonia",
		VA: "Estado de la Ciudad del Vaticano",
		FM: "Estados Federados de Micronesia",
		US: "Estados Unidos",
		ET: "Etiopia",
		FJ: "Fiyi",
		FI: "Finlandia",
		FR: "Francia",
		GA: "Gabón",
		GM: "Gambia",
		GE: "Georgia",
		GS: "Islas Georgias del Sur y Sándwich del Sur",
		GH: "Ghana",
		GI: "Gibraltar",
		GR: "Grecia",
		GD: "Granada",
		GL: "Groenlandia",
		GP: "Guadalupe",
		GU: "Guam",
		GT: "Guatemala",
		GG: "Guernsey",
		GN: "Guinea",
		GQ: "Guinea ecuatorial",
		GW: "Guinea-Bisáu",
		GY: "Guyana",
		GF: "Guayana Francesa",
		HT: "Haití",
		HN: "Honduras",
		HU: "Hungría",
		BV: "Isla Bouvet",
		CX: "Isla de Navidad",
		CP: "Isla Clipperton",
		AC: "Isla Ascensión",
		IM: "Isla de Man",
		NF: "Isla Norfolk",
		AX: "Islas Åland",
		KY: "Islas Caimán",
		IC: "Islas Canarias",
		CC: "Islas Cocos / Islas Keeling",
		CK: "Islas Cook",
		FO: "Islas Feroe",
		HM: "Islas Heard y McDonald",
		FK: "Islas Malvinas",
		MP: "Islas Marianas del Norte",
		MH: "República de las Islas Marshall",
		UM: "Islas Ultramarinas Menores de los Estados Unidos",
		SB: "Islas Salomón",
		TC: "Islas Turcas y Caicos",
		VG: "Islas Vírgenes Británicas",
		VI: "Islas Vírgenes de os Estados Unidos",
		IN: "India",
		ID: "Indonesia",
		IQ: "Irak",
		IR: "Irán",
		IE: "Irlanda",
		IS: "Islandia",
		IL: "Israel",
		IT: "Italia",
		JM: "Jamaica",
		JP: "Japón",
		JE: "Jersey",
		JO: "Jordania",
		KZ: "Kazajistán",
		KE: "Kenia",
		KG: "Kirguistán",
		KI: "Kiribati",
		KW: "Kuwait",
		LA: "Laos",
		LS: "Lesoto",
		LV: "Letonia",
		LB: "Líbano",
		LR: "Liberia",
		LY: "Libia",
		LI: "Liechtenstein",
		LT: "Lituania",
		LU: "Luxemburgo",
		MK: "Macedonia",
		MG: "Madagascar",
		MY: "Malasia",
		MW: "Malaui",
		MV: "Maldivas",
		ML: "Malí",
		MT: "Malta",
		MA: "Marruecos",
		MQ: "Martinica",
		MU: "Mauricio",
		MR: "Mauritania",
		YT: "Mayotte",
		MX: "México",
		MD: "Moldavia",
		MC: "Mónaco",
		MN: "Mongolia",
		ME: "Montenegro",
		MS: "Montserrat",
		MZ: "Mozambique",
		MM: "Myanmar",
		NA: "Namibia",
		NR: "Nauru",
		NP: "Nepal",
		NI: "Nicaragua",
		NE: "Níger",
		NG: "Nigeria",
		NU: "Niue",
		NO: "Noruega",
		NC: "Nueva Caledonia",
		NZ: "Nueva Zelandia",
		OM: "Omán",
		UG: "Uganda",
		UZ: "Uzbekistán",
		PK: "Pakistán",
		PW: "Palaos",
		PA: "Panamá",
		PG: "Papúa Nueva Guinea",
		PY: "Paraguay",
		NL: "Países Bajos",
		PE: "Perú",
		PH: "Filipinas",
		PN: "Islas Pitcairn",
		PL: "Polonia",
		PF: "Polinesia Francesa",
		PR: "Puerto Rico",
		PT: "Portugal",
		QA: "Catar",
		HK: "R.A.E de Hong Kong de la República Popular China",
		MO: "R.A.E. de Macao",
		QO: "Oceanía Lejana",
		CF: "República Centroafricana",
		CD: "República Democrática del Congo",
		DO: "República Dominicana",
		CZ: "República Checa",
		RE: "Reunión",
		RO: "Rumanía",
		GB: "Reino Unido",
		RU: "Rusia",
		RW: "Ruanda",
		EH: "Sahara Occidental",
		BL: "San Bartolomé",
		KN: "San Cristóbal y Nieves",
		SM: "San Marino",
		MF: "Isla San Martín",
		PM: "San Pedro y Miquelón",
		VC: "San Vicente y las Granadinas",
		SH: "Isla Santa Elena",
		LC: "Santa Lucia",
		WS: "Samoa",
		AS: "Samoa Americana",
		ST: "Santo Tomé y Príncipe",
		SN: "Senegal",
		RS: "Serbia",
		CS: "Serbia y Montenegro",
		SC: "Seychelles",
		SL: "Sierra Leona",
		SG: "Singapur",
		SK: "Eslovaquia",
		SI: "Eslovenia",
		SO: "Somalia",
		SD: "Sudán",
		LK: "Sri Lanka",
		SE: "Suecia",
		CH: "Suiza",
		SR: "Surinam",
		SJ: "Svalbard y Jan Mayen",
		SZ: "Suazilandia",
		SY: "Siria",
		TJ: "Tayikistán",
		TW: "Taiwán",
		TZ: "Tanzania",
		TD: "Chad",
		TF: "Tierras Australes y Antárticas Francesas",
		IO: "Territorio Británico del Océano Índico",
		PS: "Territorios Palestinos",
		TH: "Tailandia",
		TL: "Timor Oriental",
		TG: "Togo",
		TK: "Tokelau",
		TO: "Tonga",
		TT: "Trinidad y Tobago",
		TA: "Tristán da Cunha",
		TN: "Túnez",
		TM: "Turkmenistán",
		TR: "Turquía",
		TV: "Tuvalu",
		UA: "Ucrania",
		EU: "Unión Europea",
		UY: "Uruguay",
		VU: "Vanuatu",
		VE: "Venezuela",
		VN: "Vietnam",
		WF: "Wallis y Futuna",
		YE: "Yemen",
		ZM: "Zambia",
		ZW: "Zimbabue",
	},
	pt: {
		login_caption_title: "Hierø - Interface tradutor",
		login_caption_subtitle: "Um programa imaginado por tradutores para tradutores.",
		login_main_logo: "Hierø",
		login_main_title: "Interface do tradutor",
		login_main_subtitle: "Acesse suas missões e o editor de documentos con a interfaca tradutor Hierø!",
		login_main_connect: "Conectar",
		login_main_email: "E-mail",
		login_main_email_placeholder: "Indicar o endereço de e-mail",
		login_main_email_help: "Inicie a sessão com seu endereço de e-mail",
		login_main_password: "Senha",
		login_main_password_placeholder: "Indicar sua senha",
		login_main_password_help: "",
		login_main_button: "Conectar",
		login_main_to_signup: "Ainda não tem uma conta ? Registrar-se !",
		login_main_copyright: "© Hierø - By'Me Univers 2019",
		login_error_required_field: "Campo obrigatório",
		login_error_invalid_email: "O endereço de e-mail não é válido.",
		login_error_email_not_found: "O endereço de e-mail não existe.",
		signup_caption_title: "Hierø - Interface do tradutor",
		signup_caption_subtitle: "Escolha Hierø e ganhe visibilidade!",
		signup_main_logo: "Hierø",
		signup_main_title: "Inscrição",
		signup_main_subtitle: "Crie a sua conta, entre na nossa interface e seja visível para nossos usuários!",
		signup_main_email: "Endereço de e-mail",
		signup_main_email_placeholder: "",
		signup_main_email_help: "",
		signup_main_password: "Senha",
		signup_main_password_placeholder: "",
		signup_main_password_help: "",
		signup_main_confirm: "Confirmar sua senha",
		signup_main_confirm_placeholder: "",
		signup_main_confirm_help: "",
		signup_main_surname: "Sobrenome",
		signup_main_surname_placeholder: "",
		signup_main_surname_help: "",
		signup_main_name: "Nome",
		signup_main_name_placeholder: "",
		signup_main_name_help: "",
		signup_main_telephone: "Telefone",
		signup_main_telephone_placeholder: "",
		signup_main_telephone_help: "",
		signup_main_button: "Registrar-se",
		signup_main_to_signup: "Já está inscrito ? Conectar",
		signup_main_copyright: "© Hierø - By'Me Univers 2019",
		signup_error_required_field: "Campo obrigatório",
		signup_error_invalid_email: "O endereço de e-mail não é válido.",
		signup_error_email_not_found: "O endereço de e-mail não existe.",
		signup_error_min_password: "Mínimo de 8 caracteres",
		signup_error_max_password: "30 caracteres máximo",
		signup_error_passwords_do_not_match: "As senhas não correspondem",

		login_forgot_password: "Esqueceu a senha ?",
   	reset_password_main: "Reiniciar a senha",
   	reset_password_email_title: "E-mail",
   	reset_password_email_placeholder: "",
    reset_password_email_help: "",
    reset_password_send: "Enviar",
    reset_password_login: "Conectar",
    reset_password_success: "Un email foi enviado para seu endereço. Depois, clique no link para modificar a senha.",
    reset_password_error_required: "Campo obrigatório",
    reset_password_error_invalid_email: "O endereço de e-mail não é válido.",
    reset_password_error_user_not_found: " Oendereço de e-mail não existe.",

		main_header_title: "Hierø -",
		main_header_subtitle: "Interface do tradutor",
		main_header_user_menu_logout: "Desconectar",
		main_left_menu_title: "Tradutor juramentado",
		main_left_menu_section_navigation: "Menu de navegação",
		main_left_menu_section_navigation_missions: "Minhas missões",
		main_left_menu_section_parameters: "Configuração",
		main_left_menu_section_parameters_agency: "Minha agência",
		main_left_menu_section_parameters_services: "Meus serviços",
		main_left_menu_section_parameters_user: "Minha conta",
		main_left_menu_section_parameters_support: "Suporte",
		agency_setup_title: "Sobre sua agência",
		agency_setup_explanation: "Hierø ofrece seus serviços a clientes pertos das suas instalações. <br>Por favor, indique aqui sua razão social e seu endereço postal. <br>Essas informações serão oferecidas aos clientes que procuram um tradutor.",
		agency_setup_companyname: "Razão social",
		agency_setup_companyname_placeholder: "",
		agency_setup_companyname_help: "O nome da empresa que será oferecida aos clientes.",
		agency_setup_address: "Endereço postal",
		agency_setup_address_placeholder: "",
		agency_setup_address_help: "Comece a digitar o endereço postal e depois escolha o endereço correto na lista suspensa.",
		agency_setup_addresscompl: "Complementos de endereço",
		agency_setup_addresscompl_placeholder: "",
		agency_setup_addresscompl_help: "Edifício, escadas, código de acesso, etc. Quando seus clientes procuram seus documentos, eles precisam de todas as informações.",
		agency_setup_tel: "Telefone",
		agency_setup_tel_placeholder: "",
		agency_setup_tel_help: "O número de telefone a ser usado para conversar com seus clientes.",
		agency_setup_email: "Endereço de e-mail",
		agency_setup_email_placeholder: "",
		agency_setup_email_help: "O endereço de e-mail a ser usado para conversar com seus clientes. Por favor, digite outro se não é o seu endereço de registro de Hierø.",
		agency_setup_save: "Salvar",
		agency_setup_error_required_field: "Campo obrigatório",
		agency_setup_error_invalid_email: "O endereço de e-mail não é válido.",
		agency_setup_error_valid_address: "Por favor, escolha uma dos endereços sugeridos no menu suspenso.",

		agency_address_not_found: "Seu endereço não foi encontrado?",
    agency_address_title: "Entrar manualmente seus detalhes",
    agency_address_address: "Endereço :",
    agency_address_number: "N.°",
    agency_address_street: "Rua",
    agency_address_code: "Código postal",
    agency_address_city: "Cidade",
    agency_address_country: "País",
    agency_address_coords: "Coordenadas GPS :",
    agency_address_coords_lat: "Latitude",
    agency_address_coords_long: "Longitude",
    agency_address_cancel: "Cancelar",
    agency_address_validate: "Validar",

		section_agency_title: "Minha agência",
		section_services_title: "Meus serviços",
		section_account_title: "Minha conta",
		section_support_title: "Suporte",
		section_tasks_title: "Minhas missões",
		section_task_title: "Realizar uma missão",
		section_document_title: "Traduzir um documento",
		notifications_new_task: "Você tem uma nova missão!",
		notifications_task_confirmed: "A missão foi confirmada. Você pode iniciar a tradução.",
		notifications_task_validated: "O cliente validou a missão.",
		tasks_filters: "Filtros",
		tasks_sent_on: "Enviado em",
		tasks_src_lang: "Língua original",
		tasks_src_country: "País de origem",
		tasks_dst_country: "Traduzir para",
		tasks_state: "Progressão",
		tasks_price: "Preço (s/IVA)",
		state_cancelled: "Cancelado pelo cliente",
		state_waiting_for_translator: "Novo",
		state_waiting_for_payment: "À espera de pagamento",
		state_refused_by_translator: "Missão recusada",
		state_translating: "Tradução pendente",
		state_waiting_for_client_validation: "Acabada. Aguardando a verificação pelo cliente.",
		state_validated: "Validado pelo cliente",
		prestation_breadcrumb_tasks: "Minhas missões",
		prestation_order_number: "Número do pedido :",
		prestation_next_action_title: "Próximas etapas",
		prestation_next_action_accept_order: "Aceitar o pedido",
		prestation_next_action_accept_order_expl: "Clique aqui para aceitar o pedido. Depois, o cliente procederá ao pagamento. Após o pagamento ser processado, você pode iniciar a tradução.",
		prestation_next_action_reject_order: "Recusar o pedido",
		prestation_next_action_reject_order_expl: "Clique aqui para recusar o pedido.",
		prestation_next_action_rejected: "Você recusou o pedido.",
		prestation_next_action_waiting_for_payment: "Aguardando o pagamento pelo cliente.",
		prestation_next_action_translate_expl: "Pode iniciar a tradução de cada documento do pedido, clicando no botão 'Traduzir'. Uma vez que todos os documentos estão prontos, clique no botão 'Enviar para o cliente'. Para qualquer pergunta o precisão, você pode conversar diretamente com o cliente por e-mail.",
		prestation_next_action_translate_all_done_expl: "Você terminou todos os documentos neste pedido.",
		prestation_next_action_translate_confirm: "Envíar ao cliente para verificaação.",
		prestation_next_action_waiting_for_validation: "O cliente está validando a tradução.",
		prestation_next_action_validated: "Pedido confirmado pelo cliente.",
		prestation_order_details: "Detalhes do pedido :",
		prestation_order_from: "De",
		prestation_order_to: "Para",
		prestation_order_country_origin: "País de origem :",
		prestation_order_document_count: "Documentos para traduzir :",
		prestation_order_price: "Preço (s/IVA):",
		prestation_order_date: "Pedido criado em :",
		prestation_order_documents_title: "Documentos para traduzir :",
		prestation_order_document_type: "Tipo de documento",
		prestation_order_document_originals: "Originais",
		prestation_order_document_translated: "Traduzido(s)",
		prestation_order_document_not_yet_translated: "Ainda não tem traduçao",
		prestation_order_document_translate: "Traduzir !",
		prestation_order_messaging_title: "Mensagem eletrônica",
		prestation_order_messaging_send: "Enviar",
		prestation_order_messaging_client: "Cliente :",
		prestation_order_messaging_me: "Eu:",
		prestation_order_confirm_ok: "Ok",
		prestation_order_confirm_cancel: "Cancelar",
		prestation_order_validate_title: "Confirmar",
		prestation_order_validate_msg: "Tem a certeza de que deseja confirmar este pedido? O cliente receberá uma notificação e terá acesso aos documentos traduzidos.",
		prestation_order_accept_title: "Confirmar",
		prestation_order_accept_msg: "Tem a certeza de que deseja aceitar este pedido?",
		prestation_order_refuse_title: "Confirmar",
		prestation_order_refuse_msg: "Tem a certeza de que deseja recusar este pedido?",
		prestation_document_validate: "Confirmar a tradução !",
		prestation_document_show_original: "Ver o original em outra aba :",
		prestation_document_edit: "Editor de documento",
		prestation_document_edit_expl: "O texto no campo acima está sublinhado no documento abaixo. Ao entrar  o texto traduzido no campo, o doumento será atualizado automaticamente. Utilize as setas para navegar o documento.",
		prestation_document_get_browser: "Você não vê o PDF? Use um navegador recente como",
		prestation_document_button_reset: "Reiniciar o documento",
		prestation_document_confirm_title: "Confirmar",
		prestation_document_confirm_message: "Tem a certeza de que deseja reiniciar a tradução? Todas as modificações serão perdidas !",
		prestation_document_confirm_oui: "Sim",
		prestation_document_confirm_non: "Não",
		account_expl: "Indique suas informações de contacto, para contatá-lo se necessário.",
		account_save: "Salvar",
		account_surname: "Sobrenome",
		account_surname_placeholder: "",
		account_surname_help: "",
		account_name: "Nome",
		account_name_placeholder: "",
		account_name_help: "",
		account_telephone: "Telefone",
		account_telephone_placeholder: "",
		account_telephone_help: "",
		account_error_required: "Campo obrigatório",
		agency_expl_1_title: "Nome comercial",
		agency_expl_1_expl: "Especifique o nome da sua empresa para que seus clientes possam encontrá-lo.",
		agency_expl_2_title: "Geolocalização",
		agency_expl_2_expl: "A aplicação requer suas informações de contato para entrar em contacto com os clientes mais próximos de você.",
		agency_expl_3_title: "Ativar sua conta",
		agency_expl_3_expl: "Seu perfil ficará visível on-line, uma vez a informação salvada.",
		services_add_button: "Adicionar uma nova oferta…",
		services_none_yet: "Para estar visível na plataforma Hierø, especifique os tipos de serviços que você ofrece.",
		services_add_expl: "Por favor, especifique as línguas que você practica, e clique em 'Adicionar'.",
		services_add_src_lang: "Língua original",
		services_dst_lang: "Para",
		services_add: "Adicionar !",
		services_error_add: "Essa combinação de línguas ainda não é possível com o Hierø. Contacte-nos para mais informações!",
		services_dont_see_title: "Não consigo ver minhas línguas.",
		services_dont_see_response: "Hierø facilita sua tradução, fornecendo modelos para os documentos prontos para uso. Desenvolvemos continuamente nossos documentos. Contacte-nos por <a href='mailto:contact@hiero-support.com'> contact@hiero-support.com </a> para ter línguas adicionais.",
		services_list_title: "Sua lista de serviços",
		services_expl: "Clique em cada serviço para personalizar os documentos que você aceita.  Pode recusar un tipo de documento, desativando o botão, o especificar seu preço para tratar este tipo de documento.",
		services_list_active: "Ativado",
		services_list_country: "País",
		services_list_document_type: "Tipo de documento",
		services_list_average_price: "Preço médio (s/IVA)",
		services_list_your_price: "Seu preço (s/IVA)",
		services_list_your_price_reset: "Reiniciar",
		services_list_delete: "Remover",
		support_title: "Nossas instalações",
		support_company: "By'Me Univers",
		support_addr1: "15 Avenue de Norvège",
		support_addr2: "91140 VILLEBON-SUR-YVETTE",
		support_addr3: "França",
		support_contact: "Contacto",
		support_tel: "Tel. : (+33) 1 80 38 30 46",
		support_email: "E-mail : <a href='mailto:contact@hiero-support.com'>contact@hiero-support.com</a>",
		not_found_title: "Elemento não encontrado",
		not_found_subtitle: "A página solicitada não existe.",
		not_found_action: "Contactar <a href='mailto:contact@hiero-support.com'>o suporte</a> se o problema persistir.",
		not_found_home: "Início",
		AF: "Afeganistão",
		ZA: "África do Sul",
		AL: "Albânia",
		DZ: "Argélia",
		DE: "Alemanha",
		AD: "Andorra",
		AO: "Angola",
		AI: "Anguilla",
		AQ: "Antartica",
		AG: "Antiga e Barbuda",
		AN: "Antilhas Neerlandesas",
		SA: "Arábia Saudita",
		AR: "Argentina",
		AM: "Arménia",
		AW: "Aruba",
		AU: "Austrália",
		AT: "Áustria",
		AZ: "Azerbaijão",
		BS: "Bahamas",
		BH: "Barém",
		BD: "Bangladexe",
		BB: "Barbados",
		BY: "Bielorrússia",
		BE: "Bélgica",
		BZ: "Belize",
		BJ: "Benim",
		BM: "Bermudas",
		BT: "Butão",
		BO: "Bolívia",
		BA: "Bósnia e Herzegovina",
		BW: "Botsuana",
		BR: "Brasil",
		BN: "Brunei",
		BG: "Bulagária",
		BF: "Burquina Faso",
		BI: "Burúndi",
		KH: "Camboja",
		CM: "Camarões",
		CA: "Canadá",
		CV: "Cabo Verde",
		EA: "Ceuta e Melilla",
		CL: "Chile",
		CN: "China",
		CY: "Chipre",
		CO: "Colômbia",
		KM: "Comores",
		CG: "Congo-Brazzaville",
		KP: "Coreia do Norte",
		KR: "Coreia do Sul",
		CR: "Costa Rica",
		CI: "Costa do Marfim",
		HR: "Croácia",
		CU: "Cuba",
		DK: "Dinamarca",
		DG: "Diego Garcia",
		DJ: "Jibuti",
		DM: "Dominica",
		EG: "Egito",
		SV: "El Salvador",
		AE: "Emirados Árabes Unidos",
		EC: "Equador",
		ER: "Eritreia",
		ES: "Espanha",
		EE: "Estónia",
		VA: "Estado da Cidade do Vaticano",
		FM: "Estados Federados da Micronésia",
		US: "Estados Unidos",
		ET: "Etiópia",
		FJ: "Fiji",
		FI: "Finlândia",
		FR: "França",
		GA: "Gabão",
		GM: "Gâmbia",
		GE: "Geórgia",
		GS: "Ilhas Geórgia do Sul e Sandwich do Sul",
		GH: "Gana",
		GI: "Gibraltar",
		GR: "Grécia",
		GD: "Granada",
		GL: "Gronelândia",
		GP: "Guadalupe",
		GU: "Guam",
		GT: "Guatemala",
		GG: "Guernsey",
		GN: "Guiné",
		GQ: "Guiné Equatorial",
		GW: "Guiné-Bissau",
		GY: "Guiana",
		GF: "Guiana Francesa",
		HT: "Haiti",
		HN: "Honduras",
		HU: "Hungria",
		BV: "Ilha Bouvet",
		CX: "Ilha Christmas",
		CP: "Ilha Clipperton",
		AC: "Ilha de Ascensão",
		IM: "Ilha de Man",
		NF: "Ilha Norfolk",
		AX: "Ilhas de Aland",
		KY: "Ilhas Caimão",
		IC: "Ilhas Canárias",
		CC: "Ilhas Cocos (Keeling)",
		CK: "Ilhas Cook",
		FO: "Ilhas Feroe",
		HM: "Ilha Heard e Ilhas McDonald",
		FK: "Ilhas Malvinas",
		MP: "Ilhas Marianas Setentrionais",
		MH: "Ilhas Marechal",
		UM: "Ilhas Menores Distantes dos Estados Unidos",
		SB: "Ilhas Salomão",
		TC: "Ilhas Turcas e Caicos",
		VG: "Ilhas Virgens Britânicas",
		VI: "Ilhas Virgens Americanas",
		IN: "India",
		ID: "Indonésia",
		IQ: "Iraque",
		IR: "Irão",
		IE: "Irlanda",
		IS: "Islândia",
		IL: "Israel",
		IT: "Itália",
		JM: "Jamaica",
		JP: "Japão",
		JE: "Jersey",
		JO: "Jordânia",
		KZ: "Cazaquistão",
		KE: "Quénia",
		KG: "Quirguistão",
		KI: "Kiribati",
		KW: "Cuaite",
		LA: "Laus",
		LS: "Lesoto",
		LV: "Letónia",
		LB: "Líbano",
		LR: "Libéria",
		LY: "Líbia",
		LI: "Listenstaine",
		LT: "Lituânia",
		LU: "Luxemburgo",
		MK: "Macedónia",
		MG: "Madagáscar",
		MY: "Malásia",
		MW: "Maláui",
		MV: "Maldivas",
		ML: "Mali",
		MT: "Malta",
		MA: "Marrocos",
		MQ: "Martinica",
		MU: "Maurícia",
		MR: "Mauritânia",
		YT: "Maiote",
		MX: "México",
		MD: "Moldávia",
		MC: "Mónaco",
		MN: "Mongólia",
		ME: "Montenegro",
		MS: "Montserrat",
		MZ: "Moçambique",
		MM: "Mianmar",
		NA: "Namíbia",
		NR: "Nauru",
		NP: "Nepal",
		NI: "Nicarágua",
		NE: "Níger",
		NG: "Nigéria",
		NU: "Niue",
		NO: "Noruega",
		NC: "Nova Caledónia",
		NZ: "Nova Zelândia",
		OM: "Omã",
		UG: "Uganda",
		UZ: "Uzbequistão",
		PK: "Paquistão",
		PW: "Palau",
		PA: "Panamá",
		PG: "Papua Nova Guiné",
		PY: "Paraguai",
		NL: "Países Baixos",
		PE: "Peru",
		PH: "Filipinas",
		PN: "Ilhas Pitcairn",
		PL: "Polónia",
		PF: "Polinésia Francesa",
		PR: "Porto Rico",
		PT: "Portugal",
		QA: "Catar",
		HK: "R.A.E. de Hong Kong da República Popular da China",
		MO: "R.A.E. de Macau da República Popular da China",
		QO: "Ocenia Remoto",
		CF: "República Centro-Africana",
		CD: "República Democrática do Congo",
		DO: "República Dominicana",
		CZ: "República Checa",
		RE: "Reunião",
		RO: "Roménia",
		GB: "Reino Unido",
		RU: "Rússia",
		RW: "Ruanda",
		EH: "Saara Ocidental",
		BL: "São Bartolomeu",
		KN: "São Cristóvão e Neves",
		SM: "São Marinho",
		MF: "Ilha de São Martinho",
		PM: "São Pedro e Miquelão",
		VC: "São Vicente e Granadinas",
		SH: "Ilha Santa Helena",
		LC: "Santa Lúcia",
		WS: "Samoa Wúcia",
		AS: "Samoa Americana",
		ST: "São Tomé e Príncipe",
		SN: "Senegal",
		RS: "Sérvia",
		CS: "Sérvia e Montenegro",
		SC: "Seicheles",
		SL: "Serra Leoa",
		SG: "Singapura",
		SK: "Eslóvaquia",
		SI: "Eslóvenia",
		SO: "Somália",
		SD: "Sudã",
		LK: "Sri Lanca",
		SE: "Suécia",
		CH: "Suíça",
		SR: "Suriname",
		SJ: "Svalbard e Jan Mayen",
		SZ: "Suazilândia",
		SY: "Síria",
		TJ: "Tajiquistão",
		TW: "Taiwan",
		TZ: "Tanzânia",
		TD: "Chade",
		TF: "Terras Austrais e Antárticas Francesas",
		IO: "Território Britânico do Oceano Índico",
		PS: "Territórios palestinianos",
		TH: "Tailândia",
		TL: "Timor-Leste",
		TG: "Togo",
		TK: "Tokelau",
		TO: "Tonga",
		TT: "Trinidade e Tobago",
		TA: "Tristão da Cunha",
		TN: "Tunísia",
		TM: "Turcomenistão",
		TR: "Turquia",
		TV: "Tuvalu",
		UA: "Ucrânia",
		EU: "União Europeia",
		UY: "Uruguai",
		VU: "Vanuatu",
		VE: "Venezuela",
		VN: "Vietname",
		WF: "Wallis e Futuna",
		YE: "Iemen",
		ZM: "Zâmbia",
		ZW: "Zimbábue",
	},
};

