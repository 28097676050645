var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { EnumTraducteurServiceExceptionType } from '../interfaces/ITraducteurService';
var FIREBASE_COLLECTION = 'traducteur_services';
var TraducteurService = /** @class */ (function () {
    function TraducteurService(translator, docId, data) {
        this._traducteur = translator;
        this._docId = docId;
        this._data = data;
    }
    Object.defineProperty(TraducteurService.prototype, "Id", {
        get: function () {
            return this._docId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TraducteurService.prototype, "data", {
        get: function () {
            return this._data;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TraducteurService.prototype, "Traducteur", {
        get: function () {
            return this._traducteur;
        },
        enumerable: true,
        configurable: true
    });
    /* async addDocumentType(docTypeId: string, price: number) {
  
      const cleanPrice = parseFloat(<any>price);
  
      if (!docTypeId || !cleanPrice || cleanPrice < 0) {
        throw Error('Invalid document');
      }
  
  
      // Check for duplicates
      const current = this._data.types.find(
        (type) => {
          return type.documentTypeId === docTypeId;
        }
      );
  
      if (current) {
        throw Error('This document already exists in the list');
      }
  
      // Add
      this._data.types = this._data.types.concat({
        documentTypeId: docTypeId,
        price: cleanPrice,
        exceptions: []
      });
  
      // Save
      await this._traducteur.User.DB.collection(FIREBASE_COLLECTION).doc(this._docId).update({
        types: this._data.types
      });
    }*/
    TraducteurService.prototype.addException = function (templateId, type, price) {
        return __awaiter(this, void 0, void 0, function () {
            var cleanprice, excepFiltered, exceptions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!templateId || !type) {
                            throw Error('Invalid exception');
                        }
                        cleanprice = 0;
                        if (type === EnumTraducteurServiceExceptionType.DIFFERENT_PRICE) {
                            cleanprice = parseFloat(price);
                        }
                        excepFiltered = this._data.exceptions.filter(function (exc) { return exc.templateId !== templateId; });
                        exceptions = excepFiltered.concat({
                            type: type,
                            templateId: templateId,
                            priceHT: cleanprice
                        });
                        return [4 /*yield*/, this._traducteur.User.DB.collection(FIREBASE_COLLECTION).doc(this._docId).update({
                                exceptions: exceptions
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TraducteurService.prototype.removeException = function (templateId) {
        return __awaiter(this, void 0, void 0, function () {
            var exceptions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!templateId) {
                            throw Error('Invalid exception');
                        }
                        exceptions = this._data.exceptions.filter(function (exc) {
                            return (exc.templateId !== templateId);
                        });
                        return [4 /*yield*/, this._traducteur.User.DB.collection(FIREBASE_COLLECTION).doc(this._docId).update({
                                exceptions: exceptions
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TraducteurService.prototype.delete = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._traducteur.User.DB.collection(FIREBASE_COLLECTION).doc(this._docId).delete()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return TraducteurService;
}());
export { TraducteurService };
var TraducteurServiceList = /** @class */ (function () {
    function TraducteurServiceList(translator) {
        var _this = this;
        this._traducteur = translator;
        this._services = [];
        this._servicesSubject = new BehaviorSubject(this._services);
        var query = translator.User.DB.collection(FIREBASE_COLLECTION).where('traducteurId', '==', translator.Id);
        query.onSnapshot({
            next: function (snapshot) {
                _this._services = [];
                snapshot.forEach(function (result) {
                    var data = result.data();
                    var pds = new TraducteurService(translator, result.id, data);
                    _this._services.push(pds);
                });
                // Signal new data to the list
                _this._servicesSubject.next(_this._services);
            },
            error: function (error) {
                // console.warn(error);
            }
        });
        try {
            query.get();
        }
        catch (err) {
            console.warn(err);
        }
    }
    TraducteurServiceList.prototype.WatchTraducteurServiceList = function (observer) {
        return this._servicesSubject.subscribe(observer);
    };
    TraducteurServiceList.prototype.addService = function (srcLangISO639, dstLangISO639) {
        return __awaiter(this, void 0, void 0, function () {
            var prest, exist;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        prest = {
                            traducteurId: this._traducteur.Id,
                            srcLanguageIso639: srcLangISO639,
                            destLanguageIso639: dstLangISO639,
                            exceptions: []
                        };
                        exist = this._services.find(function (service) {
                            return (service.data.srcLanguageIso639 === srcLangISO639 && service.data.destLanguageIso639 === dstLangISO639);
                        });
                        if (exist) {
                            throw Error('This service already exists');
                        }
                        // TODO: Validate
                        console.log(prest);
                        // Should fire the snapshot callback
                        return [4 /*yield*/, this._traducteur.User.DB.collection(FIREBASE_COLLECTION).add(prest)];
                    case 1:
                        // Should fire the snapshot callback
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TraducteurServiceList.prototype.removeService = function (prestationId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._traducteur.User.DB.collection(FIREBASE_COLLECTION).doc(prestationId).delete()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /** Instantiate a new Translator class */
    TraducteurServiceList.Init = function (translator) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, Promise.resolve(new TraducteurServiceList(translator))];
            });
        });
    };
    TraducteurServiceList.HasServices = function (translator) {
        return __awaiter(this, void 0, void 0, function () {
            var query, results;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = translator.User.DB.collection(FIREBASE_COLLECTION).where('traducteurId', '==', translator.Id);
                        return [4 /*yield*/, query.get()];
                    case 1:
                        results = _a.sent();
                        if (results) {
                            return [2 /*return*/, (results.size > 0)];
                        }
                        else {
                            return [2 /*return*/, false];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return TraducteurServiceList;
}());
export { TraducteurServiceList };
