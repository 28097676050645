var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EnumPrestationState } from '../interfaces/IPrestation';
import { Prestation } from '../prestation/Prestation';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs';
var FIREBASE_PRESTATION_COLLECTION = 'prestations';
var TraducteurOrderParam = /** @class */ (function () {
    function TraducteurOrderParam() {
    }
    return TraducteurOrderParam;
}());
export { TraducteurOrderParam };
/**
 * Use this class to list, filter, order and monitor the orders for a specific translator.
 * Note: this class will recover ALL records for this translator, filter and order them client side.
 * This will most likely need to be optimised once we know more about the general usage of this class.
 * Remember to call cleanup before destroying this object to avoid memory leaks.
 */
var TraducteurPrestations = /** @class */ (function () {
    function TraducteurPrestations(traducteur, query, initSnap, watch, states, order) {
        var _this = this;
        this._totalFilteredUnpagedCount = 0;
        this._page = 0;
        this._pageSize = 10;
        this._states = states;
        this._order = order;
        this._traducteur = traducteur;
        this.reconstruct(initSnap);
        this._listSubject = new BehaviorSubject(this.All);
        this._prestationChangeStateSubject = new Subject();
        if (watch) {
            this._stopListening = query.onSnapshot({
                next: function (snapshot) {
                    // Go through all changes
                    snapshot.docChanges().forEach(function (change) {
                        // Get a corresponding old one to be able to see if it exists or not
                        var old = _this._prestationMap.get(change.doc.id);
                        var prestation = change.doc.data();
                        // SIGNAL NEWLY ADDED
                        if (change.type === 'added' && !old) {
                            if (prestation.state === EnumPrestationState.WaitingForTranslator) {
                                _this._prestationChangeStateSubject.next({
                                    prestation: prestation,
                                    from: EnumPrestationState.Defining,
                                    to: prestation.state
                                });
                            }
                        }
                        else if (change.type === 'modified') {
                            // SIGNAL MODIFIED
                            if (old) {
                                if (prestation.state !== old.Data.state) {
                                    _this._prestationChangeStateSubject.next({
                                        prestation: prestation,
                                        from: old.Data.state,
                                        to: prestation.state
                                    });
                                }
                            }
                        }
                    });
                    _this.reconstruct(snapshot);
                },
                error: function (err) {
                }
            });
        }
    }
    Object.defineProperty(TraducteurPrestations.prototype, "Count", {
        get: function () {
            return this._filteredArr.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TraducteurPrestations.prototype, "TotalCount", {
        /**
         * Gets the number of filtered items before paging
         */
        get: function () {
            return this._totalFilteredUnpagedCount;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TraducteurPrestations.prototype, "All", {
        /**
         * Get a copy of your query
         */
        get: function () {
            return this._filteredArr.slice();
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Update your query here, it will not redownload the data, but will fire the subscription with re-ordered data (if there is one in place).
     * Otherwise get the data using All.
     * @param states
     * @param order
     */
    TraducteurPrestations.prototype.updateQuery = function (states, order, page, pageSize) {
        this._states = states;
        this._order = order;
        this._page = page;
        this._pageSize = pageSize;
        // Filter and order
        this._filteredArr = this.getWithQuery(this._states, this._order, page, pageSize);
        if (this._listSubject) {
            this._listSubject.next(this.All);
        }
    };
    TraducteurPrestations.prototype.getWithQuery = function (states, order, page, pageSize) {
        if (page === void 0) { page = 0; }
        if (pageSize === void 0) { pageSize = 10; }
        // Filter by selected states
        var result = [];
        if (states && states.length > 0) {
            var stateSet_1 = new Set();
            states.forEach(function (state) {
                stateSet_1.add(state);
            });
            result = this._prestationArr.filter(function (prestation) {
                return stateSet_1.has(prestation.Data.state);
            });
        }
        else {
            result = this._prestationArr;
        }
        // Order
        if (order && order.length > 0) {
            var _loop_1 = function (i) {
                var orderParam = order[i];
                var sorted = result.sort(function (a, b) {
                    var res = 0;
                    if (typeof (a.Data[orderParam.column]) === 'string') {
                        res = a.Data[orderParam.column].localeCompare(b.Data[orderParam.column]);
                    }
                    else {
                        res = a.Data[orderParam.column] - b.Data[orderParam.column];
                    }
                    if (orderParam.desc) {
                        res = res * -1;
                    }
                    return res;
                });
                result = sorted;
            };
            for (var i = order.length - 1; i >= 0; --i) {
                _loop_1(i);
            }
        }
        // Save the total filtered count
        this._totalFilteredUnpagedCount = result.length;
        // Paging
        var startPage = page * pageSize;
        result = result.slice(startPage, startPage + pageSize);
        return result;
    };
    /**
     * Get a specific order, given its Id
     * @param id
     */
    TraducteurPrestations.prototype.getPrestation = function (id) {
        return this._prestationMap.get(id);
    };
    TraducteurPrestations.prototype.reconstruct = function (snap) {
        var _this = this;
        this._prestationMap = new Map();
        this._prestationArr = [];
        snap.forEach(function (doc) {
            var prestRaw = doc.data();
            var prestation = new Prestation(_this._traducteur.User, true, doc.ref, doc.id, prestRaw);
            _this._prestationMap.set(doc.id, prestation);
            _this._prestationArr.push(prestation);
        });
        // Filter and order
        this._filteredArr = this.getWithQuery(this._states, this._order);
        if (this._listSubject) {
            this._listSubject.next(this.All);
        }
    };
    /**
     * Subscribe for changes to this list
     * @param observer
     */
    TraducteurPrestations.prototype.WatchList = function (observer) {
        return this._listSubject.subscribe(observer);
    };
    TraducteurPrestations.prototype.WatchEvents = function (observer) {
        return this._prestationChangeStateSubject.subscribe(observer);
    };
    /**
     * Make sure you call this function to avoid memory leaks.
     */
    TraducteurPrestations.prototype.cleanup = function () {
        if (this._stopListening) {
            this._stopListening();
            this._stopListening = null;
        }
    };
    /**
     * Create the list object. This will get the initial list and setup the watcher if required.
     * @param traducteur The translator for whom we are getting the list
     * @param watch If true, a watch will be placed on this list, and automatic updates will be transmitted via the Watch* subscriptions
     * @param states A list of states that you want to filter the list by
     * @param order The order in which you wish to display the results
     */
    TraducteurPrestations.Init = function (traducteur, watch, states, order) {
        return __awaiter(this, void 0, void 0, function () {
            var query, snapshot;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = traducteur.User.DB.collection(FIREBASE_PRESTATION_COLLECTION)
                            .where('traducteurId', '==', traducteur.Id);
                        return [4 /*yield*/, query.get()];
                    case 1:
                        snapshot = _a.sent();
                        return [2 /*return*/, new TraducteurPrestations(traducteur, query, snapshot, watch, states, order)];
                }
            });
        });
    };
    return TraducteurPrestations;
}());
export { TraducteurPrestations };
