import { Component, NgZone } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ESignupType } from '../../../../../common/src/bdd/user/AuthService';
import { HieroBDD } from '../../../services/hierobdd.service';
import { SubmitForm, ISubmitFormInputErrors } from '../../../../../common/src/utility/forms/submitform.class';
import { LocalisationService } from '../../../../../common/src/modules/localisation/localisation.service';

@Component({
  templateUrl: './login.component.html'
})

export class LoginComponent {

  busy: boolean;

  loginForm: SubmitForm;

  constructor(
    private fb: FormBuilder,
    private hiero: HieroBDD,
    private router: Router,
    private zone: NgZone,
    private localisation: LocalisationService
  ) {
    this.createForm();
  }


  createForm() {
    this.loginForm = new SubmitForm(
      this.fb,
      [
        {
          name: 'email',
          value: '',
          validators: [Validators.required, Validators.email],
          type: 'email',
          title: this.localisation.localise('login_main_email'),
          autocomplete: 'email',
          placeholder:  this.localisation.localise('login_main_email_placeholder'),
          help: this.localisation.localise('login_main_email_help'),
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: this.localisation.localise('login_error_required_field'),
            },
            <ISubmitFormInputErrors>{
              code: 'email',
              message: this.localisation.localise('login_error_invalid_email'),
            },
            <ISubmitFormInputErrors>{
              code: 'isEmail',
              message: this.localisation.localise('login_error_invalid_email'),
            },
            <ISubmitFormInputErrors>{
              code: 'notFound',
              message: this.localisation.localise('login_error_email_not_found'),
            }
          ]
        },
        {
          name: 'password',
          value: '',
          validators: [Validators.required],
          type: 'password',
          title: this.localisation.localise('login_main_password'),
          autocomplete: 'off',
          placeholder: this.localisation.localise('login_main_password_placeholder'),
          help: this.localisation.localise('login_main_password_help'),
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: this.localisation.localise('login_error_required_field'),
            },
          ]
        }
      ],
      // Submit callback
      data => {
        this.busy = true;
        let prom = this.hiero.Auth.login({
          email: data.email,
          password: data.password,
          type: ESignupType.EmailPassword
        });
        
        return prom;
      },

      // Success callback
      () => {
        // What to do with login success ?
        console.log('Success.');
        this.router.navigate(['/', 'app']);
      },

      // Fail callback
      err => {
        // What to do with login failuer
        this.busy = false;
      },

      // Changes callback
      null
    );
  }

}
