var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { EmptyTraducteurProfile } from '../interfaces/ITraducteur';
import { TraducteurServiceList } from './TraducteurService';
var FIREBASE_TRADUCTEUR_COLLECTION = 'traducteurs';
/**
 * Represents a translator. This class handles receiving and updating data about the translator with firebase.
 * This class can only be created by passing a valid User object.
 */
var Traducteur = /** @class */ (function () {
    function Traducteur(user, docId) {
        var _this = this;
        this._user = user;
        this._docId = docId;
        this._docRef = user.DB.collection(FIREBASE_TRADUCTEUR_COLLECTION).doc(docId);
        this._profileSubject = new BehaviorSubject(null);
        this._docRef.onSnapshot({
            next: function (snapshot) {
                _this._profileSubject.next(snapshot.data());
            },
            error: function (err) { }
        });
    }
    Object.defineProperty(Traducteur.prototype, "User", {
        get: function () {
            return this._user;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Traducteur.prototype, "Id", {
        get: function () {
            return this._docId;
        },
        enumerable: true,
        configurable: true
    });
    Traducteur.prototype.getTraducterServices = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!this._traducteurServiceList) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, TraducteurServiceList.Init(this)];
                    case 1:
                        _a._traducteurServiceList = _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/, this._traducteurServiceList];
                }
            });
        });
    };
    Traducteur.prototype.hasServices = function () {
        return __awaiter(this, void 0, void 0, function () {
            var hasResult;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, TraducteurServiceList.HasServices(this)];
                    case 1:
                        hasResult = _a.sent();
                        return [2 /*return*/, hasResult];
                }
            });
        });
    };
    Traducteur.prototype.sync = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._docRef.get()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /** Watch for changes to the translator profile. */
    Traducteur.prototype.WatchProfile = function (observer) {
        return this._profileSubject.subscribe(observer);
    };
    /** Update the translator profile */
    Traducteur.prototype.UpdateProfile = function (profile) {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        /* validatedProfile = await TraducteurProfileSchema.validate(profile,
                          {
                            strict: false,
                            abortEarly: false,
                            stripUnknown: true
                          }
                        );
                        */
                        return [4 /*yield*/, this._docRef.update(profile)];
                    case 1:
                        /* validatedProfile = await TraducteurProfileSchema.validate(profile,
                          {
                            strict: false,
                            abortEarly: false,
                            stripUnknown: true
                          }
                        );
                        */
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        return [2 /*return*/, Promise.reject(err_1)];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /** Instantiate a new Translator class */
    Traducteur.Init = function (user) {
        return __awaiter(this, void 0, void 0, function () {
            var query, snapshot, docId, traducteur, docRef, trad, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = user.DB.collection(FIREBASE_TRADUCTEUR_COLLECTION).where('uid', '==', user.Id);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 8, , 9]);
                        return [4 /*yield*/, query.get()];
                    case 2:
                        snapshot = _a.sent();
                        docId = null;
                        if (!snapshot.empty) return [3 /*break*/, 5];
                        traducteur = EmptyTraducteurProfile;
                        traducteur.uid = user.Id;
                        return [4 /*yield*/, user.DB.collection(FIREBASE_TRADUCTEUR_COLLECTION).add(traducteur)];
                    case 3:
                        docRef = _a.sent();
                        return [4 /*yield*/, user.GetIDToken()];
                    case 4:
                        _a.sent();
                        docId = docRef.id;
                        return [3 /*break*/, 6];
                    case 5:
                        docId = snapshot.docs[0].id;
                        _a.label = 6;
                    case 6:
                        trad = new Traducteur(user, docId);
                        return [4 /*yield*/, trad.sync()];
                    case 7:
                        _a.sent();
                        return [2 /*return*/, trad];
                    case 8:
                        err_2 = _a.sent();
                        console.error(err_2);
                        throw err_2;
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    return Traducteur;
}());
export { Traducteur };
