/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-switch.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./app-switch.component";
var styles_AppSwitchComponent = [i0.styles];
var RenderType_AppSwitchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppSwitchComponent, data: {} });
export { RenderType_AppSwitchComponent as RenderType_AppSwitchComponent };
export function View_AppSwitchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "app-switch"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "app-sw-background"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "app-sw-bg-off": 0, "app-sw-bg-on": 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "app-sw-toggle"]], null, null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "app-sw-toggle-off": 0, "app-sw-toggle-on": 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "app-sw-background"; var currVal_1 = _ck(_v, 3, 0, !_co.isOn, _co.isOn); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "app-sw-toggle"; var currVal_3 = _ck(_v, 6, 0, !_co.isOn, _co.isOn); _ck(_v, 5, 0, currVal_2, currVal_3); }, null); }
export function View_AppSwitchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-switch", [], null, null, null, View_AppSwitchComponent_0, RenderType_AppSwitchComponent)), i1.ɵdid(1, 49152, null, 0, i3.AppSwitchComponent, [], null, null)], null, null); }
var AppSwitchComponentNgFactory = i1.ɵccf("app-switch", i3.AppSwitchComponent, View_AppSwitchComponent_Host_0, { isOn: "isOn", refresh: "refresh" }, { onToggled: "onToggled" }, []);
export { AppSwitchComponentNgFactory as AppSwitchComponentNgFactory };
