import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, NgZone, OnChanges, SimpleChanges } from '@angular/core';
import { PDFRender } from '../pdfpreview/pdfrender';
var PDFEditorComponent = /** @class */ (function () {
    function PDFEditorComponent(cdRef, zone) {
        this.cdRef = cdRef;
        this.zone = zone;
        this.update = new EventEmitter();
        this.pageIndex = 0;
        this.inputIndex = 0;
    }
    PDFEditorComponent.prototype.ngAfterViewInit = function () {
        this.load();
        this.cdRef.detectChanges();
    };
    PDFEditorComponent.prototype.ngOnChanges = function (changes) {
        if ('objectEntry' in changes) {
            this.load();
        }
    };
    PDFEditorComponent.prototype.load = function () {
        if (this.objectEntry) {
            this.pageIndex = 0;
            this.pageIndex = 0;
            this.currentInput = null;
            this.pdf = this.objectEntry.Data;
            this.pdfRender = this.objectEntry;
            this.nextElement(true);
        }
    };
    Object.defineProperty(PDFEditorComponent.prototype, "hasPrevious", {
        get: function () {
            return !(this.inputIndex === 0 && this.pageIndex === 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PDFEditorComponent.prototype, "hasNext", {
        get: function () {
            var nextEl = this.inputIndex + 1;
            var nextPage = this.pageIndex;
            if (nextEl >= this.pdf.pages[this.pageIndex].inputs.length) {
                nextEl = 0;
                nextPage = this.pageIndex + 1;
            }
            return (nextPage < this.pdf.pageCount && nextEl < this.pdf.pages[nextPage].inputs.length);
        },
        enumerable: true,
        configurable: true
    });
    PDFEditorComponent.prototype.nextElement = function (forceRender) {
        var _this = this;
        if (forceRender === void 0) { forceRender = true; }
        this.zone.run(function () {
            if (_this.currentInput) {
                _this.currentInput.fill = null;
                var nextEl = _this.inputIndex + 1;
                var nextPage = _this.pageIndex;
                if (nextEl >= _this.pdf.pages[_this.pageIndex].inputs.length) {
                    nextEl = 0;
                    nextPage = _this.pageIndex + 1;
                }
                if (nextPage < _this.pdf.pageCount && nextEl < _this.pdf.pages[nextPage].inputs.length) {
                    _this.inputIndex = nextEl;
                    _this.pageIndex = nextPage;
                    _this.currentInput = _this.pdf.pages[_this.pageIndex].inputs[_this.inputIndex];
                }
            }
            else {
                _this.currentInput = _this.pdf.pages[0].inputs[0];
            }
            _this.currentInput.fill = '#00acac';
            if (forceRender) {
                _this.pdfRender.renderToUriString();
            }
        });
    };
    PDFEditorComponent.prototype.previousElement = function (forceRender) {
        var _this = this;
        if (forceRender === void 0) { forceRender = true; }
        this.zone.run(function () {
            if (_this.currentInput) {
                _this.currentInput.fill = null;
                var nextEl = _this.inputIndex - 1;
                var nextPage = _this.pageIndex;
                if (nextEl < 0) {
                    nextPage = _this.pageIndex - 1;
                    if (nextPage >= 0) {
                        nextEl = _this.pdf.pages[nextPage].inputs.length - 1;
                    }
                    else {
                        nextEl = 0;
                        nextPage = 0;
                    }
                }
                if (nextPage < _this.pdf.pageCount && nextEl < _this.pdf.pages[nextPage].inputs.length) {
                    _this.inputIndex = nextEl;
                    _this.pageIndex = nextPage;
                    _this.currentInput = _this.pdf.pages[_this.pageIndex].inputs[_this.inputIndex];
                }
            }
            else {
                _this.currentInput = _this.pdf.pages[0].inputs[0];
            }
            _this.currentInput.fill = '#00acac';
            if (forceRender) {
                _this.pdfRender.renderToUriString();
            }
        });
    };
    PDFEditorComponent.prototype.onUpdate = function (input) {
        this.pdfRender.renderToUriString();
        this.update.emit(this.pdf);
    };
    return PDFEditorComponent;
}());
export { PDFEditorComponent };
