var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Traducteur } from '../../../common/src/bdd/traducteur/Traducteur';
import { TraducteurPrestations } from '../../../common/src/bdd/traducteur/TraducteurPrestations';
import { AuthService } from '../../../common/src/bdd/user/AuthService';
import { ERoleType } from '../../../common/src/bdd/user/User';
import { Config } from '../../../common/src/services/config.service';
import { GoogleGeo } from './google.services';
import { MessageNotifications } from '../../../common/src/bdd/messaging/MessageNotifications';
var HieroBDD = /** @class */ (function () {
    function HieroBDD(config, google) {
        var _this = this;
        this.config = config;
        this.google = google;
        // Initialize Firebase
        firebase.initializeApp(this.config.Environment.firebase);
        // Create the database object
        this._db = firebase.firestore();
        this._auth = firebase.auth();
        this._storage = firebase.storage();
        this._traducteurSubject = new BehaviorSubject(null);
        this._traducteurPresentSubject = new Subject();
        this._traducteurProfileSubject = new BehaviorSubject(null);
        this._traducteurPrestationsSubject = new BehaviorSubject(null);
        this._prestationEventSubject = new Subject();
        this._notifierMap = new Map();
        this._notifierSubscriptionMap = new Map();
        this._prestationMessageNotifiersSubject = new BehaviorSubject(this._notifierMap);
        // Setup the service provider
        this._authService = new AuthService(this._auth, this._db, ERoleType.traducteur);
        // Setup extra login steps:
        // 1/ Login automatically as a translator
        this._authService.extraLoginSteps.push(function (user) { return __awaiter(_this, void 0, void 0, function () {
            var traducteur, err_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, Traducteur.Init(user)];
                    case 1:
                        traducteur = _a.sent();
                        this._traducteurSubject.next(traducteur);
                        this._traducteurPresentSubject.next(traducteur);
                        // GLOBAL SUBSCRIBE TO PROFILE
                        traducteur.WatchProfile({
                            next: function (profile) {
                                _this._traducteurProfileSubject.next(profile);
                            }
                        });
                        // GLOBAL SUBSCRIBE TO PRESTATIONS 
                        TraducteurPrestations.Init(traducteur, true, [], [])
                            .then(function (prestations) {
                            _this._traducteurPrestationsSubject.next(prestations);
                            // Subscribe to the filtered prestation list (that is shown)
                            // This will create subscriptions on each shown prestation for the message queues that may exist
                            // on each prestation
                            _this._prestationListSubscription = prestations.WatchList({
                                next: function (newPrestations) {
                                    _this.handleNotifierUpdates(newPrestations);
                                }
                            });
                            // Unsubscribe the old one to prevent memory leaks
                            if (_this._prestationEventSubscription) {
                                _this._prestationEventSubscription.unsubscribe();
                            }
                            // Subscribe this one
                            _this._prestationEventSubscription = prestations.WatchEvents({
                                next: function (event) {
                                    _this._prestationEventSubject.next(event);
                                }
                            });
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        this._traducteurSubject.next(null);
                        this._traducteurPresentSubject.next(null);
                        throw err_1;
                    case 3: return [2 /*return*/];
                }
            });
        }); });
        // Set up a watch on user, if user becomes null, so does traducteur
        this._authService.WatchUser({
            next: function (user) {
                if (user == null) {
                    // User logs out
                    _this.handleNotifierUpdates([]);
                    var prestationList = _this._traducteurPrestationsSubject.value;
                    if (prestationList) {
                        prestationList.cleanup();
                    }
                    _this._traducteurPrestationsSubject.next(null);
                    // Unsubscribe listener for prestation list
                    if (_this._prestationListSubscription) {
                        _this._prestationListSubscription.unsubscribe();
                        _this._prestationListSubscription = null;
                    }
                    // Unsubscribe the old one to prevent memory leaks
                    if (_this._prestationEventSubscription) {
                        _this._prestationEventSubscription.unsubscribe();
                    }
                    _this._traducteurSubject.next(null);
                    _this._traducteurPresentSubject.next(null);
                }
            }
        });
        // Start listener
        this.Auth.Listen();
    }
    Object.defineProperty(HieroBDD.prototype, "DB", {
        get: function () {
            return this._db;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HieroBDD.prototype, "Auth", {
        get: function () {
            return this._authService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HieroBDD.prototype, "Storage", {
        get: function () {
            return this._storage;
        },
        enumerable: true,
        configurable: true
    });
    HieroBDD.prototype.WatchTraducteur = function (observer) {
        return this._traducteurSubject.subscribe(observer);
    };
    HieroBDD.prototype.WatchTraducteurPresent = function (observer) {
        return this._traducteurPresentSubject.subscribe(observer);
    };
    Object.defineProperty(HieroBDD.prototype, "Traducteur", {
        get: function () {
            return this._traducteurSubject.value;
        },
        enumerable: true,
        configurable: true
    });
    HieroBDD.prototype.WatchTraducteurProfile = function (observer) {
        return this._traducteurProfileSubject.subscribe(observer);
    };
    HieroBDD.prototype.WatchTraducteurPrestations = function (observer) {
        return this._traducteurPrestationsSubject.subscribe(observer);
    };
    HieroBDD.prototype.WatchPrestationEvent = function (observer) {
        return this._prestationEventSubject.subscribe(observer);
    };
    /////////// MESSAGE NOTIFICATIONS
    HieroBDD.prototype.handleNotifierUpdates = function (prestations) {
        var _this = this;
        if (!this._traducteurSubject.value) {
            return;
        }
        var notSet = new Set();
        prestations.forEach(function (prestation) {
            notSet.add(prestation.Id);
            if (!_this._notifierMap.has(prestation.Id)) {
                // Set to null, to mark the place
                _this._notifierMap.set(prestation.Id, null);
                _this._notifierSubscriptionMap.set(prestation.Id, null);
                MessageNotifications.InitForTranslatorPrestation(_this._traducteurSubject.value, prestation.Id)
                    .then(function (not) {
                    _this._notifierMap.set(prestation.Id, not);
                    _this._notifierSubscriptionMap.set(prestation.Id, not.WatchNotifications({
                        next: function (newNotification) {
                            _this._prestationMessageNotifiersSubject.next(_this._notifierMap);
                        }
                    }));
                });
            }
        });
        var toRemove = [];
        this._notifierMap.forEach(function (msgNot, key) {
            if (!notSet.has(key)) {
                // Signal for removal
                toRemove.push(key);
            }
        });
        toRemove.forEach(function (id) {
            var msgNot = _this._notifierMap.get(id);
            if (msgNot) {
                msgNot.cleanup();
            }
            var sub = _this._notifierSubscriptionMap.get(id);
            if (sub) {
                sub.unsubscribe();
            }
            _this._notifierMap.delete(id);
            _this._notifierSubscriptionMap.delete(id);
        });
    };
    HieroBDD.prototype.SetSeen = function (prestationId) {
        return __awaiter(this, void 0, void 0, function () {
            var not, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        not = this._notifierMap.get(prestationId);
                        if (!not) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, not.SetSeen()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        console.log(err_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    HieroBDD.prototype.GetNotifierFor = function (prestationId) {
        return this._notifierMap.get(prestationId);
    };
    HieroBDD.prototype.WatchPrestationMessageNotifiers = function (observer) {
        return this._prestationMessageNotifiersSubject.subscribe(observer);
    };
    return HieroBDD;
}());
export { HieroBDD };
