import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-switch',
  templateUrl: './app-switch.component.html',
  styleUrls: ['./app-switch.component.scss']
})
export class AppSwitchComponent {
  @Output() onToggled = new EventEmitter();
  @Input() isOn = false;
  @Input() refresh = false;

  toggle() {
    this.onToggled.emit(!this.isOn);
  }
}


