var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, OnChanges } from '@angular/core';
import { Traducteur } from '../../../../../common/src/bdd/traducteur/Traducteur';
import { Prestation } from '../../../../../common/src/bdd/prestation/Prestation';
import { MessageBox } from '../../../../../common/src/bdd/messaging/MessageBox';
import { HieroBDD } from '../../../services/hierobdd.service';
import { File } from '../../../../../common/src/bdd/prestation/File';
var AppChatComponent = /** @class */ (function () {
    function AppChatComponent(hiero) {
        this.hiero = hiero;
        this.messages = [];
        this.msgText = '';
        this.timeOutSubs = null;
    }
    // Init the message box
    AppChatComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.prestation.currentValue && changes.traducteur.currentValue) {
            // Clear timeout if there is one
            if (this.timeOutSubs) {
                clearTimeout(this.timeOutSubs);
                this.timeOutSubs = null;
            }
            if (this.messageBox) {
                this.messageBox.cleanup();
                this.messageBox = null;
            }
            // Init message box
            MessageBox.InitForTranslatorAndPrestation(changes.traducteur.currentValue, changes.prestation.currentValue)
                .then(function (messageBox) {
                _this.messageBox = messageBox;
                _this.messagesSub = _this.messageBox.WatchMessages({
                    next: function (messages) {
                        _this.messages = messages;
                        // TODO: Refine this to actual message, for now, if on this page, assume seen all!
                        _this.timeOutSubs = setTimeout(function () {
                            _this.hiero.SetSeen(_this.prestation.Id);
                        }, 5000);
                    }
                });
            });
        }
    };
    AppChatComponent.prototype.ngOnDestroy = function () {
        if (this.messageBox) {
            this.messageBox.cleanup();
            this.messagesSub.unsubscribe();
            this.messageBox = null;
        }
        if (this.timeOutSubs) {
            clearTimeout(this.timeOutSubs);
            this.timeOutSubs = null;
        }
    };
    AppChatComponent.prototype.send = function () {
        var clean = this.msgText.trim();
        if (clean.length === 0) {
            return;
        }
        this.msgText = clean;
        this.messageBox.AddMessage(this.prestation, clean);
        this.msgText = '';
    };
    AppChatComponent.prototype.onNewMessage = function (text) {
        this.msgText = text;
    };
    AppChatComponent.prototype.onKeydown = function (event) {
        if (event.key === 'Enter' || event.key === 'Return') {
            this.send();
        }
    };
    AppChatComponent.prototype.isMe = function (message) {
        return message.senderUid === this.traducteur.User.Id;
    };
    AppChatComponent.prototype.isNotMe = function (message) {
        return message.senderUid !== this.traducteur.User.Id;
    };
    AppChatComponent.prototype.openChatFile = function (message) {
        return __awaiter(this, void 0, void 0, function () {
            var fileObj, link, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        fileObj = new File(this.hiero.Storage, this.prestation.Data.uid, this.prestation.Data, 'chat', message.file);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, fileObj.GetDownloadLink()];
                    case 2:
                        link = _a.sent();
                        window.open(link, '_blank');
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        console.error(err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return AppChatComponent;
}());
export { AppChatComponent };
