var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ElementRef, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { EnumPrestationState } from '../../../../../common/src/bdd/interfaces/IPrestation';
import { LocalisationService } from '../../../../../common/src/modules/localisation/localisation.service';
import { HieroBDD } from '../../../services/hierobdd.service';
import { TitleExtractor } from '../../../../../common/src/utility/title_extractor.class';
import { IsValidProfile } from '../../verify/profile/profilecheck.component';
var AppBaseComponent = /** @class */ (function () {
    function AppBaseComponent(hiero, router, route, notifier, zone, localisation) {
        var _this = this;
        this.hiero = hiero;
        this.router = router;
        this.route = route;
        this.notifier = notifier;
        this.zone = zone;
        this.localisation = localisation;
        this.titles = [];
        this._titleExtractor = new TitleExtractor(route, function (key) {
            // Replace strings here if necessary
            return '';
        }, function (titles) {
            _this.titles = titles;
        });
        this._eventSubs = hiero.WatchPrestationEvent({
            next: function (event) {
                var msg = null;
                switch (event.to) {
                    case EnumPrestationState.WaitingForTranslator:
                        msg = localisation.localise('notifications_new_task');
                        break;
                    case EnumPrestationState.Translating:
                        msg = localisation.localise('notifications_task_confirmed');
                        break;
                    case EnumPrestationState.Validated:
                        msg = localisation.localise('notifications_task_validated');
                        break;
                }
                if (msg) {
                    _this.notifier.notify('success', msg);
                }
            }
        });
    }
    AppBaseComponent.prototype.getTitle = function () {
        if (this.titles && this.titles.length > 0) {
            var titleKey = this.titles[this.titles.length - 1].title;
            return this.localisation.localise(titleKey);
        }
        else {
            return '';
        }
    };
    AppBaseComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._subscription = this.hiero.Auth.WatchUser({
            next: function (user) {
                _this.user = user;
                if (_this.user) {
                    _this.user.WatchProfile({
                        next: function (profile) {
                            _this.profile = profile;
                        }
                    });
                }
            }
        });
        // ESSENTIAL: TRANSLATOR DETAILS
        this._translatorProfile = this.hiero.WatchTraducteurProfile({
            next: function (profile) {
                if (profile) {
                    _this.translator = profile;
                    if (!IsValidProfile(profile)) {
                        _this.router.navigate(['verify']);
                    }
                    else {
                        // Check if we have already defined some services.
                        _this._translatorSub = _this.hiero.WatchTraducteur({
                            next: function (translator) { return __awaiter(_this, void 0, void 0, function () {
                                var hasServices;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, translator.hasServices()];
                                        case 1:
                                            hasServices = _a.sent();
                                            this._translatorSub.unsubscribe();
                                            if (!hasServices) {
                                                this.router.navigate(['app', 'services']);
                                            }
                                            return [2 /*return*/];
                                    }
                                });
                            }); }
                        });
                    }
                }
            }
        });
        this._titleExtractor.update();
        this._routerSubs = this.router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                _this._titleExtractor.update();
            }
        });
    };
    AppBaseComponent.prototype.ngOnDestroy = function () {
        this._subscription.unsubscribe();
        this._eventSubs.unsubscribe();
        this._translatorProfile.unsubscribe();
        this._routerSubs.unsubscribe();
        if (this._translatorSub) {
            this._translatorSub.unsubscribe();
        }
    };
    AppBaseComponent.prototype.logout = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.hiero.Auth.logout()];
                    case 1:
                        _a.sent();
                        this.router.navigate(['compte', 'connexion']);
                        return [2 /*return*/];
                }
            });
        });
    };
    AppBaseComponent.prototype.toggleSidebar = function () {
        this.sidebar.nativeElement.style = "width: 30px";
    };
    return AppBaseComponent;
}());
export { AppBaseComponent };
