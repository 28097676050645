var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// import { HieroBDD } from '../../../../hiero/app/services/hierobdd.service';
import { Localisation } from '../../bdd/settings/Localisation';
import { Storage } from '../../utility/storage.class';
import * as PHRASES from './phrases.js';
import { BehaviorSubject } from 'rxjs';
import { Language } from '../../bdd/utility/Language';
var langCookieKey = 'hiero-lang';
var FIREBASE_SETTINGS_COLLECTION = 'settings';
var LocalisationService = /** @class */ (function () {
    function LocalisationService(
    // private hiero: HieroBDD
    ) {
        this._currentLanguageISO639 = 'fr';
        // Load local
        this._localisation = new Localisation(PHRASES);
        this.determineLanguage();
        this._availableLanguages = new BehaviorSubject(this.constructLocalisedList());
        // Load remote
        this.Init('i18n_traducteur');
    }
    Object.defineProperty(LocalisationService.prototype, "CurrentLanguageISO639", {
        get: function () {
            return this._currentLanguageISO639;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LocalisationService.prototype, "CurrentLanguageDictionary", {
        get: function () {
            if (!this._localisation) {
                return {};
            }
            return this._localisation.getDict(this._currentLanguageISO639);
        },
        enumerable: true,
        configurable: true
    });
    LocalisationService.prototype.constructLocalisedList = function () {
        var _this = this;
        var list = [];
        this._localisation.AvailableLanguages.forEach(function (iso639) {
            list.push({
                iso639: iso639,
                name: Language.GetNameFromISO639(iso639),
                current: iso639 === _this._currentLanguageISO639
            });
        });
        return list;
    };
    LocalisationService.prototype.determineLanguage = function () {
        var _this = this;
        // Try get last specified language or navigator language
        var cookieLang = Storage.GetValue(langCookieKey);
        if (!cookieLang) {
            var navLang_1 = navigator.language;
            this._localisation.AvailableLanguages.forEach(function (lang) {
                if (navLang_1.indexOf(lang) > -1) {
                    _this._currentLanguageISO639 = lang;
                }
            });
        }
        else {
            this._currentLanguageISO639 = cookieLang;
        }
    };
    LocalisationService.prototype.Init = function (service) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    LocalisationService.prototype.localise = function (key) {
        if (!this._localisation) {
            return '';
        }
        var clean = key.toLowerCase().trim();
        return this._localisation.localise(this._currentLanguageISO639, clean);
    };
    LocalisationService.prototype.changeLanguage = function (langCodeISO639) {
        this._currentLanguageISO639 = langCodeISO639;
        Storage.SetValue(langCookieKey, this._currentLanguageISO639, 5000);
        window.location.reload(true);
    };
    LocalisationService.prototype.WatchAvailableLanguages = function (observer) {
        return this._availableLanguages.subscribe(observer);
    };
    return LocalisationService;
}());
export { LocalisationService };
