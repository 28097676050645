var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, OnInit, EventEmitter, QueryList } from '@angular/core';
import { DocumentType } from '../../../../../../common/src/bdd/documents/DocumentType';
import { TemplateList } from '../../../../../../common/src/bdd/documents/TemplateList';
import { EnumTraducteurServiceExceptionType } from '../../../../../../common/src/bdd/interfaces/ITraducteurService';
import { TraducteurService } from '../../../../../../common/src/bdd/traducteur/TraducteurService';
import { Countries } from '../../../../../../common/src/bdd/utility/countries';
import { HieroBDD } from '../../../../services/hierobdd.service';
import { LocalisationService } from '../../../../../../common/src/modules/localisation/localisation.service';
var rotate = { 'asc': 'desc', 'desc': 'asc', '': 'desc' };
export var compare = function (v1, v2) { return v1 < v2 ? -1 : v1 > v2 ? 1 : 0; };
var NgbdSortableHeader = /** @class */ (function () {
    function NgbdSortableHeader() {
        this.direction = '';
        this.sort = new EventEmitter();
    }
    NgbdSortableHeader.prototype.rotate = function () {
        this.direction = rotate[this.direction];
        this.sort.emit({ column: this.sortable, direction: this.direction });
    };
    return NgbdSortableHeader;
}());
export { NgbdSortableHeader };
//////////////////
var TraducteurServiceComponent = /** @class */ (function () {
    function TraducteurServiceComponent(hiero, localisation) {
        this.hiero = hiero;
        this.localisation = localisation;
        this.busy = true;
        this.sortColumn = 'localisedCountry';
        this.sortDirection = 'asc';
    }
    TraducteurServiceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.templateList.updateQuery([
            {
                and: [
                    {
                        column: 'srcLanguageIso639',
                        value: this.service.data.srcLanguageIso639
                    },
                    {
                        column: 'destLanguageIso639',
                        value: this.service.data.destLanguageIso639
                    },
                ]
            }
        ], [
            {
                column: 'srcCountryCode',
                desc: false
            }
        ], 0, 100000);
        this.templateSub = this.templateList.WatchList({
            next: function (temps) {
                _this.updateAll(temps);
                _this.busy = false;
            }
        });
    };
    TraducteurServiceComponent.prototype.getCountryName = function (code) {
        return Countries.GetLocalizedCountryName(this.localisation.CurrentLanguageDictionary, code);
    };
    TraducteurServiceComponent.prototype.getDocumentName = function (code) {
        if (this.documentTypeMap) {
            return this.documentTypeMap.GetDocumentName(this.localisation.CurrentLanguageISO639, code);
        }
        else {
            return '';
        }
    };
    TraducteurServiceComponent.prototype.updateAll = function (templates) {
        var _this = this;
        this.templates = templates;
        this.templateDetail = [];
        this.templates.forEach(function (template) {
            var isOn = true;
            var price = template.Data.priceHT;
            var overridePrice = -1;
            if (_this.service.data.exceptions) {
                var exception = _this.service.data.exceptions.find(function (exc) { return exc.templateId === template.Id; });
                if (exception) {
                    isOn = (exception.type !== EnumTraducteurServiceExceptionType.DO_NOT_HANDLE);
                    if (exception.type === EnumTraducteurServiceExceptionType.DIFFERENT_PRICE) {
                        overridePrice = exception.priceHT;
                    }
                }
            }
            _this.templateDetail.push({
                template: template,
                on: isOn,
                priceHT: price,
                overridePriceHT: overridePrice,
                localisedCountry: Countries.GetCountryName('fr', template.Data.srcCountryCode)
            });
        });
        this.sortByColumn();
    };
    TraducteurServiceComponent.prototype.ngOnDestroy = function () {
        if (this.templateSub) {
            this.templateSub.unsubscribe();
        }
        if (this.templateList) {
            this.templateList.cleanup();
        }
    };
    TraducteurServiceComponent.prototype.delete = function () {
        this.busy = true;
        try {
            this.service.delete();
        }
        catch (err) {
        }
    };
    TraducteurServiceComponent.prototype.onToggled = function (isOn, template) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.busy = true;
                        if (!!isOn) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.service.addException(template.template.Id, EnumTraducteurServiceExceptionType.DO_NOT_HANDLE)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.service.removeException(template.template.Id)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    TraducteurServiceComponent.prototype.onUpdate = function (value, template) {
        template.overridePriceHT = parseFloat(value);
        this.updateException(template);
    };
    TraducteurServiceComponent.prototype.clearPriceException = function (template) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        template.overridePriceHT = -1;
                        return [4 /*yield*/, this.updateException(template)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TraducteurServiceComponent.prototype.updateException = function (template) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.busy = true;
                        if (!(template.overridePriceHT > -1)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.service.addException(template.template.Id, EnumTraducteurServiceExceptionType.DIFFERENT_PRICE, template.overridePriceHT)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.service.removeException(template.template.Id)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    TraducteurServiceComponent.prototype.onSort = function (_a) {
        var column = _a.column, direction = _a.direction;
        // sorting countries
        this.sortColumn = column;
        this.sortDirection = direction;
        this.sortByColumn();
    };
    TraducteurServiceComponent.prototype.sortByColumn = function () {
        var _this = this;
        // resetting other headers
        if (this.headers) {
            this.headers.forEach(function (header) {
                if (header.sortable !== _this.sortColumn) {
                    header.direction = '';
                }
            });
        }
        this.templateDetail = this.templateDetail.sort(function (a, b) {
            var res = compare(a[_this.sortColumn], b[_this.sortColumn]);
            return _this.sortDirection === 'asc' ? res : -res;
        });
    };
    return TraducteurServiceComponent;
}());
export { TraducteurServiceComponent };
