
<ng-template #popFilterContent>
  <a *ngFor="let filter of filterUI" class="badge clickable mr-1" [ngClass]="{'badge-info':filter.active, 'badge-secondary':!filter.active}" (click)="toggleState(filter)">{{ filter.state | prestationStateName }}</a>
</ng-template>
<ng-template #popFilterTitle>
  {{ 'tasks_filters' | localise }}
</ng-template>


<table class="table table-striped table-hover">
  <thead>
    <tr>
      <th scope="col" class="align-middle">            
        <a *ngIf="desc" class="badge badge-info menu_badge text-white clickable" (click)="toggleOrder()" ><i class="fa fa-angle-down"></i></a>
        <a *ngIf="!desc" class="badge badge-info menu_badge text-white clickable" (click)="toggleOrder()"><i class="fa fa-angle-up"></i></a>
        {{ 'tasks_sent_on' | localise }}
      </th>
      <th scope="col" class="align-middle">{{ 'tasks_src_lang' | localise }}</th>
      <th scope="col" class="align-middle">{{ 'tasks_src_country' | localise }}</th>
      <th scope="col" class="align-middle">{{ 'tasks_dst_country' | localise }}</th>
      <th scope="col" class="align-middle">
        <a class="badge badge-info menu_badge text-white clickable" [ngbPopover]="popFilterContent" [popoverTitle]="popFilterTitle" [placement]="['bottom']"><i class="fa fa-filter"></i></a>
        {{ 'tasks_state' | localise }}
      </th>
      <th scope="col" class="align-middle">{{ 'tasks_price' | localise }}</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let prestation of prestationList; let i = index" (click)="open(prestation)" class="clickable">
      <td class="align-middle">{{ prestation.Data.lastModifiedAt | date:'d/MM/yyyy, H:mm' }} </td>
      <td class="align-middle">{{ prestation.Data.srcLanguageIso639 | iso639ToNative }}</td>
      <td class="align-middle">{{ prestation.Data.srcCountryCode | country }}</td>
      <td class="align-middle">{{ prestation.Data.destLanguageIso639 | iso639ToNative }}</td>
      
      <td class="align-middle">{{ prestation.Data.state | prestationStateName }}</td>
      <td class="align-middle">{{ prestation.Data.price.traducteurHT }} €</td>
      <td class="align-middle">
        <i class="fa fa-envelope text-info" aria-hidden="true"></i> x {{ getUnreadMessages(prestation) }}
      </td>
    </tr>
    
  </tbody>
</table>

<ngb-pagination *ngIf="!!prestations" [collectionSize]="prestations.TotalCount" [page]="page" [pageSize]="pageSize" (pageChange)="onPageChange($event)" aria-label="Default pagination"></ngb-pagination>
<app-wait [show]='busy'></app-wait>
