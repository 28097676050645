
  <div class="row ml-0">
    <div class="col-md-8 pl-0">
      <p [innerHTML]="'agency_setup_explanation' | localise"></p>
      <form [formGroup]="profileForm.Group" novalidate>
        <app-form-input [parameters]="profileForm.GetInputElement('businessName')"></app-form-input>

        <app-form-typeahead class="verify-address" [parameters]="profileForm.GetInputElement('fulladdr')" [search]="search"
          [formatter]="formatter" (selected)='onAddressSelected($event)'></app-form-typeahead>
        <small><a class="d-block text-primary clickable mb-3" (click)="manualAddress()">{{ 'agency_address_not_found' | localise }}</a></small>

        <app-form-input [parameters]="profileForm.GetInputElement('extra')"></app-form-input>
        <app-form-input [parameters]="profileForm.GetInputElement('telephone')"></app-form-input>
        <app-form-input [parameters]="profileForm.GetInputElement('email')"></app-form-input>

      </form>
      <button type="button" class="btn btn-info" (click)='profileForm.Submit()'
        [disabled]="!profileForm.Valid">{{'agency_setup_save' | localise}}</button>
      <app-wait [show]='busy'></app-wait>
    </div>

    <div class="col-md-4">
      <h4><i class="fa fa-check fa-check text-muted"></i> {{'agency_expl_1_title' | localise}}</h4>
      <ul class="nav nav-list">
        <li>{{'agency_expl_1_expl' | localise}}</li>
      </ul>

      <h4 class="mt-5"><i class="fa fa-globe fa-fw text-muted"></i> {{'agency_expl_2_title' | localise}}</h4>
      <ul class="nav nav-list">
        <li>{{'agency_expl_2_expl' | localise}}</li>
      </ul>
      <h4 class="mt-5"><i class="fa fa-adjust fa-fw text-muted"></i> {{'agency_expl_3_title' | localise}}</h4>
      <ul class="nav nav-list">
        <li>{{'agency_expl_3_expl' | localise}}</li>
      </ul>

      <app-wait [show]='busy'></app-wait>
    </div>
  </div>

