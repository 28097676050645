import * as firebase from 'firebase/app';
import { Observable, PartialObserver, Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ISignupUserProfile, IUserProfile } from '../interfaces/IUserProfile';
import { ERoleType, User } from './User';
import * as yup from 'yup';

/**
 * Represents status options of the user login. This is not a binary option, as we may be in a 'waiting' state.
 */
export enum ELoggedInStatus {
  /**
   * Still waiting to determing if the user is logged in or not
   */
  Waiting,
  /**
   * User is logged in and authorized
   */
  LoggedIn,
  /**
   * User is not logged in
   */
  NotLoggedIn
}

/**
 * Represents the different signup types permitted on the platform
 */
export enum ESignupType {
  EmailPassword = 'email-password'
}

/**
 * The base structure for signing up to the platform. To be extended for each different type of signup.
 */
export interface ISignupBase {
  type: ESignupType;
}

/**
 * Email/password signup structure
 */
export interface ISignupData extends ISignupBase {
  email: string;
  password: string;
}

/**
 * Information necessary for sending email verifications.
 */
export interface IVerifyEmailData {
  /**
   * The URL to which to redirect when the user clicks the verify button
   */
  url: string;
  /**
   * The bundle ID of the app, so that when on mobile, the app will open instead of the browser.
   */
  bundleId: string;
  /**
   * Minimum version of a mobile app to open
   */
  minimumVersion: string;
}

/**
 * Callback type allowing to hook into the login or signup process with additional steps, before the subscriptions are fired.
 */
export type ExtraLoginStepCallbackType  = (user: User) => void;

/**
 * Auth service is the starting point for the Hiero API library. A valid and initialised firebase instance must be passed to the
 * constructor, and this service then handles the rest.
 * 
 * Auth service will automatically monitor changes to the state of the user, and fire events via subscriptions (the Watch* methods).
 */
export class AuthService {

  private _role: ERoleType;
  
  private _auth: firebase.auth.Auth;
  private _db: firebase.firestore.Firestore;

  private _userSubject: BehaviorSubject<User|null>;
  private _userLoggedInSubject: BehaviorSubject<ELoggedInStatus>;

  private _signupProfile: IUserProfile | null | undefined;
  private _stateListenerUnsubscribe: any;

  // For tracking async callbacks
  private _initMap: Map<string, Promise<User|null>>;
  private _lastInitUid: string | null;

  // Additional login steps
  public extraLoginSteps: ExtraLoginStepCallbackType[] = [];

  // Just before
  public justBeforeStateFireSteps: ExtraLoginStepCallbackType[] = [];

  constructor(auth: firebase.auth.Auth, db: firebase.firestore.Firestore, role: ERoleType) {

    this._role = role;
    this._auth = auth;
    this._db = db;
    this._userSubject = new BehaviorSubject<User|null>(null);
    this._userLoggedInSubject = new BehaviorSubject<ELoggedInStatus>(ELoggedInStatus.Waiting); // Initial state is waiting
    this._stateListenerUnsubscribe = null;
    this._signupProfile = null;
    this._lastInitUid = null;

    this._initMap = new Map<string, Promise<User|null>>();
  }

  public Listen() {
    
    // Make sure we only have one listener ever!
    if (!this._stateListenerUnsubscribe) {
      // Can be called multiple times be careful!!
      this._stateListenerUnsubscribe = this._auth.onAuthStateChanged(
        (user: firebase.User) => {
          if (user) {
            // Handle user state change
            this.handleStateChange(user);
          } else {
            // Signout
            this.signalLoggedOut();
          }
        },
        (err: any) => {
          // Error was encountered, signout        
          this.signalLoggedOut();
        }      
      );
    }    
  }

  public StopListening() {
    if (this._stateListenerUnsubscribe) {
      this._stateListenerUnsubscribe();
      this._stateListenerUnsubscribe = null;
    }
  }

  /**
   * Logs the current user out.
   */
  public async logout() {
    try {
      await this._auth.signOut();
    } catch (err) {
      console.log('Error while signing out. Ignoring.');
    }
  }

  /**
   * Watch for changes on the user. This will fire with the value null if the user is logged out.
   * The observer pass should be of the form: 
   * ```
   * { 
   *    next: (user: User) => { ... }
   * }
   * ```
   * @param observer The observer that will receive an update
   * @returns A subscription that can be cancelled by calling `unsubscribe()` on this object.
   */
  public WatchUser(observer: PartialObserver<User|null>): Subscription {
    return this._userSubject.subscribe(observer);
  }

  public get User(): User|null {
    return this._userSubject.value;
  }

  /**
   * Watch for changes on the login status. The user is considered logged in if a series of validation steps have been completed.
   * The observer pass should be of the form: 
   * ```
   * { 
   *    next: (status: ELoggedInStatus) => { ... }
   * }
   * ```
   * @param observer The observer that will receive an update
   * @returns A subscription that can be cancelled by calling `unsubscribe()` on this object.
   */
  public WatchLoggedInStatus(observer: PartialObserver<ELoggedInStatus>): Subscription {
    return this._userLoggedInSubject.subscribe(observer);
  }

  /**
   * Get the current logged in status of the user.
   * @returns The current logged in status.
   */
  public get LoggedInStatus(): ELoggedInStatus {
    return this._userLoggedInSubject.value;
  }

  /***
   * Logs in the user as a specific role.
   * Note, this function will start by logging out the user (just in case), to ensure startig from a clean slate.
   * The promise will eventually return true when the use is logged in, or reject if the login failed.
   * @param signupProfile If a profile is passed, this profile will be used to create a new profile for the user.
   */
  public async login(loginData: ISignupData, signupProfile?: ISignupUserProfile | null): Promise<boolean> {
  
    this._signupProfile = signupProfile;
    // Do a logout
    await this.logout();

    this._userLoggedInSubject.next(ELoggedInStatus.Waiting);

    let sub: Subscription;
    return new Promise(
      (resolve, reject) => {
        // Set up a subscription to wait for a change in status
        sub = this.WatchLoggedInStatus({
          next: (status: ELoggedInStatus) => {
            if (sub) {
              sub.unsubscribe();
            }

            if (status === ELoggedInStatus.LoggedIn) {
              return resolve(true);
            } else if (status === ELoggedInStatus.NotLoggedIn) {
              return reject(false);
            }
          }
        });
        switch (loginData.type) {
          case ESignupType.EmailPassword:

            const schema = yup.object({
              email: yup.string().ensure().trim().lowercase(),
              password: yup.string().ensure().trim(),
            });

            schema.validate(loginData)
            .then(
              (validated) => {

                return this._auth.signInWithEmailAndPassword(validated.email, validated.password)
              }
            )
            .catch(
              (err) => {
                console.log(err);
                reject(err);
              }
            );

            break;
          default:
            reject('Unknown login type.');
            break;
        }
      }
    );

  }

  /***
   * Sign's a new user for a specified role.
   * If the user already exists, the signup will fail.
   * @param signupData The data needed to sign the user up
   * @param signupProfile Profile information necessary for the signup. 
   * @param verifyData The options for sending the verify email
   */
  public async signup(signupData: ISignupData, signupProfile: ISignupUserProfile, verifyData: IVerifyEmailData) {

    this._signupProfile = signupProfile;

    // Do a logout
    await this.logout();

    // Notify that we are going into a waiting state
    this._userLoggedInSubject.next(ELoggedInStatus.Waiting);

    let sub: Subscription;
    return new Promise(
      (resolve, reject) => {
        // Set up a subscription to wait for a change in status
        sub = this.WatchLoggedInStatus({
          next: (status: ELoggedInStatus) => {
            if (sub) {
              sub.unsubscribe();
            }

            if (status === ELoggedInStatus.LoggedIn) {
              return resolve(true);
            } else if (status === ELoggedInStatus.NotLoggedIn) {
              return reject(false);
            }
          }
        });

        switch (signupData.type) {
          case ESignupType.EmailPassword:
            // Create account

            const schema = yup.object({
              email: yup.string().ensure().trim().lowercase(),
              password: yup.string().ensure().trim(),
            });
            
            schema.validate(signupData)
            .then(
              (validated) => {
                return this._auth.createUserWithEmailAndPassword(validated.email, validated.password)
              }
            )
            // TODO: Do we need email verification ??
            /* .then(
              (cred: firebase.auth.UserCredential) => {
                console.log("SIGNUP OK... SENDING EMAIL");
                return cred.user.sendEmailVerification(
                  {
                    url:  'https://hiero.page.link/verify',
                    android: {
                      packageName: 'tech.kevinglass.hiero',
                    },
                    iOS: {
                      bundleId: 'tech.kevinglass.hiero',
                    },
                    handleCodeInApp: false
                  }
                );
              }
            )*/
            .then(
              () => {
                resolve(true);
              }
            )
            .catch(
              (err) => {
                reject(err);
              }
            )            
            break;
          default:
            return Promise.reject('Unknown signup type.');
        }
      }
    );


  }

  /***
   * Logs in the user as a specific role.
   * Note, this function will start by logging out the user (just in case), to ensure startig from a clean slate.
   * The promise will eventually return true when the use is logged in, or reject if the login failed.
   * @param signupProfile If a profile is passed, this profile will be used to create a new profile for the user.
   */
  public async sendPasswordReset(email: string, resetLink: string): Promise<boolean> {

    return new Promise<boolean>(
      (resolve, reject) => {
        
         const resetData = {
          email: email
        }

        const schema = yup.object({
          email: yup.string().ensure().trim().lowercase(),
        });
            
        schema.validate(resetData)
        .then(
          (validated) => {
            //console.log("Sending password reset to: " + validated.email);
            return this._auth.sendPasswordResetEmail(validated.email, {
              url:  resetLink,
              android: {
                packageName: 'com.bymeunivers.hieromobile',
              },
              iOS: {
                bundleId: 'com.bymeunivers.hieromobile',
              },
              handleCodeInApp: false
            })
          }
        )
        .then(
          () => {
            resolve(true);
          }
        )
        .catch(
          (err) => {
            console.log(err);
            reject(err);
          }
        )    
            
      }
    );

  }

  public setAuthLanguage(iso639: string) {
    this._auth.languageCode = iso639;
  }

  private removeFromInitMap(uid:string) {
    // Remove from the map
    if (this._initMap.has(uid)) {
      this._initMap.delete(uid);
    }
  }

  private async setupUser(fbUser: firebase.User): Promise<User|null> {
    try {
      const loggedUser = await User.Init(fbUser, this._db, this._role, this._signupProfile);

      if (!loggedUser) {
        this.removeFromInitMap(fbUser.uid);
        return null;
      }

      // Extra login steps
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.extraLoginSteps.length; ++i) {
        await this.extraLoginSteps[i](loggedUser);
      }

      // Remove from the map
      this.removeFromInitMap(fbUser.uid);

      // Signal, if still relevant
      if (this._lastInitUid === fbUser.uid) {
        // Activate the user event
        this._userSubject.next(loggedUser);

        for (let i = 0; i < this.justBeforeStateFireSteps.length; ++i) {
          await this.justBeforeStateFireSteps[i](loggedUser);
        }

        // Activate the logged in status
        this._userLoggedInSubject.next(ELoggedInStatus.LoggedIn);

        return loggedUser;
      } else {
        // OOPS: this user is an old one, ignore it
        return null;
      }

      
    } catch (err) {
      // If an error was caught, the user should log out
      // This should automatically end up firing the log-out below
      console.warn('Error encountered while logging in: ' + err);
      console.warn('Signing out');
      this.removeFromInitMap(fbUser.uid);
      await this.logout();
      return null;
    }
  }

  private handleStateChange(fbUser: firebase.User) {
    // Most recent state change... last come is the one served.
    this._lastInitUid = fbUser.uid;

    if (this._initMap.has(fbUser.uid)) {
      // Already have an init running for this user... do nothing
    } else {
      this._initMap.set(fbUser.uid, this.setupUser(fbUser));
    }
  }

  private signalLoggedOut() {
    // User is logged out
    this._signupProfile = null;
    // Activate the user event
    this._userSubject.next(null);
    // Activate the logged in status
    this._userLoggedInSubject.next(ELoggedInStatus.NotLoggedIn);
  }

  

  

 

  

 
}
