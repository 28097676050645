/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../common/src/modules/localisation/localise.pipe";
import * as i2 from "../../../../../../common/src/modules/localisation/localisation.service";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "./prestationBase.component";
var styles_PrestationBaseComponent = [];
var RenderType_PrestationBaseComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PrestationBaseComponent, data: {} });
export { RenderType_PrestationBaseComponent as RenderType_PrestationBaseComponent };
export function View_PrestationBaseComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.LocalisePipe, [i2.LocalisationService]), (_l()(), i0.ɵeld(1, 0, null, null, 20, "nav", [["aria-label", "breadcrumb"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 19, "ol", [["class", "breadcrumb"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 9, "li", [["aria-current", "page"], ["class", "breadcrumb-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 8, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 671744, [[2, 4]], 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(6, 2), i0.ɵdid(7, 1720320, null, 2, i3.RouterLinkActive, [i3.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 1, { links: 1 }), i0.ɵqud(603979776, 2, { linksWithHrefs: 1 }), i0.ɵpad(10, 1), (_l()(), i0.ɵted(11, null, ["", ""])), i0.ɵppd(12, 1), (_l()(), i0.ɵeld(13, 0, null, null, 8, "li", [["aria-current", "page"], ["class", "breadcrumb-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 7, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 15).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(15, 671744, [[4, 4]], 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(16, 4), i0.ɵdid(17, 1720320, null, 2, i3.RouterLinkActive, [i3.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 3, { links: 1 }), i0.ɵqud(603979776, 4, { linksWithHrefs: 1 }), i0.ɵpad(20, 1), (_l()(), i0.ɵted(21, null, ["", ""])), (_l()(), i0.ɵeld(22, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(23, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 6, 0, "/app", "prestations"); _ck(_v, 5, 0, currVal_2); var currVal_3 = _ck(_v, 10, 0, "breadcrumbactive"); _ck(_v, 7, 0, currVal_3); var currVal_7 = _ck(_v, 16, 0, "/app", "prestation", _co.prestationId, "index"); _ck(_v, 15, 0, currVal_7); var currVal_8 = _ck(_v, 20, 0, "breadcrumbactive"); _ck(_v, 17, 0, currVal_8); _ck(_v, 23, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 5).target; var currVal_1 = i0.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_4 = i0.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i0.ɵnov(_v, 0), "prestation_breadcrumb_tasks")); _ck(_v, 11, 0, currVal_4); var currVal_5 = i0.ɵnov(_v, 15).target; var currVal_6 = i0.ɵnov(_v, 15).href; _ck(_v, 14, 0, currVal_5, currVal_6); var currVal_9 = _co.prestationId; _ck(_v, 21, 0, currVal_9); }); }
export function View_PrestationBaseComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_PrestationBaseComponent_0, RenderType_PrestationBaseComponent)), i0.ɵdid(1, 114688, null, 0, i5.PrestationBaseComponent, [i3.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrestationBaseComponentNgFactory = i0.ɵccf("ng-component", i5.PrestationBaseComponent, View_PrestationBaseComponent_Host_0, {}, {}, []);
export { PrestationBaseComponentNgFactory as PrestationBaseComponentNgFactory };
