import { User } from '../user/User';
import { ITranslatorLanguages, ITranslatorLanguageSource, ITranslatorLanguageDest } from '../interfaces/IAppIndexes';
import { Language } from '../utility/Language';

const FIREBASE_INDEX_COLLECTION = 'app_indexes';

///

export interface ILocalisation {
  value: string;
  label: string;
}

export class TemplatesLanguages {

  private _data: ITranslatorLanguages;

  private constructor(data: ITranslatorLanguages) {
    this._data = data;
  }

  private sort(arr: ILocalisation[]): ILocalisation[] {
    return arr.sort(
      (a: ILocalisation, b: ILocalisation) => {
        return a.label.localeCompare(b.label);
      }
    );
  }

  public getNativeSources(): ILocalisation[] {

    const arr: ILocalisation[] = [];
    this._data.languages.forEach(
      (lang) => {
        arr.push({
          value: lang.iso639,
          label: Language.GetNameFromISO639(lang.iso639)
        });
      }
    );

    return this.sort(arr);
  }

  public getDstLangsForSrcLang(srcIso639: string): ILocalisation[] {

    const arr: ILocalisation[] = [];

    const srcLang: ITranslatorLanguageSource|undefined = this._data.languages.find(lang => lang.iso639 === srcIso639);
    if (srcLang) {
      srcLang.to.forEach(
        (dstLang: ITranslatorLanguageDest) => {
          arr.push({
            value: dstLang.iso639,
            label: Language.GetNameFromISO639(dstLang.iso639)
          });
        }
      )
    }
    return this.sort(arr);
  }

  public static async Init(user: User): Promise<TemplatesLanguages> {
    const docRef = user.DB.collection(FIREBASE_INDEX_COLLECTION).doc('templates');
    const snapshot: firebase.firestore.DocumentSnapshot = await docRef.get();
    if (snapshot.exists) {
      const data = snapshot.data() as ITranslatorLanguages;
      return new TemplatesLanguages(data);
    } else {
      throw Error('Could not get available data');
    }
  }

}

