<ng-container *ngIf="!!prestation">
  <div class="row">
    <div class="col">
      <h4>{{ 'prestation_order_number' | localise }} {{ prestation.Id }}</h4>
    </div>
  </div>

  <div class="row">
    <div [ngClass]="{'col-8': haveChatWindow, 'col': !haveChatWindow}">
      <div class="card mb-3">
        <div class="card-body">

          <div class="row mb-3">
            <div class="col-6">
              <h4>{{ 'prestation_next_action_title' | localise }}</h4>
            </div>
          </div>

          <ng-container *ngIf="prestation.Data.state === 'waiting-for-translator'">
            <div class="row">
              <div class="col-6">
                <button class="btn btn-success" (click)="accept()">
                  {{ 'prestation_next_action_accept_order' | localise }}
                </button>
                <p class="card-text"><small>{{ 'prestation_next_action_accept_order_expl' | localise }}</small></p>
              </div>
              <div class="col-6">
                <button class="btn btn-danger" (click)="refuse()">
                  {{ 'prestation_next_action_reject_order' | localise }}
                </button>
                <p class="card-text"><small>{{ 'prestation_next_action_reject_order_expl' | localise }}</small></p>
              </div>    
            </div>
          </ng-container>

          <ng-container *ngIf="prestation.Data.state === 'refused-by-translator'">
            <div class="row">
              <div class="col">
                {{ 'prestation_next_action_rejected' | localise }}
              </div>
            </div>
          </ng-container>


          <ng-container *ngIf="prestation.Data.state === 'waiting-for-payment'">
            <div class="row">
              <div class="col">
                {{ 'prestation_next_action_waiting_for_payment' | localise }}
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="prestation.Data.state === 'translating'">
            <div class="row">
              <div *ngIf="!canValidate" class="col">
                <p>{{ 'prestation_next_action_translate_expl' | localise }}</p>
              </div>
              <div *ngIf="canValidate" class="col">
                <p>{{ 'prestation_next_action_translate_all_done_expl' | localise }}</p>
                <button *ngIf="prestation.Data.state === 'translating'" class="btn btn-success mt-3 mb-3" (click)="validate()" [disabled]="!canValidate">
                  {{ 'prestation_next_action_translate_confirm' | localise }}
                </button>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="prestation.Data.state === 'waiting-for-client-to-validate'">
            <div class="row">
              <div class="col">
                {{ 'prestation_next_action_waiting_for_validation' | localise }}
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="prestation.Data.state === 'validated'">
            <div class="row">
              <div class="col">
                {{ 'prestation_next_action_validated' | localise }}
              </div>
            </div>
          </ng-container>

        </div>
      </div>
      <div class="card mb-3">
        <h5 class="card-header">{{ 'prestation_order_details' | localise }}</h5>
        <div class="card-body">          
          <dl>
            <dd><strong>{{ 'prestation_order_from' | localise }}</strong> {{ prestation.Data.srcLanguageIso639 | iso639ToNative }} {{ 'prestation_order_to' | localise }} {{ prestation.Data.destLanguageIso639 | iso639ToNative }}</dd>
            <dd><strong>{{ 'prestation_order_country_origin' | localise }}</strong> {{ prestation.Data.srcCountryCode | country: "fr" }}</dd>
            <dd><strong>{{ 'prestation_order_document_count' | localise }}</strong> {{ prestation.Data.documents.length }}</dd>
            <dd><strong>{{ 'prestation_order_price' | localise }}</strong> {{prestation.Data.price.traducteurHT }} €</dd>
            <dd><strong>{{ 'prestation_order_date' | localise }}</strong> {{ prestation.Data.createdAt | date:'d/MM/yyyy à H:mm' }}</dd>
          </dl>
        </div>
      </div>

      <div class="card mb-3">
        <h5 class="card-header">{{ 'prestation_order_documents_title' | localise }}</h5>
        <div class="card-body">          
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col" class="align-middle">{{ 'prestation_order_document_type' | localise }}</th>
                <th scope="col" class="align-middle">{{ 'prestation_order_document_originals' | localise }}</th>
                <th scope="col" class="align-middle">{{ 'prestation_order_document_translated' | localise }}</th>    
                <th scope="col" class="align-middle" *ngIf="prestation.Data.state === 'translating' || prestation.Data.state === 'waiting-for-client-to-validate'"></th>          
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let document of prestation.Data.documents; let i = index">
                <td class="align-middle">{{ getDocumentName(document.documentTypeId) }}</td>
                <td class="align-middle">
                  <button *ngFor="let file of document.original" class="btn btn-outline-info btn-sm prestation-file-button mr-3" (click)="openFile(prestation.Data, document, file)">
                    <i *ngIf="file.type === 1" class="fa fa-file-image-o" aria-hidden="true"></i>
                    <i *ngIf="file.type === 0" class="fa fa-file-pdf-o" aria-hidden="true"></i>
                  </button>
                </td>
                <td class="align-middle">
                  <span *ngIf="!document.translated || document.translated.length == 0">{{ 'prestation_order_document_not_yet_translated' | localise }}</span>
                  <ng-container  *ngFor="let file of document.translated">
                    <button class="btn btn-outline-info btn-sm" (click)="openFile(prestation.Data, document, file)">
                      {{ file.name }}
                    </button>
                    <button class="btn btn-danger btn-sm ml-1" *ngIf="prestation.Data.state === 'translating'" (click)="removeFile(document, file)">
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </ng-container>         
                </td>
                <td class="align-middle" *ngIf="prestation.Data.state === 'translating' || prestation.Data.state === 'waiting-for-client-to-validate'">
                  <button class="btn btn-success" (click)="translate(document)">
                    {{ 'prestation_order_document_translate' | localise }}
                  </button>
                </td>
              </tr>
              
            </tbody>
          </table>
        </div>
      </div>
    </div>


    <div *ngIf="haveChatWindow"  class="col-4">      
      <app-chat [traducteur]="traducteur" [prestation]="prestation"></app-chat>
    </div>

  </div>


</ng-container>
<app-wait [show]='busy'></app-wait>


