import { PartialObserver } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subscription } from 'rxjs/Subscription';
import { EnumTraducteurServiceExceptionType, ITraducteurService } from '../interfaces/ITraducteurService';
import { Traducteur } from './Traducteur';

const FIREBASE_COLLECTION = 'traducteur_services';

export class TraducteurService {
  private _traducteur: Traducteur;
  private _docId: string;
  private _data: ITraducteurService;

  constructor(translator: Traducteur, docId: string, data: ITraducteurService) {
    this._traducteur = translator;
    this._docId = docId;
    this._data = data;
  }

  get Id(): string {
    return this._docId;
  }

  get data(): ITraducteurService {
    return this._data;
  }

  get Traducteur(): Traducteur {
    return this._traducteur;
  }

  /* async addDocumentType(docTypeId: string, price: number) {

    const cleanPrice = parseFloat(<any>price);

    if (!docTypeId || !cleanPrice || cleanPrice < 0) {
      throw Error('Invalid document');
    }


    // Check for duplicates
    const current = this._data.types.find(
      (type) => {
        return type.documentTypeId === docTypeId;
      }
    );

    if (current) {
      throw Error('This document already exists in the list');
    }

    // Add
    this._data.types = this._data.types.concat({
      documentTypeId: docTypeId,
      price: cleanPrice,
      exceptions: []
    });

    // Save
    await this._traducteur.User.DB.collection(FIREBASE_COLLECTION).doc(this._docId).update({
      types: this._data.types
    });
  }*/

  async addException(templateId: string, type: EnumTraducteurServiceExceptionType, price?: number) {

    if (!templateId || !type) {
      throw Error('Invalid exception');
    }

    let cleanprice = 0;
    if (type === EnumTraducteurServiceExceptionType.DIFFERENT_PRICE) {
      cleanprice = parseFloat(<any>price);
    }

    // Remove existing exception
    const excepFiltered = this._data.exceptions.filter(
      exc => exc.templateId !== templateId
    );

    const exceptions = excepFiltered.concat({
      type: type,
      templateId: templateId,
      priceHT: cleanprice
    });

    await this._traducteur.User.DB.collection(FIREBASE_COLLECTION).doc(this._docId).update({
      exceptions: exceptions
    });


  }

  async removeException(templateId: string) {
    if (!templateId) {
      throw Error('Invalid exception');
    }

    const exceptions = this._data.exceptions.filter(
      (exc) => {
        return (exc.templateId !== templateId);
      }
    );

    await this._traducteur.User.DB.collection(FIREBASE_COLLECTION).doc(this._docId).update({
      exceptions: exceptions
    });
  }

  async delete() {
    await this._traducteur.User.DB.collection(FIREBASE_COLLECTION).doc(this._docId).delete();
  }
}


export class TraducteurServiceList {

  private _traducteur: Traducteur;
  private _services: TraducteurService[];
  private _servicesSubject: BehaviorSubject<TraducteurService[]>;


  private constructor(translator: Traducteur) {
    this._traducteur = translator;
    this._services = [];
    this._servicesSubject = new BehaviorSubject<TraducteurService[]>(this._services);

    const query = translator.User.DB.collection(FIREBASE_COLLECTION).where('traducteurId', '==', translator.Id);
    query.onSnapshot({
      next: (snapshot: firebase.firestore.QuerySnapshot) => {

        this._services = [];

        snapshot.forEach(
          (result: firebase.firestore.QueryDocumentSnapshot) => {
            const data: ITraducteurService = result.data() as ITraducteurService;
            const pds: TraducteurService = new TraducteurService(translator, result.id, data);

            this._services.push(pds);
          }
        );

        // Signal new data to the list
        this._servicesSubject.next(this._services);
      },
      error: (error: Error) => {
        // console.warn(error);
      }
    });

    try {
      query.get();
    } catch (err) {
      console.warn(err);
    }
  }


  public WatchTraducteurServiceList(observer: PartialObserver<TraducteurService[]>): Subscription {
    return this._servicesSubject.subscribe(observer);
  }

  public async addService(srcLangISO639: string, dstLangISO639: string) {

    const prest: ITraducteurService = {
      traducteurId: this._traducteur.Id,
      srcLanguageIso639: srcLangISO639,
      destLanguageIso639: dstLangISO639,
      exceptions: []
    };

    const exist = this._services.find(
      (service) => {
        return (service.data.srcLanguageIso639 === srcLangISO639 && service.data.destLanguageIso639 === dstLangISO639);
      }
    );

    if (exist) {
      throw Error('This service already exists');
    }

    // TODO: Validate


    console.log(prest);
    // Should fire the snapshot callback
    await this._traducteur.User.DB.collection(FIREBASE_COLLECTION).add(prest);

  }

  public async removeService(prestationId: string) {
    await this._traducteur.User.DB.collection(FIREBASE_COLLECTION).doc(prestationId).delete();
  }

  /** Instantiate a new Translator class */
  public static async Init(translator: Traducteur): Promise<TraducteurServiceList> {
    return Promise.resolve(new TraducteurServiceList(translator));
  }

  public static async HasServices(translator: Traducteur): Promise<boolean> {
    const query = translator.User.DB.collection(FIREBASE_COLLECTION).where('traducteurId', '==', translator.Id);
    const results: firebase.firestore.QuerySnapshot = await query.get();
    if (results) {
      return (results.size > 0);
    } else {
      return false;
    }
  }


}

