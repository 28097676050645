var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BehaviorSubject } from 'rxjs';
var FIREBASE_MESSAGE_NOTIFICATIONS_CLIENT_COLLECTION = 'client_message_notifications';
var FIREBASE_MESSAGE_NOTIFICATIONS_TRANSLATOR_COLLECTION = 'traducteur_message_notifications';
var MessageNotifications = /** @class */ (function () {
    function MessageNotifications(doc, snapshot) {
        var _this = this;
        this._doc = doc;
        var not = {
            stored: 0,
            seen: 0
        };
        if (snapshot.exists) {
            not = snapshot.data();
        }
        this._notificationSubject = new BehaviorSubject(not);
        this._stopListeningMessages = doc.onSnapshot(function (newSnap) {
            var notif = newSnap.data();
            _this._notificationSubject.next(notif);
        });
    }
    Object.defineProperty(MessageNotifications.prototype, "Id", {
        get: function () {
            return this._doc.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MessageNotifications.prototype, "Unseen", {
        get: function () {
            var data = this._notificationSubject.value;
            return Math.max(data.stored - data.seen, 0);
        },
        enumerable: true,
        configurable: true
    });
    MessageNotifications.prototype.cleanup = function () {
        if (this._stopListeningMessages) {
            this._stopListeningMessages();
            this._stopListeningMessages = null;
        }
    };
    MessageNotifications.prototype.SetSeen = function () {
        return __awaiter(this, void 0, void 0, function () {
            var not;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        not = this._notificationSubject.value;
                        return [4 /*yield*/, this._doc.update({
                                seen: not.stored
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MessageNotifications.prototype.WatchNotifications = function (observer) {
        return this._notificationSubject.subscribe(observer);
    };
    MessageNotifications.getOrAdd = function (doc) {
        return __awaiter(this, void 0, void 0, function () {
            var snapshot, not;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, doc.get()];
                    case 1:
                        snapshot = _a.sent();
                        if (!!snapshot.exists) return [3 /*break*/, 4];
                        not = {
                            stored: 0,
                            seen: 0
                        };
                        return [4 /*yield*/, doc.set(not)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, doc.get()];
                    case 3:
                        snapshot = _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/, snapshot];
                }
            });
        });
    };
    MessageNotifications.InitForClientPrestation = function (user, prestationId) {
        return __awaiter(this, void 0, void 0, function () {
            var doc, snapshot;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        doc = user.DB.collection(FIREBASE_MESSAGE_NOTIFICATIONS_CLIENT_COLLECTION).doc(prestationId);
                        return [4 /*yield*/, MessageNotifications.getOrAdd(doc)];
                    case 1:
                        snapshot = _a.sent();
                        return [2 /*return*/, new MessageNotifications(doc, snapshot)];
                }
            });
        });
    };
    MessageNotifications.InitForTranslatorPrestation = function (translator, prestationId) {
        return __awaiter(this, void 0, void 0, function () {
            var doc, snapshot;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        doc = translator.User.DB.collection(FIREBASE_MESSAGE_NOTIFICATIONS_TRANSLATOR_COLLECTION).doc(prestationId);
                        return [4 /*yield*/, MessageNotifications.getOrAdd(doc)];
                    case 1:
                        snapshot = _a.sent();
                        return [2 /*return*/, new MessageNotifications(doc, snapshot)];
                }
            });
        });
    };
    return MessageNotifications;
}());
export { MessageNotifications };
