/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./app-debounce-input.component";
var styles_AppDebounceInputComponent = [];
var RenderType_AppDebounceInputComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppDebounceInputComponent, data: {} });
export { RenderType_AppDebounceInputComponent as RenderType_AppDebounceInputComponent };
export function View_AppDebounceInputComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { inputValue: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["inputValue", 1]], null, 0, "input", [["class", "form-control"], ["style", "width: 100%"]], [[8, "type", 0], [8, "value", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.type, ""); var currVal_1 = i0.ɵinlineInterpolate(1, "", _co.value, ""); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_AppDebounceInputComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-debounce-input", [], null, null, null, View_AppDebounceInputComponent_0, RenderType_AppDebounceInputComponent)), i0.ɵdid(1, 4374528, null, 0, i1.AppDebounceInputComponent, [], null, null)], null, null); }
var AppDebounceInputComponentNgFactory = i0.ɵccf("app-debounce-input", i1.AppDebounceInputComponent, View_AppDebounceInputComponent_Host_0, { value: "value", type: "type", period: "period" }, { onChanged: "onChanged" }, []);
export { AppDebounceInputComponentNgFactory as AppDebounceInputComponentNgFactory };
