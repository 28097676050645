var Storage = /** @class */ (function () {
    function Storage() {
    }
    Storage.GetCookie = function (key) {
        var name = key + '=';
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    };
    Storage.SetCookie = function (key, value, expiresIn) {
        // Calculate the expires fiels
        var d = new Date();
        d.setTime(d.getTime() + (expiresIn * 24 * 60 * 60 * 1000));
        var expires = 'expires=' + d.toUTCString();
        // Set the cookie for browsers that don't support
        var cookieStr = key + '=' + value + ';path=/;' + expires + ';'; // SameSite=Strict
        document.cookie = cookieStr;
    };
    Object.defineProperty(Storage, "UseCookie", {
        get: function () {
            var ua = navigator.userAgent.toLowerCase();
            return (ua.indexOf('safari') > -1) && (ua.indexOf('android') < 0) && (ua.indexOf('chrome') < 0) && (ua.indexOf('firefox') < 0) && (ua.indexOf('edge') < 0);
        },
        enumerable: true,
        configurable: true
    });
    Storage.SetValue = function (key, value, expiresIn) {
        if (this.UseCookie) {
            this.SetCookie(key, value, expiresIn);
        }
        else {
            localStorage.setItem(key, value);
        }
    };
    Storage.RemoveValue = function (key) {
        if (this.UseCookie) {
            this.SetCookie(key, '', -1);
        }
        else {
            localStorage.removeItem(key);
        }
    };
    Storage.GetValue = function (key) {
        if (this.UseCookie) {
            return this.GetCookie(key);
        }
        else {
            return localStorage.getItem(key);
        }
    };
    return Storage;
}());
export { Storage };
