import { Routes } from '@angular/router';
import { LoggedInGuard } from './guards/loggedin.guard';
import { AccountComponent } from './routes/account/account.component';
import { LoginComponent } from './routes/account/login/login.component';
import { SignupComponent } from './routes/account/signup/signup.component';
import { AppBaseComponent } from './routes/app/appbase/appbase.component';
import { PrestationEditorComponent } from './routes/app/prestations/prestation/editor/prestationEditor.component';
import { PrestationComponent } from './routes/app/prestations/prestation/index/prestation.component';
import { PrestationBaseComponent } from './routes/app/prestations/prestation/prestationBase.component';
import { PrestationListComponent } from './routes/app/prestations/prestations.component';
import { ProfileComponent } from './routes/app/profile/profile.component';
import { TraducteurServiceListComponent } from './routes/app/services/traducteurServiceList.component';
import { SupportComponent } from './routes/app/support/support.component';
import { TraducteurComponent } from './routes/app/traducteur/traducteur.component';
import { BaseComponent } from './routes/base/base.component';
import { NotFoundComponent } from './routes/not_found/not_found.component';
import { ProfileCheckComponent } from './routes/verify/profile/profilecheck.component';
import { VerifyBaseComponent } from './routes/verify/verifybase/verifybase.component';
import { ResetPasswordComponent } from './routes/account/reset-password/reset-password.component';
var ɵ0 = { title: 'section_account_title' }, ɵ1 = { title: 'section_agency_title' }, ɵ2 = { title: 'section_support_title' }, ɵ3 = { title: 'section_services_title' }, ɵ4 = { title: 'section_tasks_title' }, ɵ5 = { title: 'section_task_title' }, ɵ6 = { title: 'section_document_title' };
var appRoutes = [
    // Pages for logging in
    {
        path: 'compte',
        component: AccountComponent,
        children: [
            {
                path: 'connexion',
                component: LoginComponent
            },
            {
                path: 'inscription',
                component: SignupComponent
            },
            {
                path: 'resetPassword',
                component: ResetPasswordComponent
            },
        ]
    },
    // Intermediate steps before getting to app section
    {
        path: 'verify',
        canActivate: [LoggedInGuard],
        component: VerifyBaseComponent,
        children: [
            {
                path: 'profile',
                component: ProfileCheckComponent
            },
            { path: '**', component: ProfileCheckComponent },
            { path: '', redirectTo: 'profile', pathMatch: 'full' }
        ],
    },
    // Validated section - user is connected
    {
        path: 'app',
        canActivate: [LoggedInGuard],
        component: AppBaseComponent,
        children: [
            {
                path: 'profile',
                component: ProfileComponent,
                data: ɵ0
            },
            {
                path: 'traducteur',
                component: TraducteurComponent,
                data: ɵ1
            },
            /*
            {
              path: 'home',
              component: HomeComponent,
              data: { title: 'Acceuil' }
            },*/
            {
                path: 'support',
                component: SupportComponent,
                data: ɵ2
            },
            /*
            {
              path: 'dashboard',
              component: DashboardComponent,
              data: { title: 'Tableau de board' }
            },
            {
              path: 'subscription',
              component: SubscriptionComponent
            },*/
            {
                path: 'services',
                component: TraducteurServiceListComponent,
                data: ɵ3
            },
            {
                path: 'prestations',
                component: PrestationListComponent,
                data: ɵ4
            },
            {
                path: 'prestation/:prestationId',
                component: PrestationBaseComponent,
                data: ɵ5,
                children: [
                    {
                        path: 'index',
                        component: PrestationComponent
                    },
                    {
                        path: 'document/:documentId',
                        data: ɵ6,
                        component: PrestationEditorComponent
                    },
                    { path: '**', component: PrestationComponent }
                ]
            },
            { path: '', redirectTo: 'prestations', pathMatch: 'full' },
        ]
    },
    // Logged out page(s)
    {
        path: '',
        component: BaseComponent,
        children: []
    },
    /// Fallbacks
    { path: '', redirectTo: 'BaseComponent', pathMatch: 'full' },
    { path: '**', component: NotFoundComponent }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
