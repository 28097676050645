/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./language-dropdown.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-bootstrap/dropdown";
import * as i4 from "ngx-bootstrap/component-loader";
import * as i5 from "./language-dropdown.component";
import * as i6 from "../../../modules/localisation/localisation.service";
var styles_LanguageDropdownComponent = [i0.styles];
var RenderType_LanguageDropdownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LanguageDropdownComponent, data: {} });
export { RenderType_LanguageDropdownComponent as RenderType_LanguageDropdownComponent };
function View_LanguageDropdownComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "dropdown-item"], ["href", "javascript:;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setLanguage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_0); }); }
function View_LanguageDropdownComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "dropdown-menu dropdown-menu-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LanguageDropdownComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languages; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_LanguageDropdownComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 8, "div", [["class", "language-dropdown-main"], ["dropdown", ""]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], null, null, null, null)), i1.ɵprd(512, null, i3.BsDropdownState, i3.BsDropdownState, []), i1.ɵdid(2, 212992, null, 0, i3.BsDropdownDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i4.ComponentLoaderFactory, i3.BsDropdownConfig, i3.BsDropdownState], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "a", [["class", "dropdown-toggle"], ["data-toggle", "dropdown"], ["dropdownToggle", ""], ["href", "javascript:;"]], [[1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 147456, null, 0, i3.BsDropdownToggleDirective, [i1.ChangeDetectorRef, i3.BsDropdownDirective, i1.ElementRef, i1.Renderer2, i3.BsDropdownState], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "d-md-inline"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LanguageDropdownComponent_1)), i1.ɵdid(8, 16384, null, 0, i3.BsDropdownMenuDirective, [i3.BsDropdownState, i1.ViewContainerRef, i1.TemplateRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).dropup; var currVal_1 = i1.ɵnov(_v, 2).isOpen; var currVal_2 = (i1.ɵnov(_v, 2).isOpen && i1.ɵnov(_v, 2).isBs4); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = true; var currVal_4 = i1.ɵnov(_v, 4).isDisabled; var currVal_5 = i1.ɵnov(_v, 4).isOpen; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.current; _ck(_v, 6, 0, currVal_6); }); }
export function View_LanguageDropdownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-language-dropdown", [], null, null, null, View_LanguageDropdownComponent_0, RenderType_LanguageDropdownComponent)), i1.ɵdid(1, 180224, null, 0, i5.LanguageDropdownComponent, [i6.LocalisationService], null, null)], null, null); }
var LanguageDropdownComponentNgFactory = i1.ɵccf("app-language-dropdown", i5.LanguageDropdownComponent, View_LanguageDropdownComponent_Host_0, {}, {}, []);
export { LanguageDropdownComponentNgFactory as LanguageDropdownComponentNgFactory };
