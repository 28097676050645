var Config = /** @class */ (function () {
    function Config(config) {
        this._config = config;
    }
    Object.defineProperty(Config.prototype, "Environment", {
        get: function () {
            return this._config;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Config.prototype, "AppFullPath", {
        /// ---------- APP ------------ ///
        get: function () {
            return this.Environment.app.origin + this.Environment.app.subpath;
        },
        enumerable: true,
        configurable: true
    });
    return Config;
}());
export { Config };
