import { Logger } from '../../common/src/utility/logger.class';
// 
import { environment } from '../environments/environment';
// Set logging level
Logger.LogLevel = environment.logLevel;
var ɵ0 = environment;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
