import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, AfterViewInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ISubmitFormElement } from '../submitform.class';
import { Subscription } from 'rxjs/Subscription';
import { fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';


export interface ITypeAheadInputButton {
  text: string;
  alwaysEnabled: boolean;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-debounce-input',
  templateUrl: './app-debounce-input.component.html'
})
export class AppDebounceInputComponent implements AfterViewInit, OnDestroy {
  @Input() value;
  @Input() type;
  @Input() period;
  @Output() onChanged = new EventEmitter();
  @ViewChild('inputValue') inputValue: ElementRef;

  private unsub: Subscription;

  ngAfterViewInit() {
    this.unsub = fromEvent(this.inputValue.nativeElement, 'keyup')
    .pipe(
      map((event: any) => {
        return event.target.value;
      }),
      debounceTime(this.period),
      distinctUntilChanged(),
    )
    .subscribe(
      (text: string) => {
        this.onChanged.emit(text);
      }
    );
  }

  ngOnDestroy() {
    if (this.unsub) {
      this.unsub.unsubscribe();
    }
  }
}
