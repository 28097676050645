var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HieroBDD } from '../../../services/hierobdd.service';
import { SubmitForm } from '../../../../../common/src/utility/forms/submitform.class';
import { TemplateList } from '../../../../../common/src/bdd/documents/TemplateList';
import { TemplatesLanguages } from '../../../../../common/src/bdd/documents/TemplatesLanguages';
import { DocumentType } from '../../../../../common/src/bdd/documents/DocumentType';
import { LocalisationService } from '../../../../../common/src/modules/localisation/localisation.service';
var TraducteurServiceListComponent = /** @class */ (function () {
    function TraducteurServiceListComponent(hiero, fb, localisation) {
        var _this = this;
        this.hiero = hiero;
        this.fb = fb;
        this.localisation = localisation;
        this.busy = true;
        this.addIsCollapsed = true;
        // Add listeners for the data we want
        // These listeners will either fire immediately,
        // or they will fire when the data becomes available
        this.traducteutSub = hiero.WatchTraducteur({
            next: function (traducteur) {
                if (traducteur) {
                    // We have a user, save a reference
                    _this.traducteur = traducteur;
                    // Get a list of services for this translator
                    _this.traducteur.getTraducterServices()
                        .then(function (serviceList) {
                        _this.serviceList = serviceList;
                        _this.listSub = _this.serviceList.WatchTraducteurServiceList({
                            next: function (services) {
                                _this.services = services;
                                _this.checkReady();
                            }
                        });
                        return TemplateList.Init(traducteur.User, true);
                    })
                        .then(function (templateList) {
                        _this.templateList = templateList;
                        return TemplatesLanguages.Init(traducteur.User);
                    })
                        .then(function (tl) {
                        _this.templatesLanguages = tl;
                        _this.setSourceDropdown();
                        return DocumentType.Init(traducteur.User);
                    })
                        .then(function (docType) {
                        _this.docTypeMap = docType;
                        _this.checkReady();
                    });
                }
            }
        });
        this.createForm();
    }
    TraducteurServiceListComponent.prototype.checkReady = function () {
        if (this.services && this.docTypeMap) {
            this.busy = false;
        }
    };
    TraducteurServiceListComponent.prototype.ngOnDestroy = function () {
        if (this.traducteutSub) {
            this.traducteutSub.unsubscribe();
        }
        if (this.listSub) {
            this.listSub.unsubscribe();
        }
    };
    TraducteurServiceListComponent.prototype.setSourceDropdown = function () {
        var options = [];
        if (this.templatesLanguages) {
            var srcLangs = this.templatesLanguages.getNativeSources();
            srcLangs.forEach(function (loc) {
                options.push({
                    name: loc.label,
                    data: loc.value
                });
            });
        }
        this.srcLangDropDown = {
            choices: options,
            selectedIndex: 0,
            title: '',
            input_title: this.localisation.localise('services_add_src_lang'),
            hasButton: false,
            button_title: '',
            help: ''
        };
        // Automatically set first
        if (options.length > 0) {
            this.sourceLangISO639 = options[0].data;
            this.setDestDropdown();
        }
    };
    TraducteurServiceListComponent.prototype.setDestDropdown = function () {
        var options = [];
        if (this.templatesLanguages) {
            var dstLangs = this.templatesLanguages.getDstLangsForSrcLang(this.sourceLangISO639);
            dstLangs.forEach(function (loc) {
                options.push({
                    name: loc.label,
                    data: loc.value
                });
            });
        }
        this.dstLangDropDown = {
            choices: options,
            selectedIndex: 0,
            title: '',
            input_title: this.localisation.localise('services_dst_lang'),
            hasButton: false,
            button_title: '',
            help: ''
        };
        if (options.length > 0) {
            this.setDestLang(options[0].data);
        }
    };
    TraducteurServiceListComponent.prototype.selectSourceLang = function (data) {
        this.sourceLangISO639 = data.data;
        this.setDestDropdown();
    };
    TraducteurServiceListComponent.prototype.selectDestLang = function (data) {
        this.setDestLang(data.data);
    };
    TraducteurServiceListComponent.prototype.setDestLang = function (dstIso639) {
        var _this = this;
        if (this.services) {
            var myService = this.services.find(function (service) {
                return service.data.srcLanguageIso639 === _this.sourceLangISO639 && service.data.destLanguageIso639 === dstIso639;
            });
            if (myService) {
                this.destLangISO639 = null;
            }
            else {
                this.destLangISO639 = dstIso639;
            }
        }
    };
    TraducteurServiceListComponent.prototype.createForm = function () {
        var _this = this;
        this.prestationForm = new SubmitForm(this.fb, [
            {
                name: 'src',
                value: '',
                validators: [],
                type: 'text',
                title: 'Langue d\'origine',
                autocomplete: '',
                placeholder: 'Commencer à taper le nom de la langue',
                help: '',
                errors: [
                    {
                        code: 'required',
                        message: 'Champs obligatoire',
                    },
                ]
            },
            {
                name: 'dst',
                value: '',
                validators: [],
                type: 'text',
                title: 'Vers',
                autocomplete: '',
                placeholder: 'Commencer à taper le nom de la langue',
                help: '',
                errors: [
                    {
                        code: 'required',
                        message: 'Champs obligatoire',
                    },
                ]
            },
        ], 
        // Submit callback
        function (changes) {
        }, 
        // Success callback
        function () {
            // What to do with login success ?
            _this.busy = false;
        }, 
        // Fail callback
        function (err) {
            // What to do with login failuer
            console.error(err);
            _this.busy = false;
        }, 
        // Changes callback
        null);
        this.setSourceDropdown();
        this.setDestDropdown();
    };
    TraducteurServiceListComponent.prototype.addPrestation = function () {
        return __awaiter(this, void 0, void 0, function () {
            var srcCode, dstCode, supported, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.prestationForm.ClearGeneralError();
                        srcCode = this.sourceLangISO639;
                        dstCode = this.destLangISO639;
                        if (!srcCode || !dstCode) {
                            this.prestationForm.SetGeneralError('Les langues choisies ne sont pas reconnues');
                            return [2 /*return*/];
                        }
                        this.busy = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, TemplateList.CountTemplates(this.traducteur.User, srcCode, dstCode)];
                    case 2:
                        supported = _a.sent();
                        if (supported <= 0) {
                            throw Error(this.localisation.localise('services_error_add'));
                        }
                        return [4 /*yield*/, this.serviceList.addService(srcCode, dstCode)];
                    case 3:
                        _a.sent();
                        this.busy = false;
                        this.addIsCollapsed = true;
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        this.prestationForm.SetGeneralError(err_1.message);
                        this.busy = false;
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    TraducteurServiceListComponent.prototype.removePrestation = function (service) {
        return __awaiter(this, void 0, void 0, function () {
            var err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.busy = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.serviceList.removeService(service.Id)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        this.prestationForm.SetGeneralError(err_2.message);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    TraducteurServiceListComponent.prototype.beforePanelChange = function ($event) {
        this.currentActiveId = $event.panelId;
    };
    return TraducteurServiceListComponent;
}());
export { TraducteurServiceListComponent };
