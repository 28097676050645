var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BehaviorSubject } from 'rxjs';
var FIREBASE_DOCUMENT_TYPE_COLLECTION = 'document_types';
/**
 * This class initialises and stores a map of the document types handled by the system.
 * The list of supported documents comes from the firestore database.
 */
var DocumentType = /** @class */ (function () {
    function DocumentType(user, query, firstSnapshot, watch) {
        var _this = this;
        this._user = user;
        this._documentMapSubject = new BehaviorSubject(this);
        this.processMap(firstSnapshot);
        if (watch) {
            this._unsubscribe = query.onSnapshot(function (snapshot) {
                _this.processMap(snapshot);
            }, function (err) { });
        }
    }
    DocumentType.prototype.processMap = function (snapshot) {
        var _this = this;
        this._documentMap = new Map();
        if (snapshot && !snapshot.empty) {
            snapshot.docs.forEach(function (docSnapshot) {
                var data = docSnapshot.data();
                data._id = docSnapshot.id;
                _this._documentMap.set(docSnapshot.id, data);
            });
        }
        this._documentMapSubject.next(this);
    };
    DocumentType.prototype.cleanup = function () {
        if (this._unsubscribe) {
            this._unsubscribe();
            this._unsubscribe = null;
        }
    };
    DocumentType.prototype.Watch = function (observer) {
        return this._documentMapSubject.subscribe(observer);
    };
    DocumentType.prototype.GetDocumentName = function (iso639, docId) {
        if (this._documentMap.has(docId)) {
            var type = this._documentMap.get(docId);
            var label = type.label.find(function (docType) { return docType.code === iso639; });
            if (label) {
                return label.value;
            }
            else {
                return docId;
            }
        }
        else {
            return docId;
        }
    };
    DocumentType.prototype.Add = function (documenttype) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._user.DB.collection(FIREBASE_DOCUMENT_TYPE_COLLECTION).add(documenttype)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DocumentType.prototype.AddLabel = function (docTypeId, codeISO639, text) {
        return __awaiter(this, void 0, void 0, function () {
            var docRef, doc, docType, label;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._user.DB.collection(FIREBASE_DOCUMENT_TYPE_COLLECTION).doc(docTypeId)];
                    case 1:
                        docRef = _a.sent();
                        return [4 /*yield*/, docRef.get()];
                    case 2:
                        doc = _a.sent();
                        if (!doc.exists) return [3 /*break*/, 4];
                        docType = doc.data();
                        label = docType.label.find(function (type) { return type.code === codeISO639; });
                        if (label) {
                            label.value = text;
                        }
                        else {
                            docType.label.push({
                                code: codeISO639,
                                value: text
                            });
                        }
                        return [4 /*yield*/, docRef.update({
                                label: docType.label
                            })];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4: throw Error('Document type with that id does not exist.');
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    DocumentType.prototype.RemoveLabel = function (docTypeId, codeISO639) {
        return __awaiter(this, void 0, void 0, function () {
            var docRef, doc, docType, labels;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._user.DB.collection(FIREBASE_DOCUMENT_TYPE_COLLECTION).doc(docTypeId)];
                    case 1:
                        docRef = _a.sent();
                        return [4 /*yield*/, docRef.get()];
                    case 2:
                        doc = _a.sent();
                        if (!doc.exists) return [3 /*break*/, 4];
                        docType = doc.data();
                        labels = docType.label.filter(function (type) { return type.code !== codeISO639; });
                        return [4 /*yield*/, docRef.update({
                                label: labels
                            })];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4: throw Error('Document type with that id does not exist.');
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    DocumentType.prototype.getDocumentType = function (id) {
        return this._documentMap.get(id);
    };
    /**
     * This asynchronous method will load a map of supported document types.
     * @param user The logged in user.
     */
    DocumentType.Init = function (user, watch) {
        return __awaiter(this, void 0, void 0, function () {
            var query, snapshot;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = user.DB.collection(FIREBASE_DOCUMENT_TYPE_COLLECTION);
                        return [4 /*yield*/, query.get()];
                    case 1:
                        snapshot = _a.sent();
                        return [2 /*return*/, new DocumentType(user, query, snapshot, watch)];
                }
            });
        });
    };
    Object.defineProperty(DocumentType.prototype, "Map", {
        /** If Init has already been called, this will return the map of document types */
        get: function () {
            return this._documentMap;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DocumentType.prototype, "ToArray", {
        get: function () {
            var arr = [];
            this._documentMap.forEach(function (docType) {
                arr.push(docType);
            });
            return arr;
        },
        enumerable: true,
        configurable: true
    });
    return DocumentType;
}());
export { DocumentType };
